import { useContext } from 'react';
import { Button } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext';

const DeleteFileModal = ({ modalDetails }) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }

  const handleDeleteFile = () => {
    if (modalDetails.origin == 'episode') {
      deleteEpisodeFile(modalDetails.id)
    } else {
      deleteCourseFile(modalDetails.id)
    }
  }

  const deleteEpisodeFile = (id) => {
    if (authCtx.isLoggedIn) {
      fetch(`${process.env.REACT_APP_API_URI}/v1/admin/courses/episode/${id}/file`, {
        method: 'DELETE',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status == "success") {
          modalCtx.closeModal();
        } else {
          console.error("Error while deleting file...");
        }
      }).catch((err) => {
        console.error("Error while deleting file...", err);
      });
    }
  }

  const deleteCourseFile = (id) => {
    if (authCtx.isLoggedIn) {
      fetch(`${process.env.REACT_APP_API_URI}/v1/admin/courses/${id}/file`, {
        method: 'DELETE',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status == "success") {
          modalCtx.closeModal();
        } else {
          console.error("Error while deleting file...");
        }
      }).catch((err) => {
        console.error("Error while deleting file...", err);
      });
    }
  }

  return (
    <div className="modal">
      <p style={{ fontWeight: "bold" }}>Are you sure that you wish to delete this file?</p>
      <div className="footer">
        <Button variant='outlined' style={{ borderColor: "#1359E4", color: "#1359E4" }} fullWidth onClick={() => modalCtx.closeModal()}>Cancel</Button>
        <Button variant='contained' onClick={() => handleDeleteFile()} style={{ backgroundColor: "#1359E4", color: "white" }} fullWidth  >Delete</Button>
      </div>
    </div>
  )
}

export default DeleteFileModal;
