import { Button, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import AuthContext from '../../store/AuthContext';
import DrawerContext from '../../store/DrawerContext';


const EditTagDrawer = ({ drawerDetails }) => {

  const authCtx   = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);

  const [createTagDrawerObj, setCreateTagDrawerObj] = useState({ title: drawerDetails.title || "" });

  console.log("drawerDetails", drawerDetails);

  const submitEditTag = () => {
    
    if(authCtx.isLoggedIn) {

    }
    const { title } = createTagDrawerObj;
		fetch(process.env.REACT_APP_API_URI + '/v1/admin/tags/' + drawerDetails.id + '/update', {
			method: 'PUT',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
			body: JSON.stringify({ title, })
		}).then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			if(data.status == "success") {
				// navigate("/devotionals", { replace: true });
        drawerCtx.closeDrawer();
        window.location.reload();
			} else {
        console.error("Error while editing tag...");
				// setSnackbarMessage(data.message);
				// setOpenSnackbar(true);
			}
		});
  }

  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Edit Tag Drawer</h1>
        </header>

        <section>

          {/* <div className="double-column-form"> */}
          {/* <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required /> */}
          <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={createTagDrawerObj.title} onChange={(e) => setCreateTagDrawerObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          {/* </div> */}

          <div className="double-column-form aligned-right margin-top">

            <Button variant="contained" component="label" disabled={false} 
              onClick={(e) => submitEditTag()}>Save Changes
            </Button>
          </div>

        </section>

      </div>
    </div>
  )
}

export default EditTagDrawer;

