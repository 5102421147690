import React, { useContext, useState, useEffect } from 'react';

import ModalContext from '../../../store/ModalContext.js';
import AuthContext from '../../../store/AuthContext.js';
import { CloseRounded } from '@mui/icons-material';
import { Button } from '@mui/material';

const CreateDevotional = () => {


  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [userData, setUserData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("network");

  const closeHandle = () => {
    modalCtx.closeModal();
  }

  return (
    <>
      <div className="create-devotional-container">
        <div className='devotional-header'>
          <h2>Create Devotional</h2>
          <CloseRounded className='close' onClick={() => closeHandle()} />
        </div>
        <div className="create-container">
          <input className='title-input' type="text" />
          <input className='author-input' type="text" />
          <textarea className='description-input' type="text" />
        </div>
        <div className="save-devotional">
          <Button>Save</Button>
        </div>
      </div>
    </>
  )
}

export default CreateDevotional;
