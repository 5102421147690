import React, { useContext, useEffect, useState } from 'react';

import AOS from 'aos';
import { Button, TextField } from '@material-ui/core';
import DrawerContext from '../store/DrawerContext';
import FilterPopup from '../Components/Drawers/UserFilterMenu';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { List } from '@material-ui/icons';

import UsersDrawer from '../Components/DetailsDrawer';
import { Avatar, TablePagination } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import '../styles/App.scss';
import 'aos/dist/aos.css';

import ActionPopup from '../Components/Drawers/EditUserMenu';
import { Checkbox } from '@mui/material';
import GetImage from '../Components/GetImage';

AOS.init();

const Users = () => {

  const drawerCtx = useContext(DrawerContext);

  const [itemSelection, setItemSelection] = useState([]);
  const [loadedUsers, setUsersData] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [rowNumber, setRowNumber] = useState(0);
  const [startRowNumber, setStartRowNumber] = useState(0);
  const [endRowNumber, setEndRowNumber] = useState(30);
  const [visibleRows, setVisibleRows] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  //boolean states
  const [selectedAll, setSelectedAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = React.useState(false);
  const [deletingUsers, setDeletingUsers] = useState(false);


  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };


  function showRegDate(unFormattedDate) {
    const date = new Date(unFormattedDate);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  function showStatus(status) {
    if (status == 1) {
      return "active";
    }
    return "inactive";
  }

  const handleChangePage = (event, newPage) => {
    // console.log(visibleRows[0]);
    setPage(newPage);
    setStartRowNumber(newPage * rowsPerPage);
    if (rowNumber > newPage * rowsPerPage + rowsPerPage) {
      setEndRowNumber(newPage * rowsPerPage + rowsPerPage);
    } else {
      setEndRowNumber(rowNumber);
    }
    setVisibleRows(
      loadedUsers.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage)
    );
  }

  const getImage = (data) => {
    if (data.avatar == null) {
      return <Avatar className='avatar'>{data.name[0]}</Avatar>;
    } else {
      return <GetImage alt={data.name} imageRef={data.avatar} />
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    handleChangePage(event, 0);
  }

  // Action Menu 
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const handleSelectAll = () => {
    if (selectedAll) {
      setSelectedAll(false)
      setSelectedUsers([])
    } else {
      setSelectedAll(true)
      setSelectedUsers([])

      for (let i = 0; i < visibleRows.length; i++) {
        setSelectedUsers(prev => [...prev, { id: visibleRows[i]?.id }])
      }
    }
  }


  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);


  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  useEffect(() => {
    getUsers()
  }, []);


  const getUsers = () => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v1/users', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        // console.log(data);
        setIsLoading(false);
        setUsersData(data.data);
        setVisibleRows(
          data.data.slice(startRowNumber, endRowNumber)
        );
        setRowNumber(data.data.length);
      });
  }

  const handleCheckCheckedReview = (id) => {

    const userFound = selectedUsers?.find((item) => id == item?.id)

    if (userFound) {
      return true
    } else {
      return false
    }

  }

  const handleSelectUsers = (id) => {

    const userFound = selectedUsers?.find((item) => id == item.id)

    if (userFound) {

      const array = selectedUsers
      const result = array.filter(item => item.id !== userFound.id);
      console.log(result)
      setSelectedUsers(result)

    } else {

      setSelectedUsers(prev => [...prev, { id }])

    }
  }

  const deleteMultipleUsers = () => {

    setDeletingUsers(true)

    Promise.all(
      selectedUsers.map(async user => {
        deleteUserFirebase(user.id)
      })
    ).then(data => {
      console.log('selected users deleted')
      setSelectedUsers([])
      setDeletingUsers(false)
    })

  }

  const deleteUserFirebase = (id) => {



  }

  if (isLoading) {
    return <section><CircularProgress /></section>
  }

  // console.log(selectedUsers)

  return (
    <div className="user-container">
      <div style={{ height: '80px' }} data-aos="fade-up" className='user-heading'>
        <h1>Users</h1>
        <div className='search-filters'>
          {
            selectedUsers?.length > 0
              ? <div className="delete-btn">
                <Button className='header-cta' onClick={() => deleteMultipleUsers()} >Delete</Button>
              </div>
              : null
          }
          <TextField
            className='search-texfield'
            variant='outlined'
            placeholder="Search"
          />
          <FilterPopup />
          <Button className='header-cta' onClick={() => toggleDrawerHandler('create-user', null)}>+ Create New</Button>
        </div>
      </div>
      {/* <UsersTable /> */}
      <>
        <TableContainer component={Paper} className="user-table-container" style={{ height: 'calc(100% - 80px)' }}>
          <Table sx={{ "& td": { border: 0 } }} className="users-table" aria-label="simple table" data-aos="fade-up">
            <TableHead className='table-head' style={{ width: '100%', position: "sticky", top: "0", background: "#FFF", zIndex: "100" }}>
              <TableRow>
                <TableCell style={{ height: '10%' }} align="left"><Checkbox checked={selectedAll} onChange={(e) => handleSelectAll(e)} /></TableCell>
                <TableCell align='left'>Full Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="left">Registration Date</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='table-body'>
              {
                visibleRows?.map((data, i) => (
                  <TableRow key={data.id} className="table-row">
                    <TableCell className="TableCell" ><Checkbox value={i} checked={handleCheckCheckedReview(data.id)} onClick={() => handleSelectUsers(data.id)} /></TableCell>
                    <TableCell className="TableCell" ><div className='table-cell'>{getImage(data)} 	{data.name}</div></TableCell>
                    <TableCell className="TableCell" >{data.email}</TableCell>
                    <TableCell align="left" className="TableCell" >{showRegDate(data.created_at)}</TableCell>
                    <TableCell align="center" ><div className={data.status}> {data.status.charAt(0).toUpperCase() + data.status.slice(1).toLowerCase()} </div></TableCell>
                    <TableCell align="right"><ActionPopup /> </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>


          {
            selectedAll
              ? null
              : <div className="table-pagination" style={{ width: '100%', position: "sticky", bottom: "0", right: "0", left: "0", background: "#FFF", zIndex: "100" }}>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={loadedUsers?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
                // labelRowsPerPage="Rows per page"
                />
              </div>
          }


        </TableContainer>
        <UsersDrawer openTheDrawer={state} />

      </>
    </div>
  );

}
export default Users;