import React from "react";
import AdministratorsTable from "../Components/AdministratorTable";

const Administrators = () =>{

    return <div className="container">
    
    <div data-aos="fade-up">
        <h1>Administrators</h1>
	</div>
    <AdministratorsTable/>
    </div>

}

export default Administrators;