import React, { useState, useEffect, useCallback } from 'react';
import { signInWithEmailAndPassword, signOut, onIdTokenChanged, createUserWithEmailAndPassword } from 'firebase/auth';

import { auth } from "./Firebase"
import { ref, set, update } from "firebase/database";
import { database } from "../Components/FirebaseConfig";

let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  userId: '',
  userName: '',
  displayName: '',
  email: '',
  avatarRef: '',
  isLoggedIn: false,
  destination: '',
  login: (token, userId, userName, email, scope, tokenExpiration) => {},
  logout: () => {}
});

export const AuthContextProvider = (props) => {

  //firebase db
  const db = database;

  const initialToken = localStorage.getItem('KEAdminToken');
  const initialUserId = localStorage.getItem('userId');
  const initialEmail = localStorage.getItem('email');

  const [token, setToken] = useState(initialToken);
  const [userId, setUserId] = useState(initialUserId);
  const [email, setEmail] = useState(initialEmail);
  const [error,setError] = useState("no error");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [destination, setDestination] = useState('overview');

  const userIsLoggedIn                = !!token;  

  useEffect(() => {
    onIdTokenChanged(auth, data => {
      if(data) {
        console.log(data);
        console.log("Are we running");
        setToken(data.accessToken);
        setUserId(data.uid);
        localStorage.setItem('KEAdminToken', data.accessToken);
        localStorage.setItem('userId', data.uid);
        localStorage.setItem('email', data.email);
        setIsLoggedIn(true);
      }
    })
  }, [onIdTokenChanged, setToken, setUserId]);

  const loginHandler = (email, password, setResponse, searchParams) => {

    signInWithEmailAndPassword(auth, email, password, setResponse)
    .then((data) => {

      setDestination(searchParams.get('destination'))
      setIsLoggedIn(true)
      
      setToken(data.user.accessToken);
      setUserId(data.user.uid);
      setEmail(email);

      localStorage.setItem('KEAdminToken', data.user.accessToken);
      localStorage.setItem('userId', data.user.uid);
      localStorage.setItem('email', email);
    })
    .catch((error) => {
      console.log(error);
      let errorMessage = "An error occurred during login.";
      if (error.code === "auth/invalid-email") {
        errorMessage = "Invalid email.";
      } else if (error.code === "auth/wrong-password") {
        errorMessage = "Email or password incorrect.";
      } else if (error.code === "auth/too-many-requests") {
        errorMessage = "Too many failed logins. Please wait a while and try again.";
      }
      setResponse(errorMessage);
    });

  }

  const logoutHandler = () => {

    signOut(auth)
    .then(() => {
      console.log("Signed out");
      setToken(null);
      setUserId(null);
      setEmail(null);

      localStorage.removeItem('KEAdminToken');
      localStorage.removeItem('userId');
      localStorage.removeItem('email');
      setIsLoggedIn(false)
    })
    .catch((error) => {
      console.log(error);
    });
    
  };

  const createUserHandler = async (email, password, name)=>{

    createUserWithEmailAndPassword(auth, email.trim(), password, name)
      .then((data) => {

        const uid = data.user.uid
        const tempToken = data.user.accessToken

        var userPayload = {
          firstName: "test", 
          lastName: "test", 
          email: email, 
          password: password
        };
        console.log(userPayload)

        fetch(process.env.REACT_APP_API_URI + `/v1/register`, {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tempToken}` },
          body: JSON.stringify(userPayload)
        }).then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == "success") {

            // set(ref(db, `users/${uid}`), { "email": email })
            //   .then(() => {
            //     console.log('user added')
            //     getUserData(uid, tempToken)
            //   })
            //   .catch((error) => {
            //     console.log(error)
            //   });
            setIsLoggedIn(true)
          } else {
            console.log('something went wrong while adding user')
          }
        });
      })
      .catch((e) => {
        console.log(e);
        console.log(e.code);
        if (e.code === 'weak-password') {
          console.log("The password provided is too weak.")
        } else if (e.code === 'email-already-in-use') {
          console.log("The account already exists for that email.")
        } else if (e.code === 'too-many-requests') {
          console.log("Sorry, failed too many signup attempts. Please try again later")
        }
      });
  }

  const getUserData = (uid, tempToken) => {

    console.log(uid, tempToken)

    // fetch(process.env.REACT_APP_API_URI + `/v1/user/${uid}`, {
    //   method: 'GET',
    //   mode: 'cors',
    //   headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tempToken}` },
    // }).then(response => {
    //   return response.json();
    // }).then(data => {
    //   console.log(data);
    //   if(data.status == "success") {
    //     setToken(tempToken);
    //     setUserId(data.data.id);
    //     setUserName(data.data.name);
    //     setDisplayName(data.data.username);
    //     setEmail(data.data.email);
    //     setAvatarRef(data.data.avatarRef);
  
    //     localStorage.setItem('token', tempToken);
    //     localStorage.setItem('userId', data.data.id);
    //     localStorage.setItem('userName', data.data.username);
    //     localStorage.setItem('displayName', data.data.name);
    //     localStorage.setItem('email', data.data.email);
    //     localStorage.setItem('avatarRef', data.data.avatarRef);
  
    //   } else {
    //     console.log('something went wrong while getting user')
    //   }
    // });

  }

  const contextValue = {
    token: token,
    userId: userId,
    email: email,
    isLoggedIn: isLoggedIn,
    error: error,
    destination: destination,
    login: loginHandler,
    logout: logoutHandler,
    createUser : createUserHandler
  }
  console.log(contextValue);
  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
}

export default AuthContext;