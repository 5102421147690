import React, { useEffect, useState, useContext } from 'react'

import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material';

import ModalContext from '../store/ModalContext.js';
import DrawerContext from '../store/DrawerContext.js';
import AuthContext from '../store/AuthContext.js';

const Tags = () => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [tags, setTags] = useState([]);

  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {
    getAllTags();
  }, [drawerCtx, modalCtx]);


  const getAllTags = () => {

    fetch(process.env.REACT_APP_API_URI + '/v1/tags', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token }
    })
      .then(response => {
        // console.log(response);
        return response.json();
      }).then(data => {
        console.log('Success:', data);
        if (data.status == 'success') {
          setTags(data?.data)
        }
      }).catch(err => console.error(err));

  }

  const openDeleteTagModal = (id) => {
    modalCtx.setDetails('confirm-delete-tag', { id });
    modalCtx.openModal();
  }

  return (
    <div className='container'>
      <header>
        <h1>Tags</h1>
        <div className="create-devotional">
          {/* <Button className='create-devotional-btn' onClick={() => handleModal('createDevotional')} >+ Create New</Button> */}
          <Button className='header-cta' onClick={() => toggleDrawerHandler('create-tag', null)}>+ Create New</Button>
        </div>
      </header>
      <div className="data-table">
        <TableContainer className='tags-table' component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ position: "sticky", top: "0", background: "#FFF", zIndex: "100" }} >
              <TableRow>
                <TableCell align="left" style={{ fontWeight: 700, fontSize: '16px' }}>Tag</TableCell>
                {/* <TableCell align="center">Title</TableCell> */}
                {/* <TableCell align="center">Author</TableCell> */}
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tags?.map((tag) => (
                <TableRow style={{ background: '#fff', boxShadow: "10px 3px 10px rgba(0, 0, 0, 0.0392156863)", borderRadius: "8px" }} key={tag.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                  <TableCell component="th" scope="row">
                    
                    <span style={{ fontWeight: 600, fontSize: '16px' }} >{tag.title}</span>
                  </TableCell>
                  {/* <TableCell align="center">{tag.title}</TableCell>
											<TableCell align="center">{tag.author}</TableCell>*/}
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center" className="action-buttons-cell">
                    {/* <Button className='devo-edit-btn' ><Edit className="action-icon" /></Button>
													<Button className='devo-delete-btn' ><Delete className="action-icon" /></Button> */}
                    <Edit className="devo-edit-btn action-icon" onClick={() => toggleDrawerHandler('edit-tag', tag)} />
                    <Delete style={{ marginLeft: '1rem' }} className="devo-delete-btn action-icon" onClick={() => openDeleteTagModal(tag.id)} />
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default Tags;
