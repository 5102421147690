import { initializeApp } from "firebase/app";
import { getStorage, uploadBytes, ref, uploadString, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

// Initialize Firebase
const app = initializeApp ({
  apiKey: "AIzaSyD6Fkn-088fHr92EF0VrdtLi2pelDfc9iI",
  authDomain: "kingdom-empowered.firebaseapp.com",
  projectId: "kingdom-empowered",
  storageBucket: "kingdom-empowered.appspot.com",
  messagingSenderId: "49987204181",
  appId: "1:49987204181:web:0fd20dac21dc70c10050dc",
  measurementId: "G-XCJWJXX408"
});
 
// Firebase storage reference
const storage = getStorage(app);
const database = getDatabase(app);
const firestore = getFirestore(app);

export { database, firestore, uploadBytes, ref, uploadString, uploadBytesResumable , getDownloadURL};

export default storage;
