import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';

const DesktopMobileData = () => {
  const totalValue = 200;

  const getSubtitle = () => {
    const desktopValue = 130;
    const mobileValue = 70;
  };

  const inputRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = Highcharts.chart('container', {
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
      },
      subtitle: {
        useHTML: true,
        text: getSubtitle(),
        floating: true,
        verticalAlign: 'middle',
        y: 0,
      },
      plotOptions: {
        pie: {
          innerSize: '80%',
          dataLabels: {
            enabled: false,
          },
          borderWidth: 0,
          gapSize: 0, // Set the gapSize to 1 for no gap between slices
        },
      },
      colors: ['#844094', '#BB4468'],
      series: [
        {
          name: 'Usage',
          data: [
            { name: 'Desktop', y: 130 },
            { name: 'Mobile', y: 70 },
          ],
        },
      ],
    });

    chartRef.current = chartInstance;
  }, []);

  const update = () => {
    const chart = chartRef.current;

    chart.series[0].setData([
      { name: 'Desktop', y: 130 },
      { name: 'Mobile', y: 70 },
    ]);

    chart.setTitle(null, { text: getSubtitle() });
  };

  const handleInputChange = () => {
    update();
  };

  return (
    <div className='desktopAndMobileData'>
      <div className="desktopAndMobileData-heading">No. of users Accessing via the Desktop vs Mobile</div>
      <div id="container" style={{width:"60%" , margin:"auto"}}/>
      <div className='users-count'>
        <div className="desktop-users-count">
          <div className="users-icon">
            <div className="users-indicator"></div>
            <h4>Desktop</h4>
          </div>
          <h4 className="no-of-users">130</h4>
        </div>
        <div className="mobile-users-count">
          <div className="users-icon">
            <div className="users-indicator"></div>
            <h4>Mobile</h4>
          </div>
          <h4 className="no-of-users">70</h4>
        </div>
      </div>
    </div>
  );
};

export default DesktopMobileData;