import { Button, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import AuthContext from '../../../store/AuthContext';
import DrawerContext from '../../../store/DrawerContext';
import { useNavigate } from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateCourseDrawer = () => {

  const authCtx   = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const navigate  = useNavigate();

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [createDevotionalObj, setCreateDevotionalObj] = useState({ author: "", title: "", description: "" });

  // console.log("createDevotionalObj", createDevotionalObj);

  const submitCreateDevotional = () => {
    
    if(authCtx.isLoggedIn) {

    }
    const { author, title, description } = createDevotionalObj;
		fetch(process.env.REACT_APP_API_URI + '/v1/admin/courses', {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token  },
			body: JSON.stringify({
				// author,
				title,
				description,
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			if(data.status == "success") {
				// navigate("/devotionals", { replace: true });
        drawerCtx.closeDrawer();
        window.location.reload();
			} else {
        console.error("Error while creating devotional...");
				// setSnackbarMessage(data.message);
				// setOpenSnackbar(true);
			}
		}).catch((err) => console.error("Error while creating devotional...", err));
  }


  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Create Course</h1>
        </header>

        <section>

          {/* <div className="double-column-form"> */}
          {/* <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required /> */}
          <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={createDevotionalObj.title} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          {/* <TextField style={{ margin: "10px auto 15px" }} name="author" label="Author" value={createDevotionalObj.author} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required /> */}
          {/* <TextField style={{ margin: "10px auto 15px" }} name="description" label="Description" value={createDevotionalObj.description} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" multiline rows={4} InputLabelProps={{ shrink: true }} fullWidth required /> */}
          <ReactQuill theme="snow" name="description" value={createDevotionalObj.description} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, description: e }))} />
          {/* </div> */}

          <div className="double-column-form aligned-right margin-top">

            <Button variant="contained" component="label" disabled={false} 
              onClick={(e) => submitCreateDevotional()}>Create Course
            </Button>
          </div>

        </section>

      </div>
    </div>
  )
}



export default CreateCourseDrawer;
