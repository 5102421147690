import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../../store/AuthContext';
import DrawerContext from '../../../store/DrawerContext';
import { useNavigate } from "react-router-dom";

import 'react-quill/dist/quill.snow.css';
import { Close } from '@mui/icons-material';

const CreateBookDrawer = () => {

  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const navigate = useNavigate();

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [createDevotionalObj, setCreateDevotionalObj] = useState({
    blog_id: "", title: "", affiliate_code: "",

  });

  const [title, setTitle] = React.useState('');
  const [blogs, setBlogs] = React.useState([]);
  const [loading, setLoading] = useState(false);

  const handleChangeBlog = (title, id) => {
    setCreateDevotionalObj((prev) => ({ ...prev, ['blog_id']: id }))
    setTitle(title)
  };

  useEffect(() => {
    getAllBlogs();
  }, []);


  // functions
  const getAllBlogs = () => {
    fetch(process.env.REACT_APP_API_URI + '/v1/blogs', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.status == 'success') {
          // console.log("blogs ", data.data);
          // console.log("loading blog");
          setBlogs(data?.data);
          setLoading(false);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };


  const submitCreateDevotional = () => {

    if (authCtx.isLoggedIn) {

    }
    const { blog_id, title, affiliate_code } = createDevotionalObj;
    fetch(process.env.REACT_APP_API_URI + '/v1/admin/books', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify({
        blog_id, title, affiliate_code,

      })
    }).then(response => {
      return response.json();
    }).then(data => {
      // console.log(data);
      if (data.status == "success") {
        // navigate("/devotionals", { replace: true });
        drawerCtx.closeDrawer();

      } else {
        console.error("Error while creating books...");

      }
    }).catch((err) => console.error("Error while creating books...", err));
  }

  console.log(title)

  return loading == false ? (
    <div className="container">
      <div className="drawer">

        <header style={{ display: "flex", justifyContent: "space-between" }}>
          <h1 data-aos="fade-right" data-aos-delay="500">Create Book</h1>
          <Close style={{ margin: "0 0 0 auto", cursor: "pointer" }} onClick={() => drawerCtx.closeDrawer()} />
        </header>

        <section>

          <TextField style={{ margin: "10px auto 15px" }} name="title" label="Book Title" value={createDevotionalObj.title} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder=" Book Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          <TextField style={{ margin: "10px auto 15px" }} name="affiliate_code" label="Affiliate Code" value={createDevotionalObj.affiliate_code} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Affiliate Code" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />

          <InputLabel id="select-blog">Select Blog</InputLabel>
          <Select
            labelId="select-blog"
            id="select-blog"
            name="select-blog"
            value={title}
            label="select-blog"
            fullWidth required
          >
            {
              blogs?.map((blog, index) => (
                <MenuItem className='item' key={index} onClick={() => { handleChangeBlog(blog.title, blog.id) }} value={blog.title}>{blog.title}</MenuItem>
              ))
            }
          </Select>
          <div className="double-column-form aligned-right margin-top">
            <Button variant="contained" component="label" disabled={false}
              onClick={(e) => submitCreateDevotional()}
            >Create Book
            </Button>
          </div>

        </section>

      </div>
    </div>
  ) : (<div className="container">
    <div className="drawer"><div className='center'><CircularProgress></CircularProgress></div> </div> </div>);
}



export default CreateBookDrawer;

