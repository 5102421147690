import React, { useEffect,useState} from 'react'
import { useParams } from 'react-router-dom'
import AdvertisingHomePage from '../../Components/Advertising/AdvertisingHomePage';
import AdvertisingDevotionalsPage from '../../Components/Advertising/AdvertisingDevotionalsPage';
import AdvertisingCoursesPage from '../../Components/Advertising/AdvertisingCoursesPage';

export default function AdvertisingSlug() {
    const {slug} = useParams();

    const [current, setcurrent] = useState(null)

    console.log(slug)

    useEffect(()=>{
      if(slug=="manage-homepage"){
        setcurrent(<AdvertisingHomePage/>)
      }
      else if(slug=="manage-devotionalspage"){
        setcurrent(<AdvertisingDevotionalsPage/>)
      }
      else{
        setcurrent(<AdvertisingCoursesPage/>)
      }
    },[])

  return (
    <div className='advertising-page-manage-container'>{current}</div>
  )
}
