import { useContext, useState } from 'react';
import { Button, TextField } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext';
import { Close } from '@mui/icons-material';
import Picker from '../../../assets/img/picker.svg'

import { HuePicker, PhotoshopPicker, SketchPicker } from 'react-color';

const ColorPicker = ({ modalDetails }) => {



  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }
  console.log(modalDetails.id.affiliate_code);
  const [createDevotionalObj, setCreateDevotionalObj] = useState({
    blog_id: modalDetails.id.id, title: modalDetails.id.title, affiliate_code: modalDetails.id.affiliate_code,

  });
  const updateBook = () => {
    if (authCtx.isLoggedIn) {
      const { blog_id, title, affiliate_code } = createDevotionalObj;
      fetch(process.env.REACT_APP_API_URI + '/v1/admin/books/' + blog_id, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
        body: JSON.stringify({ title, blog_id, affiliate_code })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status == "success") {
          // navigate("/devotionals", { replace: true });
          modalCtx.closeModal();
          // window.location.reload();
        } else {
          console.error("Error while updating books...");
          // setSnackbarMessage(data.message);
          // setOpenSnackbar(true);
        }
      }).catch((err) => {
        console.error("Error while upadting books...", err);
        handleCloseModal();
      });
      console.log("update book id", blog_id);
    }
  }

  const openColorPicker = (id) => {
    modalCtx.setDetails('color-picker', { id });
    modalCtx.openModal();
  }

  //color picker

  const [color, setColor] = useState('#fff')


  const handleChangeComplete = (color) => {
    setColor(color.hex);

  };

  return (
    <div className="color-picker">
      <div className='title'><p>Color Picker</p> <Close onClick={(e) => { handleCloseModal() }} /></div>

      <span>Background Color</span>
      <div className='background'>
        <SketchPicker className='sketch' color={color}
          onChangeComplete={(e) => { handleChangeComplete(e) }} />
      </div>

      <div className="footer">

        <Button className="confirm-btn" onClick={() => updateBook()}>Apply</Button>
      </div>
    </div>
  )
}

export default ColorPicker;
