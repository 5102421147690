import React, { useContext, useState, useEffect } from 'react';
import { Button } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext.js';

import 'react-quill/dist/quill.snow.css';
import { Add, CloseRounded, KeyboardArrowDownRounded } from '@mui/icons-material';

const AttachBlogModal = ({ modalDetails }) => {


  // console.log("modalDetails", modalDetails);

  //context
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tag, setTag] = useState('');
  const [searchTag, setSearchTag] = useState('')
  const [addTagTitle, setAddTagTitle] = useState('')

  const [showTagResults, setShowTagResults] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)


  //effect
  useEffect(() => {
    getAllTags()
  }, [])

  //I had to do this effect because the state doesn't mutate immediately


  //change visibility and get all tags when searchTag is changed
  useEffect(() => {
    if (searchTag.length > 0) {
      setShowTagResults(true)
    } else {
      setShowTagResults(false)
    }
    getAllTags()
  }, [searchTag])


  // submit tag to db


  // get all tags
  const getAllTags = async (title) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v1/blogs/get/list`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const res = await response.json();
      if (res.status == 'success') {
        console.log(res)
        setTags(res?.data)
        setAddTagTitle(title)
      }
    } catch (err) {
      console.log(err);
    }
  }

  //deleting tag


  // create new tag




  //check if tag exist


  //add selected tag to db function and array
  const handleSelectTag = (tag) => {
    console.log(tag)
    setSelectedTags(tag.title);
    setCreateDevotionalObj((prev) => ({ ...prev, ['blog_id']: tag.id }));
  }

  //filter and delete tag from db and state

  //set search tag string
  const handleSearchTag = (e) => {
    setSearchTag(e.target.value)
  }

  //handling tag visibility
  const handleShowTagResult = () => {
    if (showTagResults) {
      setShowTagResults(false)
    } else {
      setShowTagResults(true)
    }
  }

  const [createDevotionalObj, setCreateDevotionalObj] = useState({
    blog_id: "", blog: ""

  });
  const updateBook = () => {
    if (authCtx.isLoggedIn) {
      const { blog_id } = createDevotionalObj;
      fetch(process.env.REACT_APP_API_URI + '/v1/admin/books/' + modalDetails.id.id + '/addBlog/' + blog_id, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
        // body: JSON.stringify({ blog_id, blog })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status == "success") {
          // navigate("/devotionals", { replace: true });
          // drawerCtx.closeDrawer();
          // window.location.reload();
          modalCtx.closeModal();
        } else {
          console.error("Error while updating books...");
          // setSnackbarMessage(data.message);
          // setOpenSnackbar(true);
        }
      }).catch((err) => {
        console.error("Error while upadting books...", err);
        modalCtx.closeModal();
      });
      console.log("update book id", blog_id);
    }
  }

  console.log(selectedTags)

  return (
    <>
      <div className="edit-tags-container">
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Attach a Blog</h1>
        </header>

        <section>

          <div className="search-tag-container">
            <input type="text" placeholder='Search for Blog..' value={searchTag} onChange={handleSearchTag} />
            <KeyboardArrowDownRounded className='drop-down-btn' onClick={() => handleShowTagResult()} style={showTagResults ? { transform: 'rotateZ(180deg)' } : null} />
          </div>
          <div className="tags-menu" style={showTagResults ? null : { display: 'none' }} >
            <ul>
              {
                tags?.filter((tag) => tag.title.toLowerCase().includes(searchTag.toLowerCase())).map((tag, i) => {
                  return <li key={i} onClick={() => handleSelectTag(tag)}>{tag.title}</li>
                })
              }
            </ul>
          </div>
          <div className="author-list">
            {selectedTags.length > 0 ? <span className='author-tag'>{selectedTags}</span> : null}
          </div>
          <div className="save-changes-btn">

            <Button className='close-btn' fullWidth variant="contained" component="label" disabled={false}
              onClick={(e) => modalCtx.closeModal()}>Close
            </Button>
            <Button variant="contained" fullWidth component="label" disabled={false}
              onClick={(e) => updateBook()}>Save Changes
            </Button>
          </div>
        </section>
      </div>


    </>
  )
}

export default AttachBlogModal;
