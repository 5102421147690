//react
import React, { useContext, useEffect, useState } from 'react';

//mui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Checkbox, TablePagination, TextField } from '@mui/material';
//context
import AuthContext from '../store/AuthContext';
import DrawerContext from '../store/DrawerContext';

//assets
import Blogs from '../assets/img/reviews/blogs.svg'
import Courses from '../assets/img/reviews/courses.svg'
import Devotionals from '../assets/img/reviews/devotionals.svg'

//scss/css
import '../styles/App.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
// ..
AOS.init();



const Payments = () => {

  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);

  const [itemSelection, setItemSelection] = useState([]);
  const [loadedPayments, setPaymentsData] = useState([]);
  const [selectedPayments, setSelectedPayments] = useState([]);


  const [isLoading, setIsLoading] = useState(true);
  const [selectedAll, setSelectedAll] = useState(false);
  const [deletingPayments, setDeletingPayments] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [rowNumber, setRowNumber] = useState(0);
  const [startRowNumber, setStartRowNumber] = useState(0);
  const [endRowNumber, setEndRowNumber] = useState(30);
  const [visibleRows, setVisibleRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChangePage = (event, newPage) => {
    // console.log(visibleRows[0]);
    setPage(newPage);
    setStartRowNumber(newPage * rowsPerPage);
    if (rowNumber > newPage * rowsPerPage + rowsPerPage) {
      setEndRowNumber(newPage * rowsPerPage + rowsPerPage);
    } else {
      setEndRowNumber(rowNumber);
    }
    setVisibleRows(
      loadedPayments?.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage)
    );
  }

  console.log(visibleRows)

  function showRegDate(unFormattedDate) {
    const date = new Date(unFormattedDate);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  // Action Menu 

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  // const handleToggle = () => {
  //   setOpen((prevOpen) => !prevOpen);
  // };

  // const handleClose = (event) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return;
  //   }

  //   setOpen(false);
  // };

  // function handleListKeyDown(event) {
  //   if (event.key === 'Tab') {
  //     event.preventDefault();
  //     setOpen(false);
  //   } else if (event.key === 'Escape') {
  //     setOpen(false);
  //   }
  // }

  // const selectAllChange = (event) => {
  //   const checked = event.target.checked;
  //   console.log(checked);
  // }

  const singleChange = (event) => {
    const index = event.target.value;
    const itemsRef = [...itemSelection];

    itemsRef[index] = event.value.checked;

    setItemSelection([...itemsRef]);

    console.log(itemSelection);


  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {

    getPayments()

  }, []);

  const getPayments = () => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v1/admin/payment', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        setIsLoading(false);
        setPaymentsData(data?.data);
        setVisibleRows(
          data?.data?.slice(startRowNumber, endRowNumber)
        );
        setRowNumber(data?.data.length);

      });
  }

  const switchCategory = (entityType) => {
    switch (entityType) {
      case 'course':
        return <div className="course-category"  >
          <img src={Courses} alt="" />
          <span> Courses </span>
        </div>
      case 'blog':
        return <div className="blog-category"  >
          <img src={Blogs} alt="" />
          <span> Blogs </span>
        </div>
      case 'devotional':
        return <div className="devotional-category"  >
          <img src={Devotionals} alt="" />
          <span> Devotionals </span>
        </div>
      default:
        return;
    }
  }

  const handleSelectAll = () => {
    if (selectedAll) {
      setSelectedAll(false)
      setSelectedPayments([])
    } else {
      setSelectedAll(true)

      setSelectedPayments([])

      for (let i = 0; i < loadedPayments.length; i++) {
        setSelectedPayments(prev => [...prev, { entity: loadedPayments[i]?.entity, entityId: loadedPayments[i]?.entityId, id: loadedPayments[i]?.timeStamp }])
      }
    }
  }

  const deleteMultiplePayments = () => {

    setDeletingPayments(true)

    Promise.all(
      selectedPayments.map(async payment => {
        deletePaymentFirebase(payment.id)
      })
    ).then(data => {
      console.log('selected payments deleted')
      setSelectedPayments([])
      setDeletingPayments(false)
    })

  }

  const deletePaymentFirebase = (id) => {



  }


  // function toggleDrawerHandler(drawer, user) {
  //   drawerCtx.openDrawer();
  //   drawerCtx.setDetails(drawer, user);
  // };

  return (
    <div className="user-container">
      <div data-aos="fade-up" className='user-heading'>
        <h1>Payments</h1>
        <div className='search-filters' style={{ height: '1rem' }} >
          <TextField className='search-texfield' variant='outlined' onChange={(e) => { setSearchTerm(e.target.value); }} placeholder="Search" />
        </div>
      </div>
      <TableContainer component={Paper} className="user-table-container" style={{ height: '90%' }}>
        <Table sx={{ "& td": { border: 0 } }} className="users-table" aria-label="simple table" data-aos="fade-up">
          <TableHead className='table-head' style={{ width: '100%', position: "sticky", top: "0", background: "#FFF", zIndex: "100" }}>
            <TableRow>
              <TableCell align='center'><Checkbox checked={selectedAll} onClick={() => handleSelectAll()} /></TableCell>
              <TableCell align='left'>Category</TableCell>
              <TableCell>Details</TableCell>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='table-body'>

            {
              visibleRows?.length
                ? visibleRows?.filter((data) => data.email.includes(searchTerm)).map((data, i) => (
                  <TableRow key={data.id} className="table-row">
                    <TableCell className="TableCell" style={{ width: 80 }} ><Checkbox value={i} checked={itemSelection[i]} onChange={(event) => singleChange(event)} /></TableCell>
                    <TableCell className="TableCell review-category-container" style={{ width: 180 }} >{switchCategory(data?.entityType)}</TableCell>
                    <TableCell className="TableCell" align='left' style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "start" }} >
                      <span>Course Name</span>
                      <span style={{ fontWeight: "lighter" }}>{data.email}</span></TableCell>
                    <TableCell align="left" className="TableCell" >{showRegDate(data.transactionDate)}</TableCell>
                    <TableCell align="left" ><span style={{ fontWeight: "bold" }}>{data?.amount ? (data?.amount / 100).toLocaleString("en-US", { style: "currency", currency: "USD" }) : null}</span></TableCell>
                  </TableRow>
                ))
                : null
            }

          </TableBody>
        </Table>
        <div className="table-pagination" style={{ width: '100%', position: "sticky", bottom: "0", right: "0", left: "0", background: "#FFF", zIndex: "100" }}>

          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={loadedPayments?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
          // labelRowsPerPage="Rows per page"
          />

        </div>
      </TableContainer>



    </div>
  );

}
export default Payments;