import React from 'react';
import courses from '../../assets/img/advertising/advertiseing-courses.png'
import devotional from '../../assets/img/advertising/advertiseing-devotional.png'
import home from '../../assets/img/advertising/advertiseing-home.png'
import { BiEdit } from 'react-icons/bi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { AiOutlinePlus } from 'react-icons/ai'
import { Link } from 'react-router-dom';

export default function Advertising() {
  return (
    <div className="advertising-container">
      <div className="advertising-heading-and-create-new-button-container">
        <h1>Advertising</h1>
        <div style={{ display: 'flex', alignItems: "center", gap: '5px' }}><button> <AiOutlinePlus /> CREATE NEW</button></div>
      </div>
      <div className="pages-container">
        <div className="advertising-pages advertising-home-page">
          <div className="image-wrapper">
            <img src={home} alt="Home Page Image" />
            <h4>Home Page</h4>
            <div className="manage-and-other-buttons-container">
              <Link to="/advertising/manage-homepage"><button className="manage-button">Manage</button></Link>
              <div className="edit-and-delete-button">
                <button className="edit-button"><BiEdit /></button>
                <button className="delete-button"><RiDeleteBin6Line /></button>
              </div>
            </div>
          </div>
        </div>
        <div className="advertising-pages advertising-devotionals-page">
          <div className="image-wrapper">
            <img src={devotional} alt="Devotionals Page Image" />
            <h4>Devotionals Page</h4>
            <div className="manage-and-other-buttons-container">
              <Link to='/advertising/manage-devotionalspage'><button className="manage-button">Manage</button></Link>
              <div className="edit-and-delete-button">
                <button className="edit-button"><BiEdit /></button>
                <button className="delete-button"><RiDeleteBin6Line /></button>
              </div>
            </div>
          </div>
        </div>
        <div className="advertising-pages advertising-courses-page">
          <div className="image-wrapper">
            <img src={courses} alt="Courses Page Image " />
            <h4>Courses Page</h4>
            <div className="manage-and-other-buttons-container">
              <Link to='/advertising/manage-coursespage'><button className="manage-button">Manage</button></Link>
              <div className="edit-and-delete-button">
                <button className="edit-button"><BiEdit /></button>
                <button className="delete-button"><RiDeleteBin6Line /></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
