import { useContext, useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext';
import { CloseRounded } from '@material-ui/icons';
import { minWidth } from '@material-ui/system';

const FileNameEditModal = ({ modalDetails }) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const [newName, setNewName] = useState('')

  useEffect(() => {

    setNewName(modalDetails.details.title)

  }, [modalDetails])


  const handleCloseModal = () => {
    modalCtx.closeModal();
  }

  const updateCourseFile = () => {
    if (authCtx.isLoggedIn) {

      const payload = {
        "courseId": modalDetails.details.courseId,
        "episodeId": 0,
        "fileName": modalDetails.details.fileName,
        "title": newName,
        "link": modalDetails.details.link
      }

      fetch(`${process.env.REACT_APP_API_URI}/v1/admin/courses/${modalDetails.details.id}/file`, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify(payload)
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status == "success") {
          modalCtx.closeModal();
        } else {
          console.error("Error while updating file name...");
        }
      }).catch((err) => {
        console.error("Error while updating file name...", err);
      });
    }
  }

  const updateEpisodeFile = () => {
    if (authCtx.isLoggedIn) {

      const payload = {
        "courseId": modalDetails.details.courseId,
        "episodeId": modalDetails.details.episodeId,
        "fileName": modalDetails.details.fileName,
        "title": newName,
        "link": modalDetails.details.link
      }

      fetch(`${process.env.REACT_APP_API_URI}/v1/admin/courses/episode/${modalDetails.details.id}/file`, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify(payload)
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status == "success") {
          modalCtx.closeModal();
        } else {
          console.error("Error while updating file name...");
        }
      }).catch((err) => {
        console.error("Error while updating file name...", err);
      });
    }
  }

  console.log(modalDetails)

  return (
    <div className="modal" style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <div className='head' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: "500px" }}>
        <h3>Edit File Name</h3>
        <CloseRounded onClick={() => modalCtx.closeModal()}></CloseRounded>
      </div>

      <div>
        <span style={{ fontWeight: "bold" }}>File Title</span>
        <TextField value={newName} onChange={({ target }) => setNewName(target.value)} placeholder='File Title' style={{ marginTop: "20px" }} fullWidth></TextField>
      </div>
      <div className="footer">
        <Button variant='outlined' style={{ borderColor: "#1359E4", color: "#1359E4" }} fullWidth onClick={() => modalCtx.closeModal()}>Cancel</Button>
        <Button variant='contained' style={{ backgroundColor: "#1359E4", color: "white" }} fullWidth onClick={() => modalDetails.type == 'episode' ? updateEpisodeFile() : updateCourseFile()}  >Save File</Button>
      </div>
    </div>
  )
}

export default FileNameEditModal;
