import React, { useContext, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import ModalContext from '../../store/ModalContext';
import Courses from '../../assets/img/advertising/Courses ads placement.png';
import AuthContext from '../../store/AuthContext';

export default function AdvertisingCoursesPage() {
  const modalCtx = useContext(ModalContext);
  const [advertisementData, setAdvertisementData] = useState({});
  const authCtx = useContext(AuthContext);
  const [togglehover, setToggleHover] = useState(false);

  useEffect(() => {
    fetchAdvertisementData();
  }, []);

  useEffect(() => {
    fetchAdvertisementData();
  }, [modalCtx]);

  const fetchAdvertisementData = async () => {
    try {
      const response = await fetch('https://api.kingdomempowered.com/v1/advertisement/slot/page/2', {
        headers: {
          Authorization: `Bearer ${authCtx.token}`
        }
      });
      const data = await response.json();
      if (data.status === 'success' && data.data.length > 0) {
        setAdvertisementData(data.data[0]);
      }
    } catch (error) {
      console.error('Error fetching advertisement data:', error);
    }
  };

  const openUploadImageModal = (id) => {
    modalCtx.setDetails('upload-image', { aspectRatio: id === 1 ? 7 : 4, origin: 'advertisingCourses', id });
    // modalCtx.setFixedsize(true);

    // if (id === 1) {
    //   modalCtx.setMinHeight(92);
    //   modalCtx.setMaxHeight(92);
    //   modalCtx.setMinWidth(641);
    //   modalCtx.setMaxWidth(641);
    // } else if (id === 2) {
    //   modalCtx.setMinHeight(144);
    //   modalCtx.setMaxHeight(144);
    //   modalCtx.setMinWidth(601);
    //   modalCtx.setMaxWidth(601);
    // }

    modalCtx.openModal();
  };

  const renderAdvertisementSlot = (id, link) => {
    if (link) {
      return (
        <div
          className={`advertising-courses-img-cropper${id}`}
          style={{ cursor: 'pointer' }}
          onClick={() => openUploadImageModal(id)}
          onMouseEnter={() => setToggleHover(true)}
          onMouseLeave={() => setToggleHover(false)}
        >
          <img src={link} alt={`Slot ${id}`} />
          <div
            className="advertisement-text-icon-container-with-img"
            style={{ display: togglehover ? 'flex' : 'none' }}
          >
            <Add />
            <h3 className="add-advertisement-text">Add Advertisement</h3>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={`advertising-courses-img-cropper${id}`}
          style={{ cursor: 'pointer' }}
          onClick={() => openUploadImageModal(id)}
        >
          <div className="advertisement-text-icon-container">
            <Add />
            <h3 className="add-advertisement-text">Add Advertisement</h3>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="advertising-img-container advertising-courses-container">
      <img src={Courses} alt="" srcSet="" className="main-img" />
      {renderAdvertisementSlot(1, advertisementData.slot1Link)}
      {renderAdvertisementSlot(2, advertisementData.slot2Link)}
    </div>
  );
}