import React, { useEffect, useState, useContext } from 'react'
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { Button, Card, CircularProgress, Paper, Tab, Table, TableBody, div, TableContainer, TableHead, TableRow, Tabs } from '@mui/material'
import { Delete, Edit, FileUploadRounded, DragIndicator } from '@mui/icons-material';

import AuthContext from '../store/AuthContext.js';
import ModalContext from '../store/ModalContext.js';
import DrawerContext from '../store/DrawerContext.js';
import GetImage from '../Components/GetImage.jsx';

//assets
import placeholder from '../assets/img/placeholder.png';
import editButton from '../assets/img/blog/edit.svg';
import deleteButton from '../assets/img/blog/delete.svg';
import { Box } from '@mui/system';
import { TabPanel } from '@material-ui/lab';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';


const Episodes = () => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  const { id } = useParams();
  const navigate = useNavigate();

  // console.log("id", id);

  function toggleDrawerHandler(drawer, data) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, data);
  };

  const [episodes, setEpisodes] = useState([])
  const [episodesPublished, setEpisodesPublished] = useState([])
  const [course, setCourse] = useState([])
  const [episodeOrder, setEpisodeOrder] = useState([])

  // updating status states 
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [isReordering, setIsReordering] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [updatingStatusId, setUpdatingStatusId] = useState();
  const [updatingEpisodeOrder, setUpdatingEpisodeOrder] = useState(false);

  useEffect(() => {
    getAllEpisodes();
    getPublishedEpisodes(false)
    getCourseById()
  }, []);

  useEffect(() => {
    getAllEpisodes();
    getPublishedEpisodes(true)
  }, [drawerCtx, modalCtx]);


  const getAllEpisodes = () => {

    fetch(process.env.REACT_APP_API_URI + '/v1/admin/courses/' + id + '/episodes', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        // console.log('Success:', data);
        if (data.status == 'success') {
          setEpisodes(data?.data?.sort((a, b) => a.episodeNo - b.episodeNo))
        } else {
          if (data?.code == 'auth/argument-error') {
            navigate(`/login?destination=courses/${id}/episodes`)
            authCtx.logout()
          }
        }
      }).catch(err => console.error(err));

  }

  const getPublishedEpisodes = async (updateOrder) => {

    try {

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/courses/${id}/episodes/published`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
      const data = await res.json()

      if (data.status === 'success') {
        // console.log(data)
        setEpisodesPublished(data?.data?.sort((a, b) => a.episodeNo - b.episodeNo))
        if (updateOrder) {
          // reOrderEpisodes()
        }
      } else {
        if (data?.code == 'auth/argument-error') {
          navigate(`/login?destination=courses/${id}/episodes`)
          authCtx.logout()
        }
      }

    } catch (error) {
      console.log(error)
    }

  }

  const getCourseById = () => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/courses/${id}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        // console.log('Success:', data);
        if (data.status == 'success') {
          setCourse(data?.data)
        } else {
          if (data?.code == 'auth/argument-error') {
            navigate(`/login?destination=courses/${id}/episodes`)
            authCtx.logout()
          }
        }
      }).catch(err => console.error(err));

  }



  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  const getEpisodeOrder = (episodes) => {

    let tempEpisodeOrder = []


    episodes?.map((item, index) => {
      return tempEpisodeOrder?.push({ episodeId: item.id, orderIndex: index + 1 })
    })

    reOrderEpisodes(tempEpisodeOrder)

  }

  const postStatusClass = (id) => {
    let episode = episodes.find(obj => obj.id == id);
    if (episode && episode.status == 'published') {
      return 'post-status post-status-published';
    } else {
      return 'post-status post-status-draft';
    }
  }

  const openDeleteEpisodeModal = (id, episode) => {
    modalCtx.setDetails('confirm-delete-episode', { id: id, episode: episode });
    modalCtx.openModal();
  }

  const openDeleteDevotionalModal = (id) => {
    modalCtx.setDetails('confirm-delete-course', { id });
    modalCtx.openModal();
  }

  const openUploadImageModal = (id) => {
    modalCtx.setDetails('upload-image', { aspectRatio: undefined, origin: 'episode', id });
    modalCtx.openModal();
  }
  const openUploadVideoModal = (id) => {
    modalCtx.setDetails('upload-video', { origin: 'episode', id });
    modalCtx.openModal();
  }

  const updateStatus = async (courseId, status) => {

    setUpdatingStatus(true)
    setUpdatingStatusId(courseId)

    try {

      fetch(process.env.REACT_APP_API_URI + '/v1/admin/courses/' + courseId + '/updateStatus', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status == 'success') {
            getAllEpisodes()
          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  const getStatusClass = (id) => {
    let episode = episodes.find(obj => obj.id == id);
    if (episode && episode.status == 'published') {
      return 'author-tag published';
    } else {
      return 'author-tag';
    }
  }

  function removeTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();

    return str.replace(/<[^>]+>/ig, '').slice(0, 70);
  }


  // console.log('episodes', episodes.map((item, index) => console.log(item.episodeNo, index + 1)));

  const [selectedTab, setSelectedTab] = useState("published");

  const handleTab = (value) => {
    setSelectedTab(value);
  }
  const openPublishModal = (episode) => {
    modalCtx.setDetails('publish-episode', { episode });
    modalCtx.openModal();
  }

  const addTrailingDots = (string, limit) => {
    if (!string) return null

    var dots = "...";
    if (string?.length > limit) {
      string = string?.substring(0, limit) + dots;
    }
    return string;
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    // console.log("handleDragEnd", episodes[result?.destination.index])

    const items = Array.from(episodesPublished);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setEpisodesPublished(items);
    getEpisodeOrder(items)
    setIsDragging(false)
  };

  const handleDragStart = (result) => {
    setIsDragging(true)
    if (!result) return;

    // console.log("handleDragStart", episodes[result?.source.index])

    // const items = Array.from(episodes);
    // const [reorderedItem] = items.splice(result.source.index, 1);
    // items.splice(result.destination.index, 0, reorderedItem);

    // setEpisodes(items);
  };

  // const changeEpisodeNo = async () => {

  //   const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/courses/${id}/episode/1/update/episodeNo`, {
  //     method: 'PUT',
  //     mode: 'cors',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ "episodeNo": 33 })
  //   })
  //   const data = await res.json()

  //   if (data.status == 'success') {
  //     console.log(data)
  //   }
  // }

  const reOrderEpisodes = (episodes) => {
    if (isDragging) return;
    setUpdatingEpisodeOrder(true)

    let requests = episodes.map((episode, i) => {
      //create a promise for each API call
      return new Promise(async (resolve, reject) => {
        const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/courses/${id}/episode/${episode.episodeId}/update/episodeNo`, {
          method: 'PUT',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({ "episodeNo": episode.orderIndex })
        })
        const data = await res.json()
        if (data.status == 'success') {
          // console.log(episode)
          resolve(data)
        } else {
          reject('error updating episode number.')
        }
      })
    })
    Promise.all(requests).then((episodeProgressData) => {
      episodeProgressData.forEach((res, i) => {
        if (res) {
          setUpdatingEpisodeOrder(false)
          console.log('first', res, i)
          // getPublishedEpisodes()
        }
      })
      setIsReordering(false)
    }).catch(err => console.log(err))
  }

  // console.log(episodes)
  // console.log(episodesPublished)
  // console.log(episodeOrder)

  return (

    <div className='episodes-container'>

      <header className='course-episode-header'>
        <div className='image-title'>{course && course.image ? <GetImage imageRef={course.image} alt="Course Image" /> : ''}

          <h1>{course?.title} - episodes</h1> </div>

        <div className="create-episode">
          {/* {
            isReordering
              ? <>
                <Button className='header-cta' onClick={() => reOrderEpisodes()} style={{ backgroundColor: 'white', color: '#3f8f29', marginRight: '1rem' }}>Save</Button>
                <Button className='header-cta' onClick={() => setIsReordering(false)} style={{ backgroundColor: 'white', color: '#DE1A24', marginRight: '1rem' }}>Cancel</Button>
              </>
              : <Button className='header-cta' onClick={() => setIsReordering(true)} style={{ backgroundColor: 'white', color: '#1359E4', marginRight: '1rem' }}>Re-Order Episodes</Button>
          } */}
          <Button className='header-cta' disabled={isReordering} onClick={() => toggleDrawerHandler('create-course-episode', { id })} style={{ backgroundColor: 'white', color: '#1359E4' }}>+ Create New</Button>
        </div>
      </header>


      <div className="episode-nav">
        <div onClick={() => handleTab("published")}>
          <span>Live Episodes</span>
          {selectedTab == "published" ? <div className='line'></div> : ""}

        </div>
        {
          isReordering
            ? null
            : <div onClick={() => handleTab("draft")}>
              <span>Drafts</span>
              {selectedTab == "draft" ? <div className='line'></div> : ""}
            </div>
        }

      </div>

      <div className={`episodes-${selectedTab}`}>
        {
          episodes?.length > 0 && selectedTab == 'draft'
            ? episodes?.filter(episode => episode.status === selectedTab)
              .map((episode, index) => (
                <Card className='blog-card' key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <div className={postStatusClass(episode.id)}></div>
                  {
                    episode.image
                      ? episode.video.length > 1 ? <div className='devo-img-container' onClick={() => openUploadImageModal(episode)} style={{ position: 'relative' }}>
                        <GetImage imageRef={episode.image} alt={episode.title} />
                        <Button className='episode-upload-btn' style={{ position: 'absolute', right: '0', bottom: '0', margin: '4px', border: '1px solid white', color: 'white' }} ><FileUploadRounded /> Update Thumbnail</Button>
                      </div> : <div className='devo-img-container' style={{ position: 'relative', filter: "grayscale(100%)" }}>
                        <GetImage imageRef={episode.image} alt={episode.title} />
                        <Button className='episode-upload-btn' onClick={() => { openUploadVideoModal(episode) }} style={{ position: 'absolute', top: '50%', left: '50%', margin: '4px', border: '1px solid white', color: 'white', transform: "translate(-50%, -50%)", background: "#0000009e" }} >No Video Uploaded</Button>
                        <Button onClick={() => openUploadImageModal(episode)} className='episode-upload-btn' style={{ position: 'absolute', right: '0', bottom: '0', margin: '4px', border: '1px solid white', color: 'white' }} ><FileUploadRounded /> Update Thumbnail</Button>
                      </div>
                      : <div className='placeholder-img' style={{ position: 'relative' }} onClick={() => openUploadImageModal(episode)}>
                        <Button className='episode-upload-btn' onClick={() => { openUploadVideoModal(episode) }} style={{ position: 'absolute', top: '50%', left: '50%', margin: '4px', border: '1px solid white', color: 'white', transform: "translate(-50%, -50%)", background: "#0000009e" }} >No Video Uploaded</Button>
                        <img src={placeholder} alt="placeholder" />
                        <span className='placeholder-text' >No image. Click to Add a image</span>
                      </div>
                  }

                  <div className='devo-title'>

                    <p className='devo-title-text' >{episode.title}</p>

                    <p className='devo-title-text' style={{ textOverflow: "ellipsis", overflow: "hidden", height: "100px", }} dangerouslySetInnerHTML={{ __html: episode.text }} ></p>

                    <NavLink to={`/episode/supporting-files/${episode.id}/course/${course?.id}`}>
                      <Button className='supporting-files-btn supporting-files-episode-btn ' >Supporting Files</Button>
                    </NavLink>

                    <div className='editable-row'>
                      <div className='status-row'>
                        <span>Status -</span>
                        <div className="author-tags"
                          onClick={() => openPublishModal(episode)}
                        >
                          {
                            (updatingStatus && updatingStatusId == episode.id) ?
                              <CircularProgress size={20} />
                              :
                              <span className={getStatusClass(episode.id)}>{typeof episode.status !== 'undefined' ? episode.status.toUpperCase() : null}</span>
                          }
                        </div>
                      </div>

                      <div className='edit-buttons'>

                        <img className='back-button action-icon' onClick={() => toggleDrawerHandler('edit-episode', episode)} src={editButton} alt='' />
                        <img className='back-button action-icon' onClick={() => openDeleteDevotionalModal(course.id)} src={deleteButton} alt='' />
                      </div>
                    </div>
                    <Button className='episode-upload-btn' onClick={() => openUploadVideoModal(episode)} >Upload Video</Button>
                  </div>

                </Card>
              ))
            : null
        }
        {
          episodesPublished?.length > 0 && selectedTab == 'published'
            ? <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="episodes">
                {(provided) => (
                  <div className='drag-drop-episodes' ref={provided.innerRef} {...provided.droppableProps}>
                    {
                      episodesPublished?.map((episode, index) => (
                        <Draggable draggableId={episode.id.toString()} index={index} key={episode.id}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} className={`episode-row ${isDragging ? 'dragging-episode-row' : null}`}>
                              <div className={postStatusClass(episode.id)}></div>
                              {/* 
                              {
                                isReordering
                                  ? <div {...provided.dragHandleProps} style={{ cursor: "grab" }} className="card-index-drag">
                                    <span>{index + 1}</span>
                                    <DragIndicator />
                                  </div>
                                  : <div className="card-index-drag">
                                    <span>{index + 1}</span>
                                  </div>
                              } */}

                              {
                                !updatingEpisodeOrder
                                  ? <div {...provided.dragHandleProps} style={{ cursor: "grab" }} className="card-index-drag">
                                    {/* <span>{index + 1}</span> */}
                                    <DragIndicator />
                                  </div>
                                  : <div style={{ cursor: "not-allowed" }} className="card-index-drag">
                                    {/* <span>{index + 1}</span> */}
                                    <DragIndicator />
                                  </div>
                              }


                              {
                                episode?.image
                                  ? episode?.video?.length > 1
                                    ? <div className='devo-img-container' onClick={() => openUploadImageModal(episode)} style={{ position: 'relative' }}>
                                      <GetImage imageRef={episode.image} alt={episode.title} />
                                      <Button className='episode-upload-btn' style={{ position: 'absolute', right: '0', bottom: '0', margin: '4px', border: '1px solid white', color: 'white' }} ><FileUploadRounded /> Update Thumbnail</Button>
                                    </div>
                                    : <div className='devo-img-container' style={{ position: 'relative', filter: "grayscale(100%)" }}>
                                      <GetImage imageRef={episode.image} alt={episode.title} />
                                      <Button className='episode-upload-btn' onClick={() => { openUploadVideoModal(episode) }} style={{ position: 'absolute', top: '50%', left: '50%', margin: '4px', border: '1px solid white', color: 'white', transform: "translate(-50%, -50%)", background: "#0000009e" }} >No Video Uploaded</Button>
                                      <Button onClick={() => openUploadImageModal(episode)} className='episode-upload-btn' style={{ position: 'absolute', right: '0', bottom: '0', margin: '4px', border: '1px solid white', color: 'white' }} ><FileUploadRounded /> Update Thumbnail</Button>
                                    </div>
                                  : <div className='placeholder-img' style={{ position: 'relative' }} onClick={() => openUploadImageModal(episode)}>
                                    <Button className='episode-upload-btn' onClick={() => { openUploadVideoModal(episode) }} style={{ position: 'absolute', top: '50%', left: '50%', margin: '4px', border: '1px solid white', color: 'white', transform: "translate(-50%, -50%)", background: "#0000009e" }} >No Video Uploaded</Button>
                                    <img src={placeholder} alt="placeholder" />
                                    <span className='placeholder-text' >No image. Click to Add a image</span>
                                  </div>
                              }

                              <div className='episode-details'>
                                <div className="episode-title-desc">
                                  <p className='devo-title-text' style={{ fontSize: '1.1rem', fontWeight: '700' }} >{episode.title}</p>
                                  <p className='devo-title-text' style={{ textOverflow: "ellipsis", overflow: "hidden", height: "100px", }} dangerouslySetInnerHTML={{ __html: episode.text }} ></p>
                                </div>


                                {
                                  isReordering
                                    ? null
                                    : <div className='editable-row'>
                                      <div className="status-edit-container">
                                        <div className='status-row'>
                                          <span>Status -</span>
                                          <div className="author-tags" onClick={() => openPublishModal(episode)}>
                                            {
                                              (updatingStatus && updatingStatusId == episode.id) ?
                                                <CircularProgress size={20} />
                                                :
                                                <span className={getStatusClass(episode.id)}>{typeof episode.status !== 'undefined' ? episode.status.toUpperCase() : null}</span>
                                            }
                                          </div>
                                        </div>

                                        <div className='edit-buttons'>
                                          <img className='back-button action-icon' onClick={() => toggleDrawerHandler('edit-episode', episode)} src={editButton} alt='' />
                                          <img className='back-button action-icon' onClick={() => openDeleteDevotionalModal(course.id)} src={deleteButton} alt='' />
                                        </div>
                                      </div>
                                      <div className="episode-action-btns">
                                        <NavLink to={`/episode/supporting-files/${episode.id}/course/${course?.id}`}>
                                          <Button className='episode-action-btn' >Supporting Files</Button>
                                        </NavLink>
                                        <Button className='episode-action-btn' onClick={() => openUploadVideoModal(episode)} >Upload Video</Button>
                                      </div>
                                    </div>
                                }
                              </div>
                              {provided.placeholder}
                            </div>
                          )}
                        </Draggable>
                      ))
                    }
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            : null
        }

      </div>
    </div >
  )
}

export default Episodes;

