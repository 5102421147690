import React, { useEffect, useState, useContext } from 'react'

import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Delete, Edit, Star, StarBorder, StarRounded } from '@mui/icons-material';

import AuthContext from '../store/AuthContext.js';
import ModalContext from '../store/ModalContext.js';
import DrawerContext from '../store/DrawerContext.js';

const Topics = () => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [topics, setTopics] = useState([]);

  // updating status states 
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [updatingBlogStatus, setUpdatingBlogStatus] = useState(false);
  const [updatingStatusId, setUpdatingStatusId] = useState();
  const [updatingBlogStatusId, setUpdatingBlogStatusId] = useState();


  useEffect(() => {
    getAllTopics();
  }, []);

  useEffect(() => {
    getAllTopics();
  }, [drawerCtx, modalCtx]);

  const getAllTopics = () => {

    fetch(process.env.REACT_APP_API_URI + '/v1/topics', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token }
    })
      .then(response => {
        // console.log(response);
        return response.json();
      }).then(data => {
        console.log('Success:', data);
        if (data.status == 'success') {
          setTopics(data?.data)
        }
      }).catch(err => console.error(err));

  }

  const updateStatusDevotionals = async (devId, status) => {

    setUpdatingStatus(true)
    setUpdatingStatusId(devId)

    try {

      fetch(process.env.REACT_APP_API_URI + '/v1/admin/topics/' + devId + '/updateFeatured', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
        body: JSON.stringify({ status })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == 'success') {
            getAllTopics();
            setUpdatingStatus(false);
            setUpdatingStatusId(null);
          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  const updateStatusBlogs = async (blogId, status) => {

    setUpdatingBlogStatus(true);
    setUpdatingBlogStatusId(blogId);

    try {

      fetch(process.env.REACT_APP_API_URI + '/v1/admin/topics/' + blogId + '/updateFeaturedBlog', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
        body: JSON.stringify({ status })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == 'success') {
            getAllTopics();
            setUpdatingBlogStatus(false);
            setUpdatingBlogStatusId(null);
          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  const getStatusClassForBlogs = (id) => {
    let topic = topics.find(obj => obj.id == id);
    if (topic && topic.featured_blogs == 'yes') {
      return 'author-tag published';
    } else {
      return 'author-tag';
    }
  }

  const getStatusClassForDevotionals = (id) => {
    let topic = topics.find(obj => obj.id == id);
    if (topic && topic.featured_devotionals == 'yes') {
      return 'author-tag published';
    } else {
      return 'author-tag';
    }
  }

  const openDeleteTopicModal = (id) => {
    modalCtx.setDetails('confirm-delete-topic', { id });
    modalCtx.openModal();
  }


  return (
    <div className='container'>

      <header>
        <h1>Topics</h1>
        <div className="create-devotional">
          {/* <Button className='create-devotional-btn' onClick={() => handleModal('createDevotional')} >+ Create New</Button> */}
          <Button className='header-cta' onClick={() => toggleDrawerHandler('create-topic', null)}>+ Create New</Button>
        </div>
      </header>

      <div className="data-table">
        <TableContainer className='topics-table' component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ position: "sticky", top: "0", background: "#FFF", zIndex: "100" }}>
              <TableRow>
                <TableCell align="left" style={{ fontWeight: 700, fontSize: '16px' }} >Topic</TableCell>
                {/* <TableCell align="center">Title</TableCell>
									<TableCell align="center">Author</TableCell>
									<TableCell align="center">Description</TableCell> */}
                <TableCell align="center" style={{ fontWeight: 700, fontSize: '16px' }} >Featured for Blogs</TableCell>
                <TableCell align="center" style={{ fontWeight: 700, fontSize: '16px' }} >Featured for Devotionals</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topics?.map((topic) => (
                <TableRow style={{ background: '#fff', boxShadow: "10px 3px 10px rgba(0, 0, 0, 0.0392156863)", borderRadius: "8px" }} key={topic.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                  <TableCell component="th" scope="row">
                    <span style={{ fontWeight: 600, fontSize: '16px' }} >{topic.title}</span>
                  </TableCell>
                  {/* <TableCell align="center">{topic.title}</TableCell>
											<TableCell align="center">{topic.author}</TableCell>
											<TableCell align="center">{topic.description}</TableCell> */}

                  <TableCell align="center" className='topic-featured'>
                    <div className="author-tags" onClick={() => updateStatusBlogs(topic.id, topic.status)}>
                      {
                        (updatingBlogStatus && updatingBlogStatusId == topic.id) ? <CircularProgress size={20} /> : <StarRounded className={getStatusClassForBlogs(topic.id)} />
                      }
                    </div>
                  </TableCell>

                  <TableCell align="center" className='topic-featured'>
                    <div className="author-tags" onClick={() => updateStatusDevotionals(topic.id, topic.status)}>
                      {
                        (updatingStatus && updatingStatusId == topic.id) ? <CircularProgress size={20} /> : <StarRounded className={getStatusClassForDevotionals(topic.id)} />
                      }
                    </div>
                  </TableCell>

                  <TableCell align="right" className="action-buttons-cell">
                    <Delete style={{ marginLeft: '1rem' }} className="devo-delete-btn action-icon" onClick={(e) => openDeleteTopicModal(topic.id)} />
                    <Edit className="devo-edit-btn action-icon" onClick={() => toggleDrawerHandler('edit-topic', topic)} />
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default Topics;
