import { useContext } from 'react';
import { Button } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext';

const DeleteCourseModal = ({ modalDetails }) => {

  // console.log("delete modal props", modalDetails);
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }

  const deleteCourse = (id) => {
    if (authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + '/v1/admin/courses/' + id, {
        method: 'DELETE',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        // body: JSON.stringify({ id })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status == "success") {
          // navigate("/courses", { replace: true });
          // drawerCtx.closeDrawer();
          window.location.reload();
        } else {
          console.error("Error while deleting course...");
          // setSnackbarMessage(data.message);
          // setOpenSnackbar(true);
        }
      }).catch((err) => {
        console.error("Error while deleting course...", err);
        handleCloseModal();
      });
      console.log("delete course id", id);
    }
  }

  return (
    <div className="modal">
      <p>Are you sure you want to delete this Course?</p>
      <div className="footer">
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>No</Button>
        <Button className="confirm-btn" onClick={() => deleteCourse(modalDetails.id)}>Yes</Button>
      </div>
    </div>
  )
}

export default DeleteCourseModal;
