import React, { useContext, useState, useEffect } from 'react';
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext.js';

import 'react-quill/dist/quill.snow.css';
import { Add, CloseRounded, KeyboardArrowDownRounded } from '@mui/icons-material';

const EditBlogAuthorModal = ({ modalDetails }) => {


  // console.log("modalDetails", modalDetails);

  //context
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const [authors, setAuthors] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [author, setAuthor] = useState('');
  const [searchAuthor, setSearchAuthor] = useState('')
  const [addAuthorTitle, setAddAuthorTitle] = useState('')

  const [showAuthorResults, setShowAuthorResults] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)

  //effect
  useEffect(() => {
    setSelectedAuthors(modalDetails.author)
    getAllAuthors()
  }, [])

  //I had to do this effect because the state doesn't mutate immediately
  useEffect(() => {
    addNewlyCreatedAuthor(addAuthorTitle)
  }, [authors, addAuthorTitle])

  //change visibility and get all authors when searchAuthor is changed
  useEffect(() => {
    if (searchAuthor.length > 0) {
      setShowAuthorResults(true)
    } else {
      setShowAuthorResults(false)
    }
    getAllAuthors()
  }, [searchAuthor])


  // submit author to db
  const submitEditAuthor = (id) => {

    if (authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + `/v1/admin/blogs/${modalDetails.id}/author`, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({
          // authorId: id,
          author_id: id,
          // id: modalDetails.id
        })
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status == "success") {
          console.log(data)
          getAllAuthors()
        } else {
          console.error("Error while editing devotional...");
        }
      }).catch((err) => console.error("Error while editing devotional...", err));
    }

  }

  // get all authors
  const getAllAuthors = async (title) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v1/authors`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const res = await response.json();
      if (res.status == 'success') {
        console.log(res)
        setAuthors(res?.data)
        setAddAuthorTitle(title)
      }
    } catch (err) {
      console.log(err);
    }
  }

  //deleting author
  const DeleteAuthor = async (blogId, id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/blogs/${blogId}/author/${id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const res = await response.json();
      if (res.status == 'success') {
      }
    } catch (err) {
      console.log(err);
    }
  }

  // create new author
  const submitCreateAuthor = async (title) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v1/authors`,
        {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({
            title,
          })
        })

      const res = await response.json();
      if (res.status == 'success') {
        getAllAuthors(title)
      }
    } catch (err) {
      console.log(err);
    }
  }

  //this function will add the newly created author to the devotional without selecting it twice
  const addNewlyCreatedAuthor = (title) => {
    for (let i = 0; i < authors.length; i++) {
      if (authors[i].full_name === title) {
        submitEditAuthor(authors[i].id)
        setSelectedAuthors(prev => [...prev, authors[i]]);
        setAddAuthorTitle('')
        setSearchAuthor('')
      }
    }
  }

  //check if author exist
  const handleCreateNewAuthor = (title) => {
    if (authors.some(author => author.title == title)) {
      setOpenSnackbar(true)
      return;
    } else {
      submitCreateAuthor(title)
    }
  }

  //add selected author to db function and array
  const handleSelectAuthor = (author) => {
    console.log(author)
    if (!selectedAuthors.some(el => el.id === author.id)) {
      submitEditAuthor(author.id)
      setSelectedAuthors(prev => [...prev, author]);
    } else {
      return
    }
  }

  //filter and delete author from db and state
  const handleFilterArray = (id) => {
    DeleteAuthor(modalDetails.id, id)
    const newArr = selectedAuthors.filter((arr) => {
      return arr.id !== id
    })
    setSelectedAuthors(newArr)
  }

  //set search author string
  const handleSearchAuthor = (e) => {
    setSearchAuthor(e.target.value)
  }

  //handling author visibility
  const handleShowAuthorResult = () => {
    if (showAuthorResults) {
      setShowAuthorResults(false)
    } else {
      setShowAuthorResults(true)
    }
  }



  return (
    <>
      <div className="edit-author-container">
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert severity="info" sx={{ width: '100%' }}>
            Author already exist.
          </Alert>
        </Snackbar>
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Edit Blog Author</h1>
        </header>

        <section>
          {/*           
          <FormControl fullWidth >
            <InputLabel id="select-author">Author</InputLabel>
            <Select
              labelId="select-author"
              value={author}
              label="Author"
              onChange={handleChangeAuthor}
              className='select-author'
              disableEnforceFocus
            >
              {
                authors?.map((author, i) => {
                  return <MenuItem key={i} onClick={() => handleSelectAuthor(author)} value={author.full_name}>{author.full_name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <div className="author-list">
            {
              selectedAuthors.map((author, i) => (
                <span key={i} className='author-author'>{author.full_name}<CloseRounded className='remove-author' onClick={() => handleFilterArray(author.id)} /></span>
              ))
            }
          </div>
          <div className="save-changes-btn">
            <Button variant="contained" component="label" disabled={false} 
              onClick={(e) => { modalCtx.closeModal() }
                }>Save Changes
            </Button>
          </div> */}

          <div className="search-author-container">
            <input type="text" placeholder='Search for author...' value={searchAuthor} onChange={handleSearchAuthor} />
            <KeyboardArrowDownRounded className='drop-down-btn' onClick={() => handleShowAuthorResult()} style={showAuthorResults ? { transform: 'rotateZ(180deg)' } : null} />
          </div>
          <div className="authors-menu" style={showAuthorResults ? null : { display: 'none' }} >
            <ul>
              {
                authors?.filter((author) => author.full_name.toLowerCase().includes(searchAuthor.toLowerCase())).map((author, i) => {
                  return <li key={i} onClick={() => handleSelectAuthor(author)}>{author.full_name}</li>
                })
              }
              {/* {searchAuthor.length == 0 ? null : <li className='add-new-author' onClick={() => handleCreateNewAuthor(searchAuthor)}><Add/> &nbsp; Add new author &nbsp; <span className='author-text'>"{searchAuthor}"</span></li> } */}
              {/* {searchAuthor.length == 0 ? null : <li className='no-author'>No Author found!!</li> } */}
            </ul>
          </div>
          <div className="author-list">
            {
              selectedAuthors.map((author, i) => (
                <span key={i} className='author-tag'>{author.full_name}<CloseRounded className='remove-author' onClick={() => handleFilterArray(author.id)} /></span>
              ))
            }
          </div>
          <div className="save-changes-btn">
            <Button variant="contained" component="label" disabled={false}
              onClick={(e) => modalCtx.closeModal()}>Save Changes
            </Button>
            <Button className='close-btn' variant="contained" component="label" disabled={false}
              onClick={(e) => modalCtx.closeModal()}>Close
            </Button>
          </div>
        </section>
      </div>
    </>
  )
}

export default EditBlogAuthorModal;
