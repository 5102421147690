// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const app = initializeApp({
  apiKey: "AIzaSyD6Fkn-088fHr92EF0VrdtLi2pelDfc9iI",
  authDomain: "kingdom-empowered.firebaseapp.com",
  projectId: "kingdom-empowered",
  storageBucket: "kingdom-empowered.appspot.com",
  messagingSenderId: "49987204181",
  appId: "1:49987204181:web:0fd20dac21dc70c10050dc",
  measurementId: "G-XCJWJXX408"
});

export const auth = getAuth();
export default app