import React, { useEffect, useState, useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';

import GetImage from '../Components/GetImage'

import AuthContext from '../store/AuthContext.js';
import ModalContext from '../store/ModalContext.js';
import DrawerContext from '../store/DrawerContext.js';

//assets
import placeholder from '../assets/img/placeholder.png';
import editButton from '../assets/img/blog/edit.svg';
import deleteButton from '../assets/img/blog/delete.svg';

import { Button, Card, Checkbox, CircularProgress, FormControlLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { AddRounded, CalendarMonth, CloseRounded, Delete, Edit } from '@mui/icons-material';
import Switch from '@mui/material/Switch';
import moment from 'moment';
import GetCourseEpisodeThumbs from '../Components/GetCourseEpisodeThumbs';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} onChange={(e) => {
    console.log(e);
  }} />
))(({ theme }) => ({
  width: 42,
  height: 22,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#1359E4' : '#1359E4',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


const Courses = () => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const navigate = useNavigate();

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [courses, setCourses] = useState([]);
  const [coursePage, setCoursePage] = useState(2);
  const [tags, setTags] = useState([]);
  const [topics, setTopics] = useState([]);
  const [topicsArray, setTopicsArray] = useState([]);

  // updating status states 
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [updatingStatusId, setUpdatingStatusId] = useState();

  // updating status states 
  const [lastpage, setLastPage] = useState(0);
  const [status, setStatus] = useState('both');
  const [courseSearchTerm, setcourseSearchTerm] = useState('');

  //boolean states
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [filterDraft, setFilterDraft] = useState(false);
  const [filterPublished, setFilterPublished] = useState(false);
  const [end, setEnd] = useState(false);


  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getAllCourses();
    }


    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {

    getAllCourses();

  }, [modalCtx, drawerCtx]);

  /**
   * this is for the status related filtering 
   */
  useEffect(() => {

    if (filterDraft && filterPublished) {
      setStatus('both')
    } else if (filterDraft && !filterPublished) {
      setStatus('draft')
    } else if (!filterDraft && filterPublished) {
      setStatus('published')
    } else if (!filterDraft && !filterPublished) {
      setStatus('both')
    }
    getAllCourses()

  }, [filterDraft, filterPublished]);

  useEffect(() => {

    if (courseSearchTerm == '') {
      getAllCourses()
    } else {
      handleSearchCourses()
    }

  }, [courseSearchTerm]);

  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  const handleDrawer = (drawer, data) => {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, data);
  }


  const getAllCourses = async () => {
    if (!authCtx.isLoggedIn) return;
    try {

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/courses/byPage`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
      const data = await res.json()

      if (data.status === 'success') {
        console.log("this is data", data.data);

        setCourses(data.data)

        /**
         * we are setting the blogPage to 2 cuz we are already getting the first 30 blogs from "this" call, so when the getAllBlogsPagination() gets called, we will get the next 30 blogs and so on
         */
        setCoursePage(2)
        setEnd(false)
      } else {
        if (data?.code == 'auth/argument-error' && authCtx.isLoggedIn && authCtx.token ) {
          navigate(`/login?destination=courses`)
          authCtx.logout()
        }
      }

    } catch (e) {
      console.log('error while getting the topics ', e)
    }
  }


  const openDeleteCourseModal = (id) => {
    modalCtx.setDetails('confirm-delete-course', { id });
    modalCtx.openModal();
  }

  const openUploadImageModal = (id) => {
    modalCtx.setDetails('upload-image', { aspectRatio: undefined, origin: 'course', id });
    modalCtx.openModal();
  }

  const updateStatus = async (courseId, status) => {

    setUpdatingStatus(true)
    setUpdatingStatusId(courseId)

    try {

      fetch(process.env.REACT_APP_API_URI + '/v1/admin/courses/' + courseId + '/updateStatus', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status == 'success') {
            getAllCourses()
          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  const getStatusClass = (id) => {
    let course = courses.find(obj => obj.id == id);
    if (course && course.status == 'published') {
      return 'author-tag published';
    } else {
      return 'author-tag';
    }
  }

  const postStatusClass = (id) => {
    let course = courses.find(obj => obj.id == id);
    if (course && course.status == 'published') {
      return 'post-status published';
    } else {
      return 'post-status draft';
    }
  }

  function removeTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();

    return str.replace(/<[^>]+>/ig, '').slice(0, 70);
  }

  function getAddButtonLocation(length) {
    if (length == 1) {
      return "episode-thumb episode-thumb-2"
    } else if (length == 2) {
      return "episode-thumb episode-thumb-3"
    } else if (length == 3) {
      return "episode-thumb episode-thumb-4"
    } else {
      return "episode-thumb episode-thumb-add-btn"
    }
  }

  const openPublishModal = (course) => {
    modalCtx.setDetails('publish-course', { course });
    modalCtx.openModal();
  }



  /**
   * this is the search function to get the blogs related search query
   */
  const handleSearchCourses = async () => {
    try {

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/courses/search?search=${courseSearchTerm}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
      const courseData = await res.json()

      if (courseData.status === 'success') {

        setCourses(courseData.data)

      }

    } catch (e) {
      console.log('error while getting the topics ', e)
    }
  }

  const handlePublishedStatus = (event) => {

    if (event.target.checked) {
      setFilterPublished(true);
    } else {
      setFilterPublished(false);
    }
  };

  const handleDraftStatus = (event) => {
    if (event.target.checked) {
      setFilterDraft(true);
    } else {
      setFilterDraft(false);
    }
  };

  const handleSearchTag = (e) => {
    setcourseSearchTerm(e.target.value)
  }

  console.log(courses)

  return (
    <>
      <div className='container'>

        <header>
          <h1>Courses</h1>
          <div className="create-devotional" style={{ alignItems: 'center' }}>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => handleDraftStatus(e)}
                    name="draft" />
                }
                label="Draft Only"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => handlePublishedStatus(e)}
                    name="published" />
                }
                label="Publised Only"
              />
              <TextField style={{ padding: '5px 10px !important' }} type='text' placeholder='Search Courses..' value={courseSearchTerm} onChange={handleSearchTag} />

            </div>
            <Button className='header-cta' onClick={() => toggleDrawerHandler('create-course', null)}>+ Create New</Button>
          </div>
        </header>


        <div className='flex-table'>

          {courses.length < 1 ? null : courses?.filter(item => status !== 'both' ? item.status === status : item).map((course, index) => (

            <Card className='blog-card course-card' key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <div className={postStatusClass(course.id)}></div>

              {
                course.image ?
                  <div className='image-container'>
                    <div className='devo-img-container' >
                      <FormControlLabel className="switch"
                        control={<IOSSwitch sx={{ m: 1 }} />}
                        label="Featured"

                      />
                      <div className='devo-img-container' onClick={() => openUploadImageModal(course.id)}>
                        <GetImage imageRef={course.image} alt={course.title} />
                      </div>

                    </div>
                  </div>

                  :
                  <div className='placeholder-img' onClick={() => openUploadImageModal(course.id)}>
                    <img src={placeholder} alt="placeholder" />
                    <span className='placeholder-text' >No image. Click to Add a image</span>
                  </div>
              }


              <div align="left" className='devo-title'>

                <p className='devo-title-text' >{course.title}</p>
                {/* <p className='devo-title-date' ><span>Post Date -</span>{course.published_date ? moment(course.published_date).format("MMM DD, YYYY") : ' Publish date not provided'}</p> */}
                {/* <p className='devo-title-text' >{course.time}</p> */}

                <div className='tags-row'>
                  <div className={typeof course.author !== 'undefined' && course.author.length > 0 ? 'author-tag' : 'topic-tag'} onClick={() => handleModal('edit-course-author', course)}>
                    <AddRounded className='add-author action-icon' />
                    {typeof course.author !== 'undefined' && course.author.length > 0 ? course.author[0]?.full_name : 'Author'}
                    {typeof course.author !== 'undefined' && course.author.length > 0 ? <div className='number-icon'>{course.author.length}</div> : null}
                  </div>
                  <div className='topic-tag' onClick={() => handleModal('edit-course-topics', course)} >
                    <AddRounded className='add-author action-icon' />
                    Topic
                    {typeof course.topics !== 'undefined' && course.topics.length > 0 ? <div className='number-icon'>{course.topics.length}</div> : null}
                  </div>
                  <div className='keyword-tag' onClick={() => handleModal('edit-course-tags', course)} >
                    <AddRounded className='add-author action-icon' />
                    Tag
                    {typeof course.tags !== 'undefined' && course.tags.length > 1 ? <div className='number-icon'>{course.tags.length}</div> : null}
                  </div>
                </div>

                {/* <div className='engaments-row'>
        <p className='devo-title-date' ><span>Likes - </span>{course.time}0 Likes</p>
        <p className='devo-title-comment' onClick={() => handleModal('blog-comment-approve', { id: course.id })} ><span>Comments - </span> <span className='comment-count'> {addPostListener(course.id)} {addPostListener(course.id) == 1 ? 'Comment' : 'Comments'}</span> </p>
      </div> */}

                <div className='small-title' >Episodes</div>
                <div className="episodes-row">
                  {course.episodes.length > 0 ? <>
                    <div className="episode-list">
                      <div className="episode-template">

                        <GetCourseEpisodeThumbs id={course.id} />
                        <div className={getAddButtonLocation(course.episodes.length)} onClick={() => handleDrawer('create-course-episode', null)}>+</div>
                      </div>
                      <div className="episode-count">{course.episodes.length} Episodes</div>
                    </div>
                    <div className="manage-episode-btn">
                      <NavLink to={`/courses/${course.id}/episodes`}>
                        <Button className='course-btn' >Manage</Button>
                      </NavLink>
                    </div>
                  </> : <>
                    <div className="episode-list">
                      <div className="episode-template">
                        <div className="episode-thumb episode-thumb-1" onClick={() => handleDrawer('create-course-episode', null)}>+</div>
                      </div>
                    </div>
                    <div className="manage-episode-btn">
                      <NavLink to={`/courses/${course.id}/episodes`}>
                        <Button className='course-btn' >Add Episode</Button>
                      </NavLink>
                    </div></>}

                </div>

                <div className='small-title' >Students Enrolled</div>

                <div className="people-enrolled-row">
                  {
                    course.students.length > 0
                      ? <>
                        <div className="people-list">
                          <div className="people-template">
                            <div className="people-thumb people-thumb-1">1</div>
                            <div className="people-thumb people-thumb-2">2</div>
                            <div className="people-thumb people-thumb-3">3</div>
                            <div className="people-thumb people-thumb-4">4</div>
                          </div>
                          <div className="people-count">{course.students.length} Students</div>
                        </div>
                        <div className="manage-people-btn">
                        </div>
                        <Button className='course-btn' >View</Button>
                        <NavLink to={`/course/supporting-files/${course.id}`}>
                          <Button className='supporting-files-btn' >Supporting Files</Button>
                        </NavLink>
                      </>
                      : <>
                        <div className="people-list">
                          <p>No Students</p>
                        </div>
                        <div className="manage-people-btn">
                        </div>
                        <Button className='course-btn' >Share</Button>
                        <NavLink to={`/course/supporting-files/${course.id}`}>
                          <Button className='supporting-files-btn' >Supporting Files</Button>
                        </NavLink>
                      </>
                  }

                </div>

                <div className='editable-row'>
                  <div className='status-row'>
                    <span>Status -</span>
                    <div className="author-tags"
                      onClick={() => openPublishModal(course)}

                    >
                      {
                        (updatingStatus && updatingStatusId == course.id) ?
                          <CircularProgress size={20} />
                          :
                          <span className={getStatusClass(course.id)}>{typeof course.status !== 'undefined' ? course.status.toUpperCase() : null}</span>
                      }
                    </div>
                  </div>

                  <div align="right" className="action-buttons-t-cell">

                    <img className='back-button action-icon' onClick={() => toggleDrawerHandler('edit-course', course)} src={editButton} alt='' />
                    <img className='back-button action-icon' onClick={() => openDeleteCourseModal(course.id)} src={deleteButton} alt='' />
                  </div>
                </div>
              </div>

            </Card>
          ))}




        </div>
        {/* {
          !loadingCourses && courseSearchTerm == ''
            ? <div className="load-more-btn-container">
              {end ? null : <Button className='load-more-btn' onClick={() => handleShowMoreCourses()}>Load More</Button>}
            </div>
            : null
        } */}

      </div>
    </>
  )
}

export default Courses;
