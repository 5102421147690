import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AssessmentIcon from '@material-ui/icons/Assessment'; 
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { List } from '@material-ui/icons';

import UsersDrawer from './DetailsDrawer';
import DrawerContext from '../store/DrawerContext.js';

import '../styles/App.scss';

const useStyles = makeStyles({
	table: {
		minWidth: 650,
		color: "#747d8c"
	},
});


function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

function showRegDate(date) {
	return date.substring(0, 10);
}

function showStatus(status) {
	if (status == 1) {
		return "active";
	}
	return "inactive";
}

const rows = [];

function UsersTable(props) {

	const drawerCtx = useContext(DrawerContext);

	const classes = useStyles();
	const [state, setState] 								= React.useState(false);

	const [isLoading, setIsLoading] 				= useState(true);
	const [assessments, setAssessments] 		= useState([]);
	
	
	function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};

	useEffect(async () => {

		setIsLoading(true);
		fetch(process.env.REACT_APP_API_URI + '/v1/assessments', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		})
		.then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			setIsLoading(false);
			setAssessments(data.assessments);
		});

	}, []);

	if (isLoading) {
		return <section><CircularProgress /></section>
	}

	return (
		<>
			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="simple table" data-aos="fade-up">
					<TableHead>
						<TableRow>
							<TableCell>Assessment</TableCell>
							<TableCell>Automation</TableCell>
							<TableCell>Leaders/Members</TableCell>
							<TableCell align="right">&nbsp;</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{assessments.map((data) => (
							<TableRow key={ data.id }>
								<TableCell width="58%">{ data.title }</TableCell>								
								<TableCell align="center"><Chip label={ data.has_automation } style={ data.has_automation == "yes" ? {backgroundColor: '#78e08f'} : {backgroundColor: '#dfe4ea'} }/></TableCell>
								<TableCell align="center">0/0</TableCell>
								<TableCell align="right" width="15%">
									<AssessmentIcon className="table-tool" onClick={() => {toggleDrawerHandler('user-details', data)}} />
									<MoreVertIcon className="table-tool" onClick={() => {toggleDrawerHandler('user-details', data)}} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<UsersDrawer openTheDrawer={state}/>
		</>
	);
}

export default UsersTable;