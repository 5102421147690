import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ModalContext from '../../../store/ModalContext';
import DrawerContext from '../../../store/DrawerContext';
import AuthContext from '../../../store/AuthContext';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import { CloseRounded } from '@mui/icons-material';
import DropzoneFile from './DropzoneFile';
import { Delete, Edit, FileUploadRounded } from '@mui/icons-material';
import { CircularProgress, LinearProgress, TextField } from '@mui/material';
import { uploadBytesResumable, ref as sRef, getDownloadURL } from "../../FirebaseConfig";
import storage from "../../FirebaseConfig";

import done from "../../../assets/img/modal/done.svg"
import moment from 'moment/moment';

const UploadFilesModal = (props) => {

  const modalDetails = props.modalDetails;

  // const db = database;
  const ref = useRef()

  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);


  const [file, setFile] = useState();
  const [percent, setPercent] = useState(0);
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');

  const [isUploading, setIsUploading] = useState(false);


  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setFile(file);
      };

      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const uploadFile = () => {

    setIsUploading(true)

    const storageRef = sRef(storage, `/supportingFiles/${file?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setPercent(percent);

      },
      (err) => console.log(err),
      () => {
        // download url
        // setLink(uploadTask.snapshot.ref)
      }
    )
    uploadTask.then(() => {
      // modalDetails.origin == 'episode' ? saveEpisodeFile() : saveCourseFile()
      console.log('finished')
      saveCourseFile(uploadTask.snapshot.ref.fullPath)
    })

  }


  const saveCourseFile = (link) => {

    const payload = {
      "entityType": modalDetails.origin,
      "courseId": modalDetails.courseId,
      "episodeId": modalDetails.episodeId,
      "fileName": file?.name,
      "title": title ? title : file?.name.split('.').slice(0, -1).join('.'),
      "link": link
    }

    fetch(`${process.env.REACT_APP_API_URI}/v1/admin/courses/file`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status == "success") {
        setIsUploading(false)
        modalCtx.closeModal();
      } else {
        console.error("Error while updating the time of the file...");
      }
    }).catch((err) => console.error("Error while updating the time of the file...", err));

  }

  // const saveEpisodeFile = () => {

  //   const payload = {
  //     "entityType": modalDetails.origin,
  //     "courseId": modalDetails.courseId,
  //     "episodeId": modalDetails.episodeId,
  //     "fileName": file?.name,
  //     "title": title ? title : file?.name.split('.').slice(0, -1).join('.'),
  //     "link": link
  //   }

  //   fetch(`${process.env.REACT_APP_API_URI}/v1/admin/courses/file`, {
  //     method: 'POST',
  //     mode: 'cors',
  //     headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
  //     body: JSON.stringify(payload)
  //   }).then(response => {
  //     return response.json();
  //   }).then(data => {
  //     console.log(data);
  //     if (data.status == "success") {
  //       setIsUploading(false)
  //       modalCtx.closeModal();
  //     } else {
  //       console.error("Error while updating the time of the file...");
  //     }
  //   }).catch((err) => console.error("Error while updating the time of the file...", err));

  // }

  const removeVideo = () => {
    setFile(null);
  }

  console.log(modalDetails)
  console.log(file)

  return (
    <>
      <div className='upload-video-modal'>
        <div className='head' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2> Upload Files</h2>
          <CloseRounded onClick={() => modalCtx.closeModal()}></CloseRounded>
        </div>
        <DropzoneFile onDrop={onDrop} />

        {
          file ?
            <>
              <div className='upload-card'>
                <div style={{ display: 'flex', flexDirection: 'column', width: '75%', paddingBottom: '10px' }}>
                  <span style={{ maxWidth: '100%', fontSize: '16px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {file ? file?.name : '-'}
                  </span>
                  {/* <span style={{ fontSize: '14px' }}>800KB / 10MB</span> */}
                  <LinearProgress variant="determinate" value={percent} style={{ marginTop: '8px' }} />
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{`${percent ? percent : 0}%`}</span>

                  {/* {percent == 100 ? <img src={done} /> : <CircularProgress variant="determinate" value={percent} size={32} />} */}

                  <CloseRounded style={{ color: '#C6C6C6' }} onClick={removeVideo} />
                </div>
              </div>

              {/* <div style={{ display: 'flex', width: '100%', gap: '20px', marginTop: '20px' }}>
                <Button variant='outlined' fullWidth onClick={() => modalCtx.closeModal()}>Cancel</Button>
                <Button variant='contained' fullWidth onClick={file ? saveVideo : null}>Save Video</Button>
              </div> */}

            </>
            :
            null
        }

      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "20px", marginTop: "2rem" }}>
        <span style={{ fontWeight: "bold" }}>File Title</span>
        <TextField onChange={({ target }) => setTitle(target.value)} placeholder='File Title'></TextField>
        <div style={{ display: 'flex', width: '100%', gap: '20px', marginTop: '20px' }}>
          <Button variant='outlined' style={{ borderColor: "#1359E4", color: "#1359E4" }} fullWidth onClick={() => modalCtx.closeModal()}>Cancel</Button>
          <Button variant='contained' disabled={!file} style={{ backgroundColor: "#1359E4", color: "white" }} fullWidth onClick={() => uploadFile()}>Save File</Button>
        </div>
      </div>
    </>
  )
}

export default UploadFilesModal;