import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';

import GetImage from '../Components/GetImage'

import AuthContext from '../store/AuthContext.js';
import ModalContext from '../store/ModalContext.js';
import DrawerContext from '../store/DrawerContext.js';

//assets
import placeholder from '../assets/img/placeholder.png';
import editButton from '../assets/img/blog/edit.svg';
import deleteButton from '../assets/img/blog/delete.svg';

import { Button, CircularProgress, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { AddRounded, CalendarMonth, CloseRounded, Delete, Edit, PictureAsPdf } from '@mui/icons-material';
import SearchIcon from "@mui/icons-material/Search";
import { set } from 'firebase/database';

const Devotionals = () => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const navigate = useNavigate();

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [devotionals, setDevotionals] = useState([])
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState([]);

  // updating status states 
  const [isLoading, setIsLoading] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [updatingStatusId, setUpdatingStatusId] = useState();

  //pagination states
  const [devotionalPage, setDevotionalPage] = useState(1)
  const [totalDevotionals, setTotalDevotionals] = useState(30);
  const [totalPages, setTotalPages] = useState(0);

  // console.log(totalPages)
  // console.log(devotionalPage)

  useEffect(() => {
    getAllDevotional(devotionalPage)
  }, []);

  // useEffect(() => {
  //   getAllDevotional(devotionalPage)
  // }, [drawerCtx, modalCtx]);

  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  const getAllDevotional = async (page) => {

    setIsLoading(true);

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/devotionals/pagination?page=${page}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      const data = await res.json()

      if (data.status === 'success') {
        // setFiltered(data?.data)
        SearchFilter('')
        setTotalDevotionals(data?.pagination?.totalCount)
        setTotalPages(data?.pagination?.totalPages)
        setDevotionals(prev => [...prev.concat(data?.data)])
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const SearchFilter = (text) => {
    setSearch(text);

    // if (text == '') {
    //   setFiltered([...devotionals]);
    // } else {
    //   setFiltered(
    //     [...devotionals.filter(dev => dev.title.includes(text))]
    //   )
    // }
  }


  const updateStatus = async (devId, status) => {

    setUpdatingStatus(true)
    setUpdatingStatusId(devId)

    try {

      fetch(process.env.REACT_APP_API_URI + '/v1/admin/devotionals/' + devId + '/updateStatus', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ status })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == 'success') {
            getAllDevotional(devotionalPage)

          } else {
            if (data?.code == 'auth/argument-error') {
              navigate(`/login?destination=devotionals`)
              authCtx.logout()
            }
          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  const handleSeeMore = () => {
    if (totalPages === devotionalPage) {
      getAllDevotional(1)
      setDevotionalPage(1)
      setDevotionals([])
    } else {
      getAllDevotional(devotionalPage + 1)
      setDevotionalPage((prev) => prev + 1)
    }
  }

  const getStatusClass = (id) => {
    let devotional = devotionals.find(obj => obj.id == id);
    if (devotional && devotional.status == 'published') {
      return 'author-tag published';
    } else {
      return 'author-tag';
    }
  }

  const openDeleteDevotionalModal = (id) => {
    modalCtx.setDetails('confirm-delete-devotional', { id });
    modalCtx.openModal();
  }

  const openUploadImageModal = (id) => {
    modalCtx.setDetails('upload-image', { aspectRatio: undefined, origin: 'devotional', id: id });
    modalCtx.openModal();
  }


  function removeTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();

    return str.replace(/<[^>]+>/ig, '').slice(0, 70);
  }

  console.log(devotionals)

  return (
    <div className='container'>

      <header>
        <h1>Devotionals</h1>
        <div className="create-devotional">
          {/* <Button className='create-devotional-btn' onClick={() => handleModal('createDevotional')} >+ Create New</Button> */}
          <TextField
            id="search"
            type="search"
            label="Search"
            value={search}
            onChange={(e) => { SearchFilter(e.target.value) }}
            sx={{ width: 300 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button className='header-cta' onClick={() => toggleDrawerHandler('create-devotional', null)}>+ Create New</Button>
        </div>
      </header>

      <div className="data-table">
        <TableContainer className='devotional-table' component={Paper}>
          {
            devotionals?.length == 0
              ? <section style={{ width: '100%', height: '200px', position: "sticky", top: "0", right: "0", left: "0", background: "#FFF", zIndex: "100", display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress style={{ color: "#1359e4" }} /></section>
              : null
          }
          <Table style={devotionals?.length == 0 ? { display: 'none' } : null} sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ width: '100%', position: "sticky", top: "0", background: "#FFF", zIndex: "100" }}>
              <TableRow>
                <TableCell align="center">Image</TableCell>
                <TableCell align="left">Title</TableCell>
                {/* <TableCell align="left">Author</TableCell> */}
                {/* <TableCell align="left">Description</TableCell> */}
                <TableCell align="left">Tags</TableCell>
                <TableCell align="left">Topic</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='table-body' >
              {
                devotionals?.filter((data) => data.title.includes(search))?.map((devotional, index) => (
                  <>
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className="table-row">

                      <TableCell component="th" scope="row" className='devo-img TableCell'>

                        {/* <span className='devotional-img'>{data.image}</span> */}
                        {
                          devotional.image ? <div className='devo-img-container' onClick={() => openUploadImageModal(devotional.id)}>
                            <GetImage imageRef={devotional.image} alt={devotional.title} />
                          </div> : <div className='placeholder-img' onClick={() => openUploadImageModal(devotional.id)}>
                            <img src={placeholder} alt="placeholder" />
                            <span className='placeholder-text' >No image. Click to Add a image</span>
                          </div>
                        }
                      </TableCell>
                      <TableCell align="left" className='devo-title TableCell'>
                        <p className='devo-title-text' >{devotional.title}</p>
                        <p className='devo-caption'>{devotional?.metaDescription?.slice(0, 70)}...</p>

                        <div className='author-tags'>
                          <div className={devotional.author.length > 0 ? 'author-tag' : 'topic-tag'} onClick={() => handleModal('edit-author', devotional)} >
                            <AddRounded className='add-author action-icon' />
                            {devotional.author.length > 0 ? devotional.author[0].full_name : 'Author'}
                            {devotional.author.length > 1 ? <div className='number-icon'>{devotional.author.length}</div> : null}
                          </div>
                        </div>
                      </TableCell>
                      {/* <TableCell align="left" className='devo-author'>{devotional.author}</TableCell> */}
                      {/* <TableCell align="left" className='devo-description' >{removeTags(devotional.description)}</TableCell> */}
                      <TableCell align="left" className='devo-tags TableCell' >
                        <div className="author-tags">
                          <div className='topic-tag' onClick={() => handleModal('edit-tags', devotional)} >
                            <AddRounded className='add-author action-icon' />
                            Tag
                            {devotional.tags.length > 1 ? <div className='number-icon'>{devotional.tags.length}</div> : null}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left" className='devo-topics TableCell' >
                        <div className="author-tags">
                          <div className='topic-tag' onClick={() => handleModal('edit-topics', devotional)} >
                            <AddRounded className='add-author action-icon' /> Topics {devotional.topics.length > 1 ? <div className='number-icon'>{devotional.topics.length}</div> : null}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left" className='devo-status TableCell' >
                        <div className="author-tags" onClick={() => updateStatus(devotional.id, devotional.status)}>
                          {
                            (updatingStatus && updatingStatusId == devotional.id) ?
                              <CircularProgress size={20} /> : <span className={getStatusClass(devotional.id)}>{devotional.status}</span>
                          }
                        </div>
                      </TableCell>
                      <TableCell align="center" className="action-buttons-cell TableCell">
                        <div className='edit-icons'>
                          <img className='devo-delete-btn' onClick={() => toggleDrawerHandler('edit-devotional', devotional)} src={editButton} alt='' />
                          <CalendarMonth className="devo-edit-btn action-icon" onClick={() => navigate("/devotionals/" + devotional.id + "/days", { replace: true })} />
                          <img className='devo-edit-btn' onClick={() => openDeleteDevotionalModal(devotional.id)} src={deleteButton} alt='' />
                        </div>
                      </TableCell>

                    </TableRow>
                  </>

                ))
              }
            </TableBody>
          </Table>
          {
            devotionals?.length == 0
              ? null
              : <div className="see-more-btn" style={{ width: '100%', height: '50px', position: "sticky", bottom: "0", right: "50%", left: "50%", background: "#FFF", zIndex: "100", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button style={{ background: '#1359E4', color: '#FFF', }} onClick={() => handleSeeMore()} >{totalPages <= devotionalPage ? 'See Less' : 'See More'}</Button>
              </div>
          }

        </TableContainer>
      </div>
    </div>
  )
}

export default Devotionals;
