import { useContext, useState, useCallback, createRef, useEffect } from 'react';

//context
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext';
import DrawerContext from '../../../store/DrawerContext';

//firebase
import { uploadBytes, ref as sRef, database } from "../../FirebaseConfig";
import storage from "../../FirebaseConfig";

//react crop
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Base64 } from 'js-base64';

//mui
import { CircularProgress } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';

//dropzone
import Dropzone from './Dropzone';

//image compression
import imageCompression from 'browser-image-compression';

import { getDownloadURL } from 'firebase/storage';

const UploadFixedImageModal = () => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);

  const cropperRef = createRef();

  const [backupImage, setBackupImage] = useState(null);
  const [image, setImage] = useState();
  const [finalImage, setFinalImage] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const [advertisementData, setAdvertisementData] = useState({});


  const fetchAdvertisementData = async () => {
    try {
      const response = await fetch('https://api.kingdomempowered.com/v1/advertisement/slot', {
        headers: {
          Authorization: `Bearer ${authCtx.token}`
        }
      });
      const data = await response.json();
      if (data.status === 'success' && data.advertisementSlots) {
        setAdvertisementData(data.advertisementSlots);
      }
    } catch (error) {
      console.error('Error fetching advertisement data:', error);
    }
  };

  useEffect(() => {
    fetchAdvertisementData();
  }, []);

  // get dropped images
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
        setBackupImage(e.target.result);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);



  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setFinalImage(baseToFile(cropperRef.current?.cropper.getCroppedCanvas().toDataURL()))
    }
  };

  const resetCropImage = () => {
    setImage(backupImage)
    setCropData(null)
    setFinalImage(undefined)
  }

  // this returns a file object from a base file
  const baseToFile = (base) => {
    if (base) {
      let arr = base?.split(",")
      let mime = arr[0].match(/:(.*?);/)[1]
      let data = arr[1]

      let dataStr = Base64.atob(data)
      let n = dataStr.length
      let dataArr = new Uint8Array(dataStr.length)

      while (n--) {
        dataArr[n] = dataStr.charCodeAt(n)
      }

      let file = new File([dataArr], `img_cropper_${Date.now()}.png`, { type: mime })
      // console.log(file)
      return file
    } else {
      return null
    }
  }
  // decides where to send the compressed image based on the origin of the context
  const handleUploadLocationCompressed = (file) => {
    switch (modalCtx.details.origin) {
      case 'devotional':
        uploadDevotionalImgCompressed(file);
        break;

      case 'blog':
        uploadBlogImgCompressed(file);
        break;

      case 'course':
        uploadCourseImgCompressed(file);
        break;

      default:
        return null
    }
  }

  // decides where to send the image based on the origin of the context
  const handleUploadLocation = () => {
    switch (modalCtx.details.origin) {
      case 'devotional':
        uploadDevotionalImg();
        break;

      case 'blog':
        uploadBlogImg();
        break;

      case 'book':
        uploadBookImg();
        break;

      case 'course':
        uploadCourseImg();
        break;

      case 'episode':
        uploadEpisodeImg();
        break;

      case 'advertisingHome':
        uploadAdvertisingImage(1, 29);
        break;

      case 'advertisingDevotional':
        uploadAdvertisingImage(3, 31);
        break;

      case 'advertisingCourses':
        uploadAdvertisingImage(2, 30);
        break;

      default:
        uploadBookImg();
        break;
    }
  }


  // upload image to firebase and then to PUT in devotional object
  const uploadDevotionalImg = () => {

    let img = baseToFile(cropData ? cropData : image);

    if (img) {

      setIsUploading(true)
      let coverRef = `/devotionals/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {
        console.log()

        getDownloadURL(storageRef)
        .then((imageUrl) => {

          fetch(process.env.REACT_APP_API_URI + '/v1/admin/devotionals/' + modalCtx.details.id + '/uploadImage', {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
            body: JSON.stringify({
              refstring: coverRef,
              downloadedImage: imageUrl
            })
          }).then(response => {
            return response.json();
          }).then(data => {
            console.log(data);
            if (data.status == "success") {
              // handleCompressedImageUpload(img)
              setIsUploading(false)
              modalCtx.closeModal();
            } else {
              console.error("Error while uploading image...");
            }
          }).catch((err) => console.error("Error while uploading image...", err));
        
        })
        .catch((error) => {
          console.error('Error getting image URL:', error);
        });

      }, (error) => console.log(error)
      )
    } else {
      console.log("no img");
    }
  }

  const uploadBookImg = () => {

    let img = baseToFile(cropData ? cropData : image);

    if (img) {

      console.log(modalCtx.details.id, "this is modalCtx.details.id");
      setIsUploading(true)
      let coverRef = `/books/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {

        getDownloadURL(storageRef)
        .then((imageUrl) => {

          fetch(process.env.REACT_APP_API_URI + '/v1/admin/books/' + modalCtx.details.id + '/uploadBgImage', {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
            body: JSON.stringify({
              refstring: coverRef,
              downloadedImage: imageUrl
            })
          }).then(response => {
            return response.json();
          }).then(data => {
            console.log(data);
            if (data.status == "success") {
              setIsUploading(false)
              modalCtx.closeModal();
            } else {
              console.error("Error while uploading image...");
            }
          }).catch((err) => console.error("Error while uploading image...", err));
        
        })
        .catch((error) => {
          console.error('Error getting image URL:', error);
        });

      }, (error) => console.log(error)
      )
    } else {
      console.log("no img");
    }
  }

  // upload image to firebase and then to PUT in devotional object
  const uploadBlogImg = () => {

    let img = baseToFile(cropData ? cropData : image);

    if (img) {

      setIsUploading(true)
      let coverRef = `/blogs/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {

        getDownloadURL(storageRef).then((imageUrl) => {

          console.log(imageUrl);

          fetch(process.env.REACT_APP_API_URI + '/v1/admin/blogs/' + modalCtx.details.id + '/uploadImage', {
            method: 'PUT',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
            body: JSON.stringify({
              refstring: coverRef,
              downloadedImage: imageUrl
            })
          }).then(response => {
            return response.json();
          }).then(data => {
            console.log(data);
            if (data.status == "success") {
              setIsUploading(false);
              if (modalCtx.details.newBlog == true) {
                toggleDrawerHandler('edit-blog', modalCtx?.details?.blog)
              }
              modalCtx.closeModal();
            } else {
              console.error("Error while uploading image...");
            }
          }).catch((err) => console.error("Error while uploading image...", err));

        })
        .catch((error) => {
          console.error('Error getting image URL:', error);
        });

      }, (error) => console.log(error)
      )
    } else {
      console.log("no img");
    }
  }

  // upload image to firebase and then to PUT in devotional object
  const uploadEpisodeImg = () => {

    let img = baseToFile(cropData ? cropData : image);

    if (img) {

      setIsUploading(true)
      let coverRef = `/episodes/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {

        getDownloadURL(storageRef)
        .then((imageUrl) => {

          fetch(process.env.REACT_APP_API_URI + '/v1/admin/courses/' + modalCtx.details.id.courseId + '/episodes/' + modalCtx.details.id.id + '/updateImage', {
            method: 'PUT',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
            body: JSON.stringify({
              refstring: coverRef,
              downloadedImage: imageUrl
            })
          }).then(response => {
            return response.json();
          }).then(data => {
            console.log(data);
            if (data.status == "success") {
              setIsUploading(false)
              if (modalCtx.details.newBlog == true) {
                toggleDrawerHandler('edit-blog', modalCtx?.details?.blog)
              }
              modalCtx.closeModal();
            } else {
              console.error("Error while uploading image...");
            }
          }).catch((err) => console.error("Error while uploading image...", err));
  
        
        })
        .catch((error) => {
          console.error('Error getting image URL:', error);
        });

      }, (error) => console.log(error)
      )
    } else {
      console.log("no img");
    }
  }

  // upload image to firebase and then to PUT in devotional object
  const uploadCourseImg = () => {

    let img = baseToFile(cropData ? cropData : image);

    if (img) {

      setIsUploading(true)
      let coverRef = `/courses/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef);
      console.log(storageRef);

      uploadBytes(storageRef, img).then((snapshot) => {

        getDownloadURL(storageRef).then((imageUrl) => {        

          fetch(process.env.REACT_APP_API_URI + '/v1/admin/courses/' + modalCtx.details.id + '/uploadImage', {
            method: 'PUT',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
            body: JSON.stringify({
              refstring: coverRef,
              downloadedImage: imageUrl
            })
          }).then(response => {
            return response.json();
          }).then(data => {
            console.log(data);
            if (data.status == "success") {
              setIsUploading(false)
              modalCtx.closeModal();
            } else {
              console.error("Error while uploading image...");
            }
          }).catch((err) => console.error("Error while uploading image...", err));
        })
        .catch((error) => {
          console.error('Error getting image URL:', error);
        });

      }, (error) => console.log(error)
      )

    } else {
      console.log("no img");
    }
  }

  const uploadAdvertisingImage = (pageId, id) => {
    let img = baseToFile(cropData ? cropData : image);

    const homePageSlot1Link = advertisementData?.homePage?.[0]?.slot1Link;
    const homePageSlot2Link = advertisementData?.homePage?.[0]?.slot2Link;
    const homePageSlot3Link = advertisementData?.homePage?.[0]?.slot3Link;

    const coursesSlot1Link = advertisementData?.courses?.[0]?.slot1Link;
    const coursesSlot2Link = advertisementData?.courses?.[0]?.slot2Link;

    const devotionalsSlot1Link = advertisementData?.devotionals?.[0]?.slot1Link;
    const devotionalsSlot2Link = advertisementData?.devotionals?.[0]?.slot2Link;

    if (img) {
      setIsUploading(true);
      let coverRef = `/advertising/${img.name}`;
      const storageRef = sRef(storage, coverRef);

      uploadBytes(storageRef, img)
        .then((snapshot) => {
          getDownloadURL(storageRef)
            .then((imageUrl) => {
              let slot1Link, slot2Link, slot3Link;

              if (pageId === 1) {
                slot1Link = modalCtx?.details?.id === 1 ? imageUrl : homePageSlot1Link;
                slot2Link = modalCtx?.details?.id === 2 ? imageUrl : homePageSlot2Link;
                slot3Link = modalCtx?.details?.id === 3 ? imageUrl : homePageSlot3Link;
              } else if (pageId === 2) {
                slot1Link = modalCtx?.details?.id === 1 ? imageUrl : coursesSlot1Link;
                slot2Link = modalCtx?.details?.id === 2 ? imageUrl : coursesSlot2Link;
              } else if (pageId === 3) {
                slot1Link = modalCtx?.details?.id === 1 ? imageUrl : devotionalsSlot1Link;
                slot2Link = modalCtx?.details?.id === 2 ? imageUrl : devotionalsSlot2Link;
              }

              const payload = {
                pageId: pageId,
                slot1Link: slot1Link,
                slot2Link: slot2Link,
                slot3Link: slot3Link,
              };

              const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authCtx.token,
              };

              fetch(`https://api.kingdomempowered.com/v1/admin/advertisement/slot/${id}`, {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify(payload),
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                  return response.json();
                })
                .then((data) => {
                  console.log(data);
                  setIsUploading(false);
                  modalCtx.closeModal();
                  modalCtx.setFixedsize(false);
                })
                .catch((error) => {
                  console.error('Error:', error);
                });
            })
            .catch((error) => {
              console.error('Error getting image URL:', error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('no img');
    }
  };


  //thumbnail functions 
  const handleCompressedImageUpload = async (file) => {
    console.log("we are here", file)

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 500,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(file, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

      if (compressedFile) handleUploadLocationCompressed(compressedFile)

    } catch (error) {
      console.log(error);
    }

  }


  // upload image to firebase and then to PUT in devotional object
  const uploadDevotionalImgCompressed = (img) => {


    if (img) {

      let coverRef = `/devotionals/thumbnail/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {

        getDownloadURL(storageRef)
        .then((imageUrl) => {

          fetch(process.env.REACT_APP_API_URI + '/v1/admin/devotionals/' + modalCtx.details.id + '/updateThumbnail', {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
            body: JSON.stringify({
              thumbnail: imageUrl
            })
          }).then(response => {
            return response.json();
          }).then(data => {
            console.log(data);
            if (data.status == "success") {
              setIsUploading(false)
              modalCtx.closeModal();
            } else {
              console.error("Error while uploading image...");
            }
          }).catch((err) => console.error("Error while uploading image...", err));

        })
        .catch((error) => {
          console.error('Error getting image URL:', error);
        });

      }, (error) => console.log(error)
      )
    } else {
      console.log("no img");
    }
  }



  // upload image to firebase and then to PUT in devotional object
  const uploadBlogImgCompressed = (img) => {


    if (img) {

      let coverRef = `/blogs/thumbnail/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {

        getDownloadURL(storageRef)
        .then((imageUrl) => {

          fetch(process.env.REACT_APP_API_URI + '/v1/admin/blogs/' + modalCtx.details.id + '/updateThumbnail', {
            method: 'PUT',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
            body: JSON.stringify({
              thumbnail: imageUrl
            })
          }).then(response => {
            return response.json();
          }).then(data => {
            console.log(data);
            if (data.status == "success") {
              setIsUploading(false)
              if (modalCtx.details.newBlog == true) {
                toggleDrawerHandler('edit-blog', modalCtx?.details?.blog)
              }
              modalCtx.closeModal();
            } else {
              console.error("Error while uploading image...");
            }
          }).catch((err) => console.error("Error while uploading image...", err));

        })
        .catch((error) => {
          console.error('Error getting image URL:', error);
        });

      }, (error) => console.log(error)
      )
    } else {
      console.log("no img");
    }
  }


  // upload image to firebase and then to PUT in devotional object
  const uploadCourseImgCompressed = (img) => {


    if (img) {

      let coverRef = `/courses/thumbnail/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {

        getDownloadURL(storageRef)
        .then((imageUrl) => {

          fetch(process.env.REACT_APP_API_URI + '/v1/admin/courses/' + modalCtx.details.id + '/updateThumbnail', {
            method: 'PUT',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
            body: JSON.stringify({
              thumbnail: imageUrl
            })
          }).then(response => {
            return response.json();
          }).then(data => {
            console.log(data);
            if (data.status == "success") {
              setIsUploading(false)
              modalCtx.closeModal();
            } else {
              console.error("Error while uploading image...");
            }
          }).catch((err) => console.error("Error while uploading image...", err));

        })
        .catch((error) => {
          console.error('Error getting image URL:', error);
        });

      }, (error) => console.log(error)
      )
    } else {
      console.log("no img");
    }
  }

  //open drawer
  function toggleDrawerHandler(drawer, data) {
    drawerCtx.openDrawer();
    drawerCtx.setDrawer(drawer);
  };


  // close 
  const onClose = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  };

  return (
    <div className='upload-image-modal-container'>
      <div className="title">
        <h2>Upload Image</h2>
        <CloseRounded onClick={() => onClose()} />
      </div>
      <div className='crop'>
        {
          image
            ?
            finalImage == undefined
              ? <Cropper
                ref={cropperRef}
                style={{ height: "100%", width: "100%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                guides={true}
                // ? if no aspect ration is provided, the cropper will be in free crop mode
                aspectRatio={modalCtx.details.aspectRatio ? modalCtx.details.aspectRatio : undefined}
              />
              : <img src={cropData} style={{ height: "100%", width: "100%", objectFit: 'contain' }} alt="preview" />
            : <Dropzone onDrop={onDrop} accept={"image/*"} />
        }
      </div>
      <div className='crop-btns'>


        {
          // ? the crop button will only show once the image is present in the setImage state
          !finalImage && image
            ? <button className='btn-primary' onClick={() => getCropData()} disabled={!image}>Crop</button>
            : null
        }

        {
          // ? this reset button will reset the image and cropper 
          finalImage
            ? <button className='btn-primary' onClick={() => resetCropImage()} disabled={!image}>Reset</button>
            : null
        }
        {
          finalImage
            ? <button style={{ marginLeft: 'auto', marginRight: '10px' }} className='btn-primary' onClick={() => handleUploadLocation()} disabled={!image}>
              {
                isUploading
                  ? <CircularProgress size={18} style={{ color: '#FFF' }} />
                  : 'Upload Cropped'
              }
            </button>
            : null
        }
        {
          image
            ? <button className='btn-primary' onClick={() => handleUploadLocation()} disabled={!image}>
              {
                isUploading
                  ? <CircularProgress size={18} style={{ color: '#FFF' }} />
                  : 'Upload Original'
              }
            </button>
            : null
        }

        <div className="tips">
          <span>! Use mouse scroll wheel to zoom in and out of the image</span>
        </div>
      </div>
    </div>
  );

}

export default UploadFixedImageModal;
