import { useContext } from 'react';
import { Button } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext';

const DeleteDayModal = ({ modalDetails }) => {

  // console.log("delete modal props", modalDetails);
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }

  const deleteDevotional = (id, day) => {
		if(authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + '/admin/devotionals/day/' + id + '/delete', {
        method: 'DELETE',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
        body: JSON.stringify({ day: day })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if(data.status == "success") {
        // navigate("/devotionals", { replace: true });
        // drawerCtx.closeDrawer();
        window.location.reload();
        } else {
          console.error("Error while deleting devotional...");
          // setSnackbarMessage(data.message);
          // setOpenSnackbar(true);
        }
      }).catch((err) => {
        console.error("Error while deleting devotional...", err);
        handleCloseModal();
      });
			console.log("delete devotional id", id);
		}
	}

  return (
    <div className="modal">
      <p>Are you sure you want to delete this Day ?</p>
      <div className="footer">
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>No</Button>
        <Button className="confirm-btn" onClick={() => deleteDevotional(modalDetails.id, modalDetails.day)}>Yes</Button>
      </div>
    </div>
  )
}

export default DeleteDayModal;
