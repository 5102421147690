//react
import React, { useContext, useEffect, useState } from "react";

//mui
import { Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddRounded } from "@mui/icons-material";

//context
import AuthContext from "../store/AuthContext";
import DrawerContext from "../store/DrawerContext";
import ModalContext from "../store/ModalContext";
import AdminContext from "../store/AdminContext";

const AdministratorsTable = () => {

  //context
  const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const adminCtx = useContext(AdminContext);

  //boolean states
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = React.useState(false);
  //states
  const [loadedUsers, setUsersData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowNumber, setRowNumber] = useState(0);
  const [startRowNumber, setStartRowNumber] = useState(0);
  const [endRowNumber, setEndRowNumber] = useState(10);
  const [visibleRows, setVisibleRows] = useState([]);

  //functions

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
    setStartRowNumber(newPage * rowsPerPage);
    if (rowNumber > newPage * rowsPerPage + rowsPerPage) {
      setEndRowNumber(newPage * rowsPerPage + rowsPerPage);
    } else {
      setEndRowNumber(rowNumber);
    }
    setVisibleRows(
      loadedUsers.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage)
    );
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    handleChangePage(event, 0);
  }

  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  useEffect(() => {
    getAdminUsers()
  }, []);

  const getAdminUsers = () => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v1/admin/administrators/admins', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        setIsLoading(false);
        setUsersData(data.data);
        setVisibleRows(
          data.data?.slice(startRowNumber, endRowNumber)
        );
        setRowNumber(data.data.length);
      });
  }

  if (isLoading) {
    return <section><CircularProgress /></section>
  }

  console.log('loadedUsers', loadedUsers)


  return <>

    <TableContainer component={Paper} className="user-table-container">
      <Table sx={{ "& td": { border: 0 } }} className="users-table" aria-label="simple table" data-aos="fade-up">
        <TableHead className='table-head'>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell >Permissions</TableCell>
            <TableCell align="right">&nbsp;</TableCell>

          </TableRow>
        </TableHead>
        <TableBody className='table-body'>
          {visibleRows?.map((data) => (
            <TableRow key={data.id} className="table-row">
              <TableCell className="TableCell" >{data.name}</TableCell>
              <TableCell className="TableCell" >{data.email}</TableCell>
              <TableCell className="permission-button" ><Button variant="contained" onClick={() => handleModal('edit-permissions', data)}> <AddRounded className='add-author action-icon' />  Permissions <div className="number">{data.permissions?.length ? data.permissions?.length : 0}</div> </Button>  </TableCell>
              {/* <TableCell align="right">{showRegDate(data.registration_date)}</TableCell>
								<TableCell align="right">{showStatus(data.status)}</TableCell> */}
              <TableCell align="right"><DeleteIcon className="table-tool" onClick={() => { }} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <TablePagination
      rowsPerPageOptions={[]}
      component="div"
      count={loadedUsers?.length}
      rowsPerPage={rowsPerPage}

      page={page}
      onPageChange={handleChangePage}
    // onRowsPerPageChange={handleChangeRowsPerPage}
    // labelRowsPerPage="Rows per page"
    />


  </>

}

export default AdministratorsTable


