import React, { useContext, useEffect, useState } from 'react'

import AuthContext from '../store/AuthContext.js';
import GetImage from './GetImage.jsx';

const GetCourseEpisodeThumbs = ({ id }) => {

  const authCtx = useContext(AuthContext)

  const [courseEpisodes, setCourseEpisodes] = useState([])

  useEffect(() => {

    getCourseById(id)

  }, [id])

  const getCourseById = (courseId) => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/admin/courses/${courseId}/episodes`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        console.log('Success:', data);
        if (data.status == 'success') {
          setCourseEpisodes(data?.data)
        }
      }).catch(err => console.error(err));

  }

  const getMissingEps = (length) => {
    if(length == 1){
      return <>
      <div className={`episode-thumb episode-thumb-2`}></div>
      <div className={`episode-thumb episode-thumb-3`}></div>
      <div className={`episode-thumb episode-thumb-4`}></div>
      </>
    } else if (length == 2) {
      return <>
      <div className={`episode-thumb episode-thumb-3`}></div>
      <div className={`episode-thumb episode-thumb-4`}></div>
      </>
    } else if (length == 3) {
      return <>
      <div className={`episode-thumb episode-thumb-4`}></div>
      </>
    } else {
      return null
    }
  }

  return (
    <>
      {
        courseEpisodes != null
          ? courseEpisodes?.slice(0, 4).map((item, index) => (
            <>
              <div className={`episode-thumb episode-thumb-${index + 1}`} style={{overflow:'hidden'}}>
                <GetImage imageRef={item.image} /></div>
            </>
          ))
          : null
      }
      {/* {getMissingEps(courseEpisodes?.length)} */}
    </>
  )
}

export default GetCourseEpisodeThumbs