import { Button } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import AuthContext from '../store/AuthContext';
import GetImage from '../Components/GetImage';
import DrawerContext from '../store/DrawerContext';
import editImage from "../assets/img/blog/edit.svg"
import deleteImage from "../assets/img/blog/delete.svg"
import docxImage from "../assets/img/supportingFiles/docx.svg"
import mp4Image from "../assets/img/supportingFiles/mp4.svg"
import pdfImage from "../assets/img/supportingFiles/pdf.svg"
import zipImage from "../assets/img/supportingFiles/zip.svg"
import ModalContext from '../store/ModalContext';
import { ArrowBack } from '@mui/icons-material';



const SupportingFiles = ({ entityType }) => {

  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);


  const { id, courseId } = useParams();
  const location = useLocation()

  const [files, setFiles] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState([]);
  const [course, setCourse] = useState();
  const [courseDetails, setCourseDetails] = useState([]);
  const [episodeId, setEpisodeId] = useState();
  const [supportingFiles, setSupportingFiles] = useState([]);

  useEffect(() => {

    setEpisodeId(id)
    setCourse(courseId)
    getCourseDetails(courseId)

  }, [id, courseId])

  useEffect(() => {

    if (entityType === "episode") {
      getEpisodeById(course, episodeId);
      if (episodeId && course) {
        getEpisodeSupportingFiles(episodeId, course);
      }
    } else {
      if (course) {
        getCourseSupportingFiles(course);
      }
      getCourseById(course);
    }

  }, [course, episodeId])

  useEffect(() => {

    if (entityType === "episode") {
      if (episodeId && course) {
        getEpisodeSupportingFiles(episodeId, course);
      }
    } else {
      if (course) {
        getCourseSupportingFiles(course);
      }
    }

  }, [modalCtx])



  // function toggleDrawerHandler(drawer, data) {
  //   drawerCtx.openDrawer();
  //   drawerCtx.setDetails(drawer, data);
  // };

  const getCourseById = () => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/courses/${courseId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        // console.log('Success:', data);
        if (data.status === 'success') {
          setDetails(data?.data)
        }
      }).catch(err => console.error(err));

  }

  const getCourseDetails = (id) => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/courses/${id}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        // console.log('Success:', data);
        if (data.status === 'success') {
          console.log(data?.data)
          setCourseDetails(data?.data)
        }
      }).catch(err => console.error(err));

  }

  const getEpisodeById = (courseId, episodeId) => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/admin/courses/${courseId}/episodes/${episodeId}`, {
      method: "GET",
      headers: { "Content-Type": 'application/json', Authorization: "Bearer " + authCtx.token }
    }).then(response => {
      return response.json();
    }).then(data => {
      // console.log("Episode Data", data.data[0]);
      if (data.status === "success") {
        setDetails(data?.data[0]);
      }
    }).catch(e => {
      console.error(e);
    })

  }

  const getCourseSupportingFiles = (id) => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/courses/${id}/file`, {
      method: "GET", headers: {
        'Content-Type': 'application/json', Authorization: "Bearer" + authCtx.token
      }
    }).then(response => { return response.json() }).then(data => {
      // console.log("files", data);
      if (data.status === "success") {
        console.log(data.data)
        setSupportingFiles(data?.data);
      }
    }).catch(e => {
      console.error(e);
    })

  }

  const getEpisodeSupportingFiles = (id, courseId) => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/courses/${courseId}/file/${id}`, {
      method: "GET", headers: {
        'Content-Type': 'application/json', Authorization: "Bearer" + authCtx.token
      }
    }).then(response => { return response.json() }).then(data => {
      console.log("files", data);
      if (data.status === "success") {
        setSupportingFiles(data?.data);
      }
    }).catch(e => {
      console.error(e);
    })

  }

  const openDeleteModal = (id) => {
    modalCtx.setDetails('confirm-delete-file', { id, origin: entityType });
    modalCtx.openModal();
  }

  const openEditModal = (details, type) => {
    modalCtx.setDetails('file-name-edit', { details, type });
    modalCtx.openModal();
  }

  const openUploadFilesModal = () => {
    modalCtx.setDetails('upload-supporting-files', { origin: entityType, episodeId, courseId });
    modalCtx.openModal();
  }

  const addTrailingDots = (string, limit) => {
    if (!string) return null

    var dots = "...";
    if (string?.length > limit) {
      string = string?.substring(0, limit) + dots;
    }
    return string;
  }

  const switchType = (type, item) => {
    switch (type) {
      case "png" || 'jpg' || 'gif':
        return <GetImage imageRef={item} />

      default:
        return <span style={{ fontWeight: "bold", fontSize: "48px" }}>.{type}</span>
    }
  }

  // console.log(details)

  return (
    <div className='supporting-files-container' >
      <header className='supporting-files-header'>
        <div className='image-title'>
          <NavLink to={entityType === 'episode' ? `/courses/${courseId}/episodes` : '/courses'} >
            <ArrowBack style={{ color: '#FFF' }} className='back-arrow' />
          </NavLink>
          {details && details.image ? <GetImage imageRef={details.image} alt="Course Image" /> : <><img src="https://firebasestorage.googleapis.com/v0/b/kingdom-empowered.appspot.com/o/courses%2Fimg_cropper_1688076678517.png?alt=media&token=746af6de-0929-49f4-ab53-797872d4f3bb" className='devotional-img bg-image' /></>}
          <div className='header-title' >
            <h1 style={{ padding: "0", margin: "0" }}> {details?.title} - Supporting Files</h1>
            {entityType === "episode" ? <span className='course-name' >{courseDetails?.title}</span> : null}
          </div>
        </div>

        <div className="create-episode">
          <Button className='header-cta' variant='outlined' onClick={() => openUploadFilesModal()} style={{ color: 'white' }}>+ Add New Files</Button>
        </div>
      </header>
      <div className="supporting-files">
        {
          supportingFiles?.length > 0
            ? supportingFiles?.map((item, index) => (
              <div key={index} className="supporting-file">
                <div className="file-preview">
                  {
                    switchType(item.fileType, item.link)
                  }
                </div>
                <div className='file-title'>
                  <span>{addTrailingDots(item.title, 55)}</span>
                  <div className='edit-icons'>
                    <span className='type'>{item.fileType}</span>
                    <img src={editImage} style={{ cursor: "pointer" }} onClick={() => { openEditModal(item, entityType === "episode" ? "episode" : "course") }} alt=''></img>
                    <img src={deleteImage} style={{ cursor: "pointer" }} onClick={() => { openDeleteModal(item.id) }} alt=''></img>
                  </div>
                </div>
              </div>
            ))
            : <>
              <div className="no-files">
                No Files
              </div>
            </>
        }
      </div>
    </div>
  )
}

export default SupportingFiles