import React, { useContext, useState } from 'react';
import { Button, TextField } from '@mui/material';
import AuthContext from '../../store/AuthContext';
import ModalContext from '../../store/ModalContext';
import DrawerContext from '../../store/DrawerContext';

const EditTopicDrawer = ({ drawerDetails }) => {
  
  const authCtx		= useContext(AuthContext);
  const modalCtx	= useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  // console.log("EditTopicDrawer Props", props);
  console.log("drawerDetails", drawerDetails);

  const [createTopicDrawerObj, setCreateTopicDrawerObj] = useState({ title: drawerDetails.title || "" });

  const submitEditedTopic = (e) => {
    
    if(authCtx.isLoggedIn) {

      const { title } = createTopicDrawerObj;
      fetch(process.env.REACT_APP_API_URI + '/v1/admin/topics/' + drawerDetails.id + '/update', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
        body: JSON.stringify({ title, })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if(data.status == "success") {
          // navigate("/devotionals", { replace: true });
          drawerCtx.closeDrawer();
          window.location.reload();
        } else {
          console.error("Error while editing topic...");
          // setSnackbarMessage(data.message);
          // setOpenSnackbar(true);
        }
		  });
    }
  }
  

  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Edit Topic Drawer</h1>
        </header>

        <section>

          {/* <div className="double-column-form"> */}
          {/* <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required /> */}
          <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={createTopicDrawerObj.title} onChange={(e) => setCreateTopicDrawerObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          {/* </div> */}

          <div className="double-column-form aligned-right margin-top">

            <Button variant="contained" component="label" disabled={false} 
              onClick={(e) => submitEditedTopic(e)}>Save Changes
            </Button>
          </div>

        </section>

      </div>
    </div>
  )

}

export default EditTopicDrawer;

