import react, { useState, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";

import { Paper, Grid, TextField, CardContent, Button, CircularProgress } from '@material-ui/core';
import { Checkbox, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import '../styles/App.scss';

import AuthContext from '../store/AuthContext.js';

import Welcome from '../assets/img/login/welcome.png';
import KE from '../assets/img/KE_App-Color.png';



const Login = (props) => {

	let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
	const authCtx = useContext(AuthContext);
			
	const [email, setEmail] 			= useState('');
	const [isSubmitting, setSubmitting] = useState(false);
	const [password, setPassword] = useState('');
	const [redirect, setRedirect] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("Sorry, email or password incorrect");
	const [alertComponent, setAlertComponent] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [response, setResponse] = useState(null);


	const checkForEnter = (e) => {
		if (e.keyCode === 13) {
			submit();
		}
	}

  console.log(searchParams.toString())

	// useEffect(()=>{
	// 	if(authCtx?.error?.code === 'auth/wrong-password'){
	// 		setAlertComponent(
	// 			<Alert severity="error"> Wrong Password ! </Alert>
	// 		)
	// 	}
	// 	else if(authCtx?.error?.code === 'auth/user-not-found'){
	// 		setAlertComponent(
	// 			<Alert severity="error"> User Not Found ! </Alert>
	// 		)
	// 	}
	// 	else{
	// 		setAlertComponent(null)
	// 	}

	// 	setTimeout(() => {
	// 		setAlertComponent(null)
	// 	}, 2000);

	// },[authCtx?.error?.code])

	async function submit() {

		setIsLoading(true);

    await authCtx.login(email, password, setResponse, searchParams);

    setTimeout(() => {setIsLoading(false);}, 500);

    console.log(response);

	}

	function changeEmail(event) {
		setEmail(event.target.value);
	}

	function changePassword(event) {
		setPassword(event.target.value);
	}

	const paperStyle = { padding: 20, width: 580, margin: "0 auto", background: "#3149C3" }
		return (
			<div className="login-register">
				{alertComponent}
				<div className="login">
					<img src={KE} alt="KE" className='ke-logo' width={130} />

					<div className="form-heading">
						<h1>Hello</h1>
						<p>Sign in to your account</p>
					</div>

					<div className="form-wrapper">
						<form onKeyDown={(e) => checkForEnter(e)}>
							<Grid container spacing={4}>

								<Grid xs={12} item>
									<p className='helper-text'>Email</p>
									<TextField onChange={changeEmail} value={email} variant="outlined" fullWidth required />
								</Grid>

								<Grid xs={12} item>
									<p className='helper-text'>Password</p>
									<TextField onChange={changePassword} value={password} type="password" variant="outlined" fullWidth required />
								</Grid>

							</Grid>
							<Button disabled={isLoading} className="outlineBtn" onClick={submit}>
								{isLoading ? <CircularProgress size={22} style={{color: "#fff"}} /> : "Login"}
							</Button>
							<Snackbar open={!!response} autoHideDuration={3000} onClose={() => setResponse(null)}>
								<Alert severity="error">
									{response}
								</Alert>
							</Snackbar>
							{/* <div className="remember-forgot-wrapper">
								<div className="circle-checkbox">
									<Checkbox name="checked8" color="primary" />Remember Me
								</div>
								<div className="forgot-password">Forgot Password ?</div>
							</div> */}
							{/* {
								isSubmitting ? <Button className="outlineBtn"><CircularProgress size={20} /></Button> :
								<Button className="outlineBtn" onClick={submit}>Sign in</Button>
							} */}
							{/* <div className="no-account-wrapper">
									Don't have an account?
									<Link to="/register"> join free today</Link>
							</div> */}
							
							{/* <p className="forgot-password">Forgot your password? <a 
								onClick={(e) => {
									e.preventDefault();
									setForgotPassword(true);
								}}>Click here</a></p> */}

						</form>
					</div>
				</div>
			</div>
		)
	}

export default Login;









