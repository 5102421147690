import React, { useRef, useContext, useState, useEffect } from 'react';
import { Alert, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Switch, FormGroup, FormControlLabel, CircularProgress, SnackbarContent } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import DrawerContext from '../../../store/DrawerContext';

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import { AddRounded, ArrowBackIos, ArrowBackRounded, ArrowForwardIosRounded, ArrowForwardRounded, CloseRounded, HighlightOffRounded, KeyboardArrowDownRounded, KeyboardBackspace, PublishRounded, Remove, ReplayRounded, Upload } from '@mui/icons-material';
import GetImage from '../../GetImage';

import placeholder from '../../../assets/img/placeholder.png';
import backbutton from '../../../assets/img/drawer/back.svg';
// import alignmentbutton from '../../../assets/img/drawer/alignment.svg';
// import boldbutton from '../../../assets/img/drawer/bold.svg';
// import codebutton from '../../../assets/img/drawer/code.svg';
// import dropdownbutton from '../../../assets/img/drawer/dropdown.svg';
// import fxbutton from '../../../assets/img/drawer/fx.svg';
// import imagebutton from '../../../assets/img/drawer/image.svg';
// import italicboldbutton from '../../../assets/img/drawer/italic.svg';
// import itlaicNormalbutton from '../../../assets/img/drawer/italic1.svg';
// import linkbutton from '../../../assets/img/drawer/link.svg';
// import underorderlistbutton from '../../../assets/img/drawer/list.svg';
// import underlinebutton from '../../../assets/img/drawer/underline.svg';
// import videobutton from '../../../assets/img/drawer/video.svg';
// import orderedList from '../../../assets/img/drawer/orderedlist.svg';
import Logo from '../../../assets/img/ke-logo.png';

import ModalContext from '../../../store/ModalContext';
import { uploadBytes, ref as sRef, getDownloadURL } from "../../FirebaseConfig";
import storage from "../../FirebaseConfig";
import { Base64 } from 'js-base64';


const EditBlogDrawer = ({ drawerDetails }) => {

  //clg

  //context
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);

  console.log(drawerCtx)
  console.log(drawerDetails)

  //states
  const [open, setOpen] = useState(false);
  const [metaTagsOpen, setMetaTagsOpen] = useState(false);
  const [editorToolOpen, setEditorToolOpen] = useState(true);
  const [blogVideoToggle, setBlogVideoToggle] = useState(false);

  // updating status states 
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [updatingStatusId, setUpdatingStatusId] = useState();
  const [savingChanges, setSavingChanges] = useState(false);

  const [blog, setBlog] = useState({});
  const [createBlogObj, setCreateBlogObj] = useState({
    snippet: blog?.snippet || "",
    title: blog?.title || "",
    post: blog?.post || "",
    slug: blog?.slug || blog?.slug,
    pageTitle: blog?.pageTitle || "",
    metaDescription: blog?.metaDescription || "",
    metaKeywords: blog?.metaKeywords || "",
    isVideo: blog?.isVideo || "no",
    videoUrl: blog?.videoUrl || "",
    customUrl: blog?.customUrl || blog?.id,
  });
  const [authors, setAuthors] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [author, setAuthor] = useState('');
  const [blogUrl, setBlogUrl] = useState();
  const [slug, setSlug] = useState(blog?.id);
  const [updateBlogStatus, setUpdateBlogStatus] = useState(false);
  const [statusClass, setStatusClass] = useState('status-button');
  const [editor, setEditor] = useState('myeditor disable');

  const quillRef = useRef();


  //effect
  useEffect(() => {
    if (blog) {
      // submitEditBlog()
      updateStatusClass()
    }
  }, [modalCtx])

  useEffect(() => {
    getBlogById(drawerDetails.id)
    updateStatusClass()
  }, [])

  useEffect(() => {
    setCreateBlogObj({
      snippet: blog?.snippet || "",
      title: blog?.title || "",
      post: blog?.post || "",
      slug: blog?.slug || blog?.slug,
      pageTitle: blog?.pageTitle || "",
      metaDescription: blog?.metaDescription || "",
      metaKeywords: blog?.metaKeywords || "",
      isVideo: blog?.isVideo || "no",
      videoUrl: blog?.videoUrl || "",
      customUrl: blog?.customUrl || blog?.id,
    });
    setBlogUrl(blog.slug)
  }, [blog])




  //functions
  const getBlogById = (id) => {


    if (authCtx.isLoggedIn) {
      fetch(`${process.env.REACT_APP_API_URI}/v1/blogs/${id}`, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status == "success") {
          console.log('blog by id', data)
          setBlog(data?.data)
        } else {
          console.error("Error while getting blog?...");
        }
      }).catch((err) => console.error("Error while editing blog?...", err));
    }
  }

  const submitEditBlog = () => {

    setSavingChanges(true);


    if (authCtx.isLoggedIn) {
      const { title, snippet, post, slug, pageTitle, metaDescription, metaKeywords, isVideo, videoUrl } = createBlogObj;
      fetch(process.env.REACT_APP_API_URI + '/v1/admin/blogs/' + blog?.id + '/update', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({
          title,
          snippet,
          post,
          slug,
          pageTitle,
          metaDescription,
          metaKeywords,
          isVideo,
          videoUrl
        })
      }).then(response => {
        console.log("getting response");
        return response.json();
      }).then(data => {
        setSavingChanges(false);
        if (data.status == "success") {
          updateURL(blog?.id, blogUrl);
          console.log(blogUrl);
        } else {
          console.error("Error while editing blog?...");
        }
      }).catch((err) => console.error("Error while editing blog?...", err));
    }
  }

  const updateURL = async (blogId, url) => {

    if (url == createBlogObj.customUrl)

      // setUpdatingStatus(true)
      setUpdatingStatusId(blogId)

    try {
      console.log('updating slug')

      fetch(process.env.REACT_APP_API_URI + '/v1/admin/blogs/' + blogId + '/updateSlug', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ "slug": url })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status == 'success') {
            console.log('slug updated', data)
            getBlog();
            // updateSlugScript()
          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  // const updateSlugScript = async (blogId, url) => {

  //   // setUpdatingStatus(true)
  //   setUpdatingStatusId(blogId)

  //   try {
  //     console.log('updating slug script')

  //     fetch(process.env.REACT_APP_API_URI + '/v1/admin/blogs/slugScript', {
  //       method: 'PUT',
  //       mode: 'cors',
  //       headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
  //     })
  //       .then(response => {
  //         return response.json();
  //       }).then(data => {
  //         if (data.status == 'success') {
  //           console.log('slug scripyt updated', data)
  //         }
  //       }).catch(err => console.error(err));

  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  const closeDrawer = () => {
    console.log(blog?.image);
    drawerCtx.closeDrawer();

  }

  const getBlog = () => {
    fetch(process.env.REACT_APP_API_URI + '/v1/blogs/' + drawerCtx.details?.id, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          console.log("blogs ", data.data)
          setBlog(data.data);
          setOpen(true);

          updateStatusClass();

          //here we will set the details
        }
      }).catch(err => {
        console.error(err)
        // setUpdatingStatus(false)

      });
  }
  const updateStatusClass = () => {
    if (blog?.status == 'published') {
      setStatusClass('status-button published');
    } else {
      setStatusClass('status-button');
    }
  }

  const getStatusClassName = () => {
    if (blog?.status == 'published') {
      return 'status-button published'
    } else {
      return 'status-button'
    }
  }

  const openUploadImageModal = (id) => {
    modalCtx.setDetails('upload-image', { aspectRatio: undefined, origin: 'blog', id: id });
    modalCtx.openModal();
  }


  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }


  const updateStatus = async (blogId, status) => {

    try {

      fetch(process.env.REACT_APP_API_URI + '/v1/admin/blogs/' + blogId + '/updateStatus', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ status })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status == 'success') {
            // getAllBlogs()
            console.log("updated");
          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const removeSingleTopic = (indexToRemove) => {
    blog?.topics.splice(indexToRemove, 1);
    console.log(blog);
  }

  //quill editor
  //functions
  const submitEditAuthor = (id) => {

    if (authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + '/v1/admin/blogs/author', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({
          id: blog?.id,
          authorId: id
        })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status == "success") {
        } else {
          console.error("Error while editing blog?...");
        }
      }).catch((err) => console.error("Error while editing blog?...", err));
    }
  }

  const getAllAuthors = () => {

    fetch(process.env.REACT_APP_API_URI + '/v1/authors', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        console.log(response.json);
      }).then(data => {
        if (data.status == 'success') {
          setAuthors(data?.data)
        }
      }).catch(err => console.error(err));

  }

  const DeleteAuthor = async (devoId, id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/blogs/${devoId}/author/${id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const topicsResponse = await response.json();
      if (topicsResponse.status == 'success') {
        submitEditBlog()
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleChangeAuthor = (event) => {
    setAuthor(event.target.value);
  }

  const handleSelectAuthor = (author) => {
    if (!selectedAuthors.some(el => el.id === author.id)) {
      submitEditAuthor(author.id)
      // setSelectedAuthors(prev => [...prev, author]);
    } else {
      return
    }
  }

  const handleFilterArray = (id) => {

    DeleteAuthor(blog?.id, id)
    const newArr = selectedAuthors.filter((arr) => {
      return arr.id !== id
    })
    setSelectedAuthors(newArr)
  }

  const changeEditorStatus = (e) => {
    if (e) {
      const quill = quillRef.current.getEditor();
      console.log(quill.getSelection());
      setEditor("myeditor active")
    } else {
      const quill = quillRef.current.getEditor();

      console.log(quill.getSelection());
      // quill.setSelection('');

      setEditor(" myeditor disable")
    }
  }

  const DeleteTopic = async (devoId, id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/blogs/${devoId}/topic/${id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const topicsResponse = await response.json();
      if (topicsResponse.status == 'success') {
        submitEditBlog()
      }
    } catch (err) {
      console.log(err);
    }
  }


  const DeleteTag = async (devoId, id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/blogs/${devoId}/tag/${id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const topicsResponse = await response.json();
      if (topicsResponse.status == 'success') {
        submitEditBlog()
      }
    } catch (err) {
      console.log(err);
    }
  }

  const openPublishModal = (blog) => {
    modalCtx.setDetails('confirm-publish-blog', { blog });
    modalCtx.openModal();
    getBlog();
  }

  const updateStatusBlog = async (blogId, status) => {

    setUpdatingStatus(true)
    setUpdatingStatusId(blogId)

    try {

      fetch(process.env.REACT_APP_API_URI + '/v1/admin/blogs/' + blogId + '/updateStatus', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ status })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          setUpdatingStatus(false)
          if (data.status == 'success') {
            submitEditBlog()

          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  const handleVideoUrl = (url) => {
    let finalUrl
    let embedId

    if (url?.includes('youtu.be' || 'youtube')) {
      embedId = url.split('/')
      finalUrl = `https://www.youtube.com/embed/${embedId[embedId.length - 1]}`
    }

    return finalUrl
  }


  const handleVideoBoolean = (e) => {
    if (e) {
      setCreateBlogObj((prev) => ({ ...prev, isVideo: 'yes' }))
    } else {
      setCreateBlogObj((prev) => ({ ...prev, isVideo: 'no' }))
    }
  }

  function showRegDate(unFormattedDate) {

    const date = new Date(unFormattedDate);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };

    return date.toLocaleDateString('en-US', options);
  }

  const handleMetaTagsBoolean = (e) => {
    if (e) {
      setMetaTagsOpen(true)
    } else {
      setMetaTagsOpen(false)
    }
  }

  const handleEditorToolBoolean = () => {
    if (editorToolOpen) {
      setEditorToolOpen(false)
    } else {
      setEditorToolOpen(true)
    }
  }

  const handlePostPaste = (e) => {
    uploadMedia(e)
    // const imgData = e.replaceAll(/<img.*?src=["|'](.*?)["|']/g, 'srclol')
    // const stringArr = e.split()

    // const doc = new DOMParser().parseFromString(e, 'text/html');
    // const arr = [...doc.body.childNodes].map(child => child.outerHTML || child.textContent);

    // let newArr = []

    // for (let i = 0; i < arr?.length; i++) {
    //   if (arr[i].includes('<img')) {
    //     const imgStr = arr[i].match(/<img.*?src=["|'](.*?)["|']/)
    //     console.log(imgStr[1])
    //     uploadEpisodeImg(imgStr[1])
    //   } else {
    //     newArr.push(arr[i])
    //   }
    // }
    // // console.log("arr", arr);
    // // console.log('e', stringArr)
    // setCreateBlogObj((prev) => ({ ...prev, post: e }))
  }

  // upload image to firebase and then to PUT in devotional object
  const uploadEpisodeImg = (base64) => {

    let img = baseToFile(base64);
    let finalUrl;

    let coverRef = `/blog/images/${img.name}`;
    const storageRef = sRef(storage, coverRef)

    uploadBytes(storageRef, img).then((snapshot) => {
      getDownloadURL(sRef(storage, coverRef))
        .then((url) => {
          console.log('url', url)
          finalUrl = url
          return url;
        })
        .catch((error) => {
          console.log(error)
        });
    }, (error) => console.log(error)
    )

  }

  // this returns a file object from a base file
  const baseToFile = (base) => {
    if (base) {
      let arr = base?.split(",")
      let mime = arr[0].match(/:(.*?);/)[1]
      let data = arr[1]

      let dataStr = Base64.atob(data)
      let n = dataStr.length
      let dataArr = new Uint8Array(dataStr.length)

      while (n--) {
        dataArr[n] = dataStr.charCodeAt(n)
      }

      let file = new File([dataArr], `blog_img_${Date.now()}.png`, { type: mime })
      return file
    } else {
      return null
    }
  }

  const uploadMedia = (e) => {

    const doc = new DOMParser().parseFromString(e, 'text/html');
    const arr = [...doc.body.childNodes].map(child => child.outerHTML || child.textContent);
    for (let i = 0; i < arr?.length; i++) {
      let coverRefFinal = '';
      if (arr[i].includes('<img') && arr[i].includes('data:image/')) {
        const imgStr = arr[i].match(/<img.*?src=["|'](.*?)["|']/)
        const element = arr.find((el) => el.includes(imgStr[1]))
        let img = baseToFile(imgStr[1]);

        if (img) {
          let coverRef = `/blog/images/${img.name}`;
          const storageRef = sRef(storage, coverRef)

          const myPromise = new Promise(function (resolve, reject) {
            uploadBytes(storageRef, img).then((snapshot) => {
              return getDownloadURL(sRef(storage, coverRef))
                .then((url) => {
                  coverRefFinal = url
                  resolve(url)
                  return url;
                })
                .catch((error) => {
                  console.log(error)
                });
            }, (error) => console.log(error)
            )
          });

          myPromise.then((resolve) => {
            console.log('imgStr[1]', imgStr[1])

            const index = arr.indexOf(element);

            if (index !== -1) {
              arr[index] = element.replace(imgStr[1], coverRefFinal);
              console.log("arrInTheLoop", arr)
            }
            setCreateBlogObj((prev) => ({ ...prev, post: arr.join('') }))
          })
            .catch(err => console.log(err))
        } else {
          console.log("no img");
        }
      } else {
        setCreateBlogObj((prev) => ({ ...prev, post: e }))
      }
    }
  }

  console.log('blog', blog)

  if (blog == {}) return null;

  return (

    <div className="container">
      <div className="drawer blog-drawer-container">
        <div className="blog-drawer" style={editorToolOpen ? { gridTemplateRows: '160px 1fr 100px' } : { gridTemplateRows: '115px 1fr 100px' }}>

          <div className="blog-drawer-header">
            <header>
              <h1 data-aos="fade-right" data-aos-delay="500">
                < img className='back-button tool-button' onClick={(e) => {
                  closeDrawer();
                }} src={backbutton} alt='' />
                Edit Blog - {createBlogObj.title}</h1>
            </header>

            <div className="blog-webpage-clone">
              <div className="webpage-clone">
                <div className="webpage-link">
                  <ArrowBackRounded style={{ color: '#aaa' }} className='link-icon' />
                  <ArrowForwardRounded style={{ color: '#aaa' }} className='link-icon' />
                  <ReplayRounded style={{ color: '#aaa' }} className='link-icon' />
                  <div className="link-container">
                    <span>kingdomempowered.com/blogs/</span>
                    <input type="text" name="slug" value={createBlogObj.slug} placeholder={blogUrl} onChange={(e) => { setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value })); setBlogUrl(e.target.value) }} />
                  </div>
                </div>
                <div className="title-tab">
                  <img src={Logo} alt="logo" />
                  <span>{createBlogObj.title}</span>
                  <CloseRounded style={{ color: '#aaa' }} />
                </div>

                <div className='action-buttons-div'>
                  <FormGroup>
                    <FormControlLabel label="Show Meta Tags And Snippet" control={<Switch />} onChange={e => handleMetaTagsBoolean(e.target.checked)} />
                  </FormGroup>
                  <Button className='save-button' variant="contained" component="label" disabled={false}
                    onClick={(e) => submitEditBlog()}> {savingChanges ? <CircularProgress style={{ color: "white" }} size={20} /> : "Save Changes"}
                  </Button>
                  <Button className='cancel-button' variant="outlined" component="label" disabled={false}
                    onClick={(e) => closeDrawer()}>Cancel
                  </Button>
                </div>

                <div className="meta-tags-container" style={metaTagsOpen ? null : { display: 'none' }}>
                  <TextField style={{ margin: "10px auto 15px" }} name="pageTitle" label="Page Title" value={createBlogObj.pageTitle} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Page Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
                  <TextField style={{ margin: "10px auto 15px" }} name="metaDescription" label="Meta Description" value={createBlogObj.metaDescription} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Meta Description" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
                  <TextField style={{ margin: "10px auto 15px" }} name="metaKeywords" label="Meta Keywords" value={createBlogObj.metaKeywords} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Meta Keywords" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
                  <textarea className='snippet-textarea' placeholder='snippet' defaultValue={createBlogObj.snippet} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} name="snippet" rows="5" />
                  {/* <FormGroup>
                    <FormControlLabel label="Video" control={<Switch checked={createBlogObj.isVideo == 'yes'} />} onChange={e => handleVideoBoolean(e.target.checked)} />
                  </FormGroup>
                  <TextField disabled={createBlogObj.isVideo !== 'yes'} style={{ margin: "10px auto 15px" }} name="videoUrl" label="Video Url" value={createBlogObj.videoUrl} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Video Url" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required /> */}
                </div>

              </div>
            </div>
            <div className="editor-toolbar-container">
              <div className="editor-toolbar-control">
                <KeyboardArrowDownRounded style={editorToolOpen ? { transform: 'rotateX(180deg)' } : null} onClick={() => handleEditorToolBoolean()} />
              </div>
              <EditorToolbar editorToolOpen={editorToolOpen} />
            </div>
          </div>

          <div className='blog-edit-section' >
            <div>
              <div className='editor-div'>
                <div className='title-header'>

                  <TextField variant="standard" style={{ margin: "10px auto 15px" }} name="title" label={showRegDate(blog?.createdAt)} value={createBlogObj.title} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" InputProps={{
                    shrink: true,
                    disableUnderline: true,
                  }} fullWidth required />
                  <div className='author-row'>
                    <img src='https://d33byq9npfy6u9.cloudfront.net/2/2022/05/03184712/Glenn_Youngkin_Headshot-e1651603656923.jpg' className='author-image' alt='author image'></img>
                    <span className='blog-author' onClick={() => getAllAuthors()}>{blog?.author ? blog?.author[0]?.full_name : 'Add a author'} </span>
                    <span className='remove-blog-author' >{blog?.author ? <CloseRounded style={{ height: "15px", width: "15px" }} onClick={(e) => DeleteAuthor(blog?.id, blog?.author[0]?.id)} /> : null} </span>
                    <div className='author-tag' onClick={() => handleModal('edit-blog-author', blog)}><AddRounded className='add-author' /> Author</div>
                  </div>

                </div>

                <div className='content-container'>
                  <div className='text-editor-container'>
                    <div className='image-container'>

                      {
                        //  <img className='post-image placeholder' src={placeholder} alt='test' />
                        blog?.image && createBlogObj.isVideo == 'no'
                          ? <GetImage className='post-image' imageRef={blog?.image} alt={blog?.title} />
                          : <iframe
                            width="100%"
                            height="100%"
                            src={handleVideoUrl(blog?.videoUrl)}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                          />
                      }
                      <div className='upload-image-buttons' style={createBlogObj.isVideo == 'no' ? null : { display: 'none' }} >
                        <Button className='upload-button' variant="contained" component="label" disabled={false} startIcon={<PublishRounded className='buttons' />}
                          onClick={(e) => openUploadImageModal(blog?.id)}>
                          {
                            blog?.image
                              ? "Update Image"
                              : "Add Image"
                          }
                        </Button>

                        {
                          blog?.image
                            ? <Button className='remove-button' variant="outlined" component="label" disabled={false} startIcon={<HighlightOffRounded className='buttons' />}
                              onClick={null}>Remove Image
                            </Button>
                            : null
                        }
                      </div>
                      <div className='upload-video-input' style={createBlogObj.isVideo == 'yes' ? null : { display: 'none' }} >
                        <input type='text' placeholder='Enter video url..' name="videoUrl" label="Video Url" value={createBlogObj.videoUrl} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} />
                      </div>
                      <div className='image-toggle-button' >
                        <FormGroup>
                          <FormControlLabel label="Video" control={<Switch checked={createBlogObj.isVideo == 'yes'} />} onChange={e => handleVideoBoolean(e.target.checked)} />
                        </FormGroup>
                      </div>
                    </div>

                    <ReactQuill className={editor} ref={quillRef}
                      theme="snow"
                      defaultValue={blog.post}
                      value={createBlogObj.post}
                      onChangeSelection={(e) => {
                        changeEditorStatus(e)
                      }}
                      paste
                      onChange={(e) => handlePostPaste(e)}
                      placeholder={"Write something awesome..."}
                      modules={modules}
                      formats={formats}
                    />
                    {/* <ReactQuill className='myeditor' modules={{
                      toolbar: false  // hide the default toolbar
                    }} defaultValue='Blog Post' theme="snow" name="post" value={createBlogObj.post} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, post: e }))} /> */}

                  </div>

                  {/* Second Column Start Here */}

                  <div className='second-column'>
                    <p className='trending-text'>Trending</p>

                    <div className='trending-items'>
                      <img className='trending-image' src={placeholder} alt='test' />
                      <p>This is the Headline. Read the blog post.</p>
                    </div>

                    <div className='trending-items'>
                      <img className='trending-image' src={placeholder} alt='test' />
                      <p>This is the Headline. Read the blog post.</p>
                    </div>

                    <div className='trending-items'>
                      <img className='trending-image' src={placeholder} alt='test' />
                      <p>This is the Headline. Read the blog post.</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>

          <div className='tags-container'>
            <div className='topic-row'>
              {
                blog?.topics?.map((topic, i) => {
                  return <div className='single-tag' onClick={null}> {topic.title} <CloseRounded className='remove-author' onClick={() => DeleteTopic(blog?.id, topic.id)} /></div>
                })
              }
              <div className='single-tag' onClick={() => handleModal('edit-blog-topics', blog)}><AddRounded className='add-author' /> Topics</div>
            </div>

            <div className='tags-row'>
              {
                blog?.tags?.map((tag, i) => {
                  return <div className='single-tag' onClick={null}><CloseRounded className='remove-author' onClick={() => DeleteTag(blog?.id, tag.id)} /> {tag.title} </div>
                })
              }
              {/* <div className='single-tag' onClick={null}><CloseRounded className='add-author' onClick={() => null} /> Prayer</div> */}
              <div className='single-tag' onClick={() => handleModal('edit-blog-tags', blog)}><AddRounded className='add-author' /> Tag</div>
            </div>

            <div className='status-container'>

              Status -
              <div className="author-tags" onClick={() => openPublishModal(blog)}>
                <span className={getStatusClassName()}>{drawerCtx.details?.status?.toUpperCase()}</span>
              </div>
            </div>
          </div>




        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <SnackbarContent style={{
          backgroundColor: 'teal',
          borderRadius: "10px",
          color: "white",
        }}
          message={<span id="client-snackbar">Post Saved</span>}
        />
      </Snackbar>
    </div>
  );
}

export default EditBlogDrawer;

