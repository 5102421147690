import React, { useEffect, useState, useContext } from 'react';
import Image from "./Components/Image";
import SideBar from "./Components/SideBar";
import DetailsDrawer from "./Components/DetailsDrawer";
import DetailsModal from "./Components/DetailsModal";

// Pages
import Login from "./Pages/Login";
import Overview from "./Pages/Overview";
import Users from "./Pages/Users";
import Payments from "./Pages/Payments";
import Clients from "./Pages/Clients";
import Assessments from "./Pages/Assessments";
import Devotionals from "./Pages/Devotionals";
import Authors from "./Pages/Authors";
import Days from './Pages/Days';
import Episodes from './Pages/Episodes';
import Topics from './Pages/Topics';
import Reviews from './Pages/Reviews';
import Tags from './Pages/Tags';
import Courses from './Pages/Courses';
import Blogs from './Pages/Blogs';

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import AuthContext from './store/AuthContext.js';
import { DrawerContextProvider } from './store/DrawerContext.js';
import { ModalContextProvider } from './store/ModalContext.js';
import { AdminContextProvider } from './store/AdminContext.js';
import Administrators from './Pages/Adminstrators';
import Books from './Pages/Books';
import Advertising from './Pages/Advertising/Advertising';
import AdvertisingSlug from './Pages/Advertising/AdvertisingSlug';
import Featured from './Pages/Featured';
import SupportingFiles from './Pages/SupportingFiles';

function App() {
	
	const authCtx 								= useContext(AuthContext);
	const [name, setName] 				= useState('');
	console.log(authCtx.destination );

	return (
		<div className="App">
			
			<DrawerContextProvider>
				<ModalContextProvider>
				<AdminContextProvider>
					<BrowserRouter>
						<SideBar />
						<main className="main">						
							<Routes>
								<Route path="/login" exact index element={!authCtx.isLoggedIn ? <Login /> : <Navigate to={`/${authCtx.destination}`} />} />
								<Route path="/overview" exact element={authCtx.isLoggedIn ? <Overview/> : <Navigate to='/login?destination=overview' />} />
								<Route path="/devotionals" exact element={authCtx.isLoggedIn ? <Devotionals /> : <Navigate to='/login?destination=devotionals' />} />
								<Route path="/courses" exact element={authCtx.isLoggedIn ? <Courses /> : <Navigate to='/login?destination=courses' />} />
								<Route path="/courses/:id/episodes" exact element={authCtx.isLoggedIn ? <Episodes /> : <Navigate to='/login?destination=courses' />} />
								<Route path="/books" exact element={authCtx.isLoggedIn ? <Books /> : <Navigate to='/login?destination=books' />} />
								<Route path="/blogs" exact element={authCtx.isLoggedIn ? <Blogs /> : <Navigate to='/login?destination=blogs' />} />
								<Route path="/devotionals/:id/days" exact element={authCtx.isLoggedIn ? <Days /> : <Navigate to='/login?destination=devotionals' />} />
								<Route path="/authors" exact element={authCtx.isLoggedIn ? <Authors /> : <Navigate to='/login?destination=authors' />} />
								<Route path="/topics" exact element={authCtx.isLoggedIn ? <Topics /> : <Navigate to='/login?destination=topics' />} />
								<Route path="/reviews" exact element={authCtx.isLoggedIn ? <Reviews /> : <Navigate to='/login?destination=reviews' />} />
								<Route path="/tags" exact element={authCtx.isLoggedIn ? <Tags /> : <Navigate to='/login?destination=tags' />} />
								<Route path="/users" exact element={authCtx.isLoggedIn ? <Users/> : <Navigate to='/login?destination=users'/>} />
								<Route path="/payments" exact element={authCtx.isLoggedIn ? <Payments/> : <Navigate to='/login?destination=payments'/>} />
								<Route path="/advertising" exact element={authCtx.isLoggedIn ? <Advertising/> : <Navigate to='/login?destination=advertising'/>} />
								<Route path="/advertising/:slug" exact element={authCtx.isLoggedIn ? <AdvertisingSlug/> : <Navigate to='/login?destination=advertising'/>} />
								<Route path="/course/supporting-files/:courseId" exact element={authCtx.isLoggedIn ? <SupportingFiles entityType={'course'} /> : <Navigate to='/login?destination=course'/>} />
								<Route path="/episode/supporting-files/:id/course/:courseId" exact element={authCtx.isLoggedIn ? <SupportingFiles entityType={'episode'} /> : <Navigate to='/login?destination=course'/>} />
								<Route path="/featured" exact element={authCtx.isLoggedIn ? <Featured/> : <Navigate to='/login?destination=featured'/>} />
								<Route path="/administrators" exact element={authCtx.isLoggedIn ? <Administrators/> : <Navigate to='/login?destination=administrators'/>} />
								<Route path="/" exact index element={<Navigate to="/login"/>} />
							</Routes>
						</main>
						<DetailsModal />
						<DetailsDrawer />
					</BrowserRouter>
				</AdminContextProvider>
				</ModalContextProvider>
			</DrawerContextProvider>
			
		</div>
	);
}

export default App;
