import React, { useEffect, useState, useContext } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';

import GetImage from '../Components/GetImage'

import AuthContext from '../store/AuthContext.js';
import ModalContext from '../store/ModalContext.js';
import DrawerContext from '../store/DrawerContext.js';

//assets
import placeholder from '../assets/img/placeholder.png';
import editButton from '../assets/img/blog/edit.svg';
import deleteButton from '../assets/img/blog/delete.svg';

import { Button, CircularProgress, Card, TextField, FormControlLabel, Checkbox } from '@mui/material'
import { AddRounded } from '@mui/icons-material';
import moment from 'moment';
import { database } from '../Components/FirebaseConfig';
import { onValue, ref } from 'firebase/database';

const Blogs = () => {

  //firebase db
  const db = database;

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const navigate = useNavigate();

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [blogs, setBlogs] = useState([])
  const [blogPage, setBlogPage] = useState(2)


  // updating status states 
  const [updatingStatusId, setUpdatingStatusId] = useState();
  const [lastpage, setLastPage] = useState(0);
  const [status, setStatus] = useState('both');
  const [blogSearchTerm, setBlogSearchTerm] = useState('');

  //boolean states
  const [loadingBlogs, setLoadingBlogs] = useState(false);
  const [filterDraft, setFilterDraft] = useState(false);
  const [filterPublished, setFilterPublished] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [end, setEnd] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getAllBlogs();
    }


    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {

    getAllBlogs();

  }, [modalCtx, drawerCtx]);

  /**
   * this is for the status related filtering 
   */
  useEffect(() => {

    if (filterDraft && filterPublished) {
      setStatus('both')
    } else if (filterDraft && !filterPublished) {
      setStatus('draft')
    } else if (!filterDraft && filterPublished) {
      setStatus('published')
    } else if (!filterDraft && !filterPublished) {
      setStatus('both')
    }
    getAllBlogs()

  }, [filterDraft, filterPublished]);


  /**
   * i think this is pretty self explanatory, search related effect. if the search field gets empty, we will repopulate blogs with the first 30 blogs
   */
  useEffect(() => {

    if (blogSearchTerm == '') {
      getAllBlogs()
    } else {
      handleSearchBlogs()
    }

  }, [blogSearchTerm]);


  //get comments from firebase
  const addPostListener = (id) => {
    let arrLength = 0
    const dbRef = ref(db, `communityComments/${id}`)
    onValue(dbRef, (snapshot) => {

      arrLength = snapshotToArray(snapshot)?.length
    })
    return arrLength
  }

  const snapshotToArray = (snapshot) => {
    const returnArr = []
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val()
      returnArr.push(item)
    })

    return returnArr.reverse()
  }

  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  const handleShowMoreBlogs = () => {
    getAllBlogsPagination(blogPage)
  }

  const handleShowLessBlogs = () => {
    setBlogPage(1)
    getAllBlogsPagination(1)
  }

  /**
   * this will run on the initial page load, getting first 30 blogs
   */
  const getAllBlogs = async () => {
    try {

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/blogs/new`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
      const data = await res.json()

      if (data.status === 'success') {

        setBlogs(data.data)

        /**
         * we are setting the blogPage to 2 cuz we are already getting the first 30 blogs from "this" call, so when the getAllBlogsPagination(blogPage) gets called, we will get the next 30 blogs and so on
         */
        setBlogPage(2)
        setEnd(false)
      } else {
        if(data?.code == 'auth/argument-error'){
          navigate("/login?destination=blogs")
          authCtx.logout()
        }
      }

    } catch (e) {
      console.log('error while getting the topics ', e)
    }
  }

  /**
   * so this function will not run on the initial page load, cuz of the pagination getting in conflict with the use effect
   */
  const getAllBlogsPagination = async (blogPage) => {
    try {

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/blogs/byPage?page=${blogPage}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
      const data = await res.json()

      if (data.status === 'success') {
        if (data.pagination.totalPages >= blogPage) {
          console.log(data)

          setBlogPage(prev => prev + 1)
          setEnd(false);
          setBlogs(prev => [...prev.concat(data.data)])

        } else {
          setEnd(true);
        }
      } else {
        if(data?.code == 'auth/argument-error'){
          navigate("/login?destination=blogs")
          authCtx.logout()
        }
      }

    } catch (e) {
      console.log('error while getting the topics ', e)
    }
  }

  /**
   * this is the search function to get the blogs related search query
   */
  const handleSearchBlogs = async () => {
    try {

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/blogs/search?search=${blogSearchTerm}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
      const blogsData = await res.json()

      if (blogsData.status === 'success') {

        setBlogs(blogsData.data)

      }

    } catch (e) {
      console.log('error while getting the topics ', e)
    }
  }

  const handlePublishedStatus = (event) => {

    if (event.target.checked) {
      setFilterPublished(true);
    } else {
      setFilterPublished(false);
    }
  };

  const handleDraftStatus = (event) => {
    if (event.target.checked) {
      setFilterDraft(true);
    } else {
      setFilterDraft(false);
    }
  };


  const getStatusClass = (id) => {
    let blog = blogs.find(obj => obj.id == id);
    if (blog && blog.status == 'published') {
      return 'author-tag published';
    } else {
      return 'author-tag';
    }
  }

  const postStatusClass = (id) => {
    let blog = blogs.find(obj => obj.id == id);
    if (blog && blog.status == 'published') {
      return 'post-status published';
    } else {
      return 'post-status draft';
    }
  }

  const openDeleteDevotionalModal = (id) => {
    modalCtx.setDetails('confirm-delete-blog', { id });
    modalCtx.openModal();
  }
  const openPublishModal = (blog) => {
    modalCtx.setDetails('confirm-publish-blog', { blog });
    modalCtx.openModal();
  }

  const openUploadImageModal = (id) => {
    modalCtx.setDetails('upload-image', { aspectRatio: undefined, origin: 'blog', id: id });
    modalCtx.openModal();
  }


  const handleSearchTag = (e) => {
    setBlogSearchTerm(e.target.value)
  }

  // console.log("draft", filterDraft)
  // console.log("published", filterPublished)
  // console.log("status", status)
  // console.log("blogs", blogs)

  return (
    <div className='container'>

      <header>
        <h1>Blogs</h1>
        <div className="create-devotional">
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => handleDraftStatus(e)}
                  name="draft" />
              }
              label="Draft Only"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => handlePublishedStatus(e)}
                  name="published" />
              }
              label="Publised Only"
            />
            <TextField type='text' placeholder='Search Blogs..' value={blogSearchTerm} onChange={handleSearchTag} />

          </div>
          <Button className='header-cta' onClick={() => toggleDrawerHandler('create-blog', null)}>+ Create New</Button>
        </div>
      </header>


      <div className='flex-table'>


        {!loadingBlogs && blogs?.filter(item => status !== 'both' ? item.status === status : item).map((blog, index) => (
          <Card className='blog-card' key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <div className={postStatusClass(blog.id)}></div>

            {
              blog.image ?
                <div className='devo-img-container' onClick={() => openUploadImageModal(blog.id)}>
                  <GetImage imageRef={blog.image} alt={blog.title} />
                </div>
                :
                <div className='placeholder-img' onClick={() => openUploadImageModal(blog.id)}>
                  <img src={placeholder} alt="placeholder" />
                  <span className='placeholder-text' >No image. Click to Add a image</span>
                </div>
            }


            <div align="left" className='devo-title'>

              <p className='devo-title-text' >{blog.title}</p>
              <p className='devo-title-date' ><span>Post Date -</span>{blog.publishedDate ? moment(blog.publishedDate).format("MMM DD, YYYY") : ' Publish date not provided'}</p>
              <p className='devo-title-text' >{blog.time}</p>

              <div className='tags-row'>
                <div className={typeof blog.author !== 'undefined' && blog.author.length > 0 ? 'author-tag' : 'topic-tag'} onClick={() => handleModal('edit-blog-author', blog)}>
                  <AddRounded className='add-author action-icon' />
                  {typeof blog.author !== 'undefined' && blog.author.length > 0 ? blog.author[0].full_name : 'Author'}
                  {typeof blog.author !== 'undefined' && blog.author.length > 0 ? <div className='number-icon'>{blog.author.length}</div> : null}
                </div>
                <div className='topic-tag' onClick={() => handleModal('edit-blog-topics', blog)} >
                  <AddRounded className='add-author action-icon' />
                  Topic
                  {typeof blog.topics !== 'undefined' && blog.topics.length > 0 ? <div className='number-icon'>{blog.topics.length}</div> : null}
                </div>
                <div className='keyword-tag' onClick={() => handleModal('edit-blog-tags', blog)} >
                  <AddRounded className='add-author action-icon' />
                  Tag
                  {typeof blog.tags !== 'undefined' && blog.tags.length > 1 ? <div className='number-icon'>{blog.tags.length}</div> : null}
                </div>
              </div>

              <div className='engaments-row'>
                <p className='devo-title-date' ><span>Likes - </span>{blog.time}0 Likes</p>
                <p className='devo-title-comment' onClick={() => handleModal('blog-comment-approve', { id: blog.id })} ><span>Comments - </span> <span className='comment-count'> {addPostListener(blog.id)} {addPostListener(blog.id) == 1 ? 'Comment' : 'Comments'}</span> </p>
              </div>

              <div className='editable-row'>
                <div className='status-row'>
                  <span>Status -</span>
                  <div className="author-tags"
                    onClick={() => openPublishModal(blog)}

                  >
                    {
                      (updatingStatus && updatingStatusId == blog.id) ?
                        <CircularProgress size={20} />
                        :
                        <span className={getStatusClass(blog.id)}>{typeof blog.status !== 'undefined' ? blog.status.toUpperCase() : null}</span>
                    }
                  </div>
                </div>

                <div align="right" className="action-buttons-t-cell">

                  <img className='back-button action-icon' onClick={() => toggleDrawerHandler('edit-blog', blog)} src={editButton} alt='' />
                  <img className='back-button action-icon' onClick={() => openDeleteDevotionalModal(blog.id)} src={deleteButton} alt='' />
                </div>
              </div>
            </div>

          </Card>
        ))}




      </div>
      {
        !loadingBlogs && blogSearchTerm == ''
          ? <div className="load-more-btn-container">
            {
              end
                ? <Button className='load-more-btn' onClick={() => { setBlogs([]); handleShowLessBlogs() }}>Load Less</Button>
                : <Button className='load-more-btn' onClick={() => handleShowMoreBlogs()}>Load More</Button>
            }
          </div>
          : null
      }

    </div>
  )
}

export default Blogs;
