import { Button, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import AuthContext from '../../../store/AuthContext';
import DrawerContext from '../../../store/DrawerContext';
import { useNavigate } from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ModalContext from '../../../store/ModalContext';
import { CloseRounded } from '@mui/icons-material';

const EditCourseEpisodeDrawer = ({drawerDetails}) => {

    const detail =drawerDetails;
    console.log(detail, "tjis");

  const authCtx   = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const navigate  = useNavigate();

  const [video, setVideo] = useState('')

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [createDevotionalObj, setCreateDevotionalObj] = useState({ meta_description:'',page_title:'',meta_keywords:'', title: detail.title, text: detail.text });

  // console.log("createDevotionalObj", createDevotionalObj);

  const submitCreateDevotional = () => {
    
    if(authCtx.isLoggedIn) {

    }
    const { meta_description,page_title, title, text , meta_keywords} = createDevotionalObj;
		fetch(process.env.REACT_APP_API_URI + `/v1/admin/courses/${detail.courseId}/episodes/${detail.id}/update`, {
			method: 'PUT',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
			body: JSON.stringify({
				title, page_title,text,meta_description, meta_keywords
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			if(data.status == "success") {
				// navigate("/devotionals", { replace: true });
        drawerCtx.closeDrawer();
        window.location.reload();
			} else {
        console.error("Error while creating devotional...");
				
			}
		}).catch((err) => console.error("Error while creating devotional...", err));
  }


  return (
    <div className="container">
      <div className="drawer">

        <header>
        <CloseRounded onClick={()=>drawerCtx.closeDrawer()} className='close-button'/><h1 data-aos="fade-right" data-aos-delay="500">   Edit Episode - {detail.title}</h1>
        </header>

        <section>

          {/* <div className="double-column-form"> */}
          <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={createDevotionalObj.title} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          <TextField style={{ margin: "10px auto 15px" }} name="page_title" label="Page Title" value={createDevotionalObj.page_title} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Page Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          
          <div style={{display:'flex', gap:"20px"}}>
          <TextField style={{ margin: "10px auto 15px" }} name="meta_description" label="Meta Description" value={createDevotionalObj.meta_description} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Meta Description" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          <TextField style={{ margin: "10px auto 15px" }} name="meta_keywords" label="Meta Keywords" value={createDevotionalObj.meta_keywords} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Meta Keywords" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          
          </div>
         {/* <TextField style={{ margin: "10px auto 15px" }} name="text" label="Text" value={createDevotionalObj.text} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required /> */}
          {/* <TextField style={{ margin: "10px auto 15px" }} name="description" label="Description" value={createDevotionalObj.description} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" multiline rows={4} InputLabelProps={{ shrink: true }} fullWidth required /> */}
          <ReactQuill theme="snow" name="text" value={createDevotionalObj.text} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, text: e }))} placeholder='Description' />
          {/* </div> */}

          <div className="double-column-form aligned-right margin-top">

            <Button variant="contained" component="label" disabled={false} fullWidth
              onClick={(e) => submitCreateDevotional()}>Edit Details
            </Button>
          </div>

        </section>

      </div>
    </div>
  )
}



export default EditCourseEpisodeDrawer;
