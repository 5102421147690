//react
import React, { useEffect, useState } from 'react';

//assets
import { BsFilePdf } from 'react-icons/bs'
import { IoMailOutline } from 'react-icons/io5'
import map from '../assets/img/overview/map.svg';
import devotional from '../assets/img/overview/devotional.png'
import BlogPost from '../assets/img/overview/BlogPost.png'
import PopularCourse from '../assets/img/overview/PopularCourse.png'

//components
import DesktopMobileData from '../Components/Charts/DesktopMobileData';
import IOSAndroidData from '../Components/Charts/IOSAndroidData';
import GetImage from '../Components/GetImage';

const Overview = () => {

  //states

  const [totalAccounts, setTotalAccounts] = useState(0);
  const [totalPdfDownloads, setTotalPdfDownloads] = useState(0);
  const [totalEmailDownloads, setTotalEmailDownloads] = useState(0);
  const [totalBlogViews, setTotalBlogViews] = useState(0);
  const [totalCourseViews, setTotalCourseViews] = useState(0);
  const [popularBlog, setPopularBlog] = useState();
  const [popularCourse, setPopularCourse] = useState();
  const [popularDevotional, setPopularDevotional] = useState();
  const [geoData, setGeoData] = useState();

  //useEffects

  useEffect(() => {
    getOverviewData()
  }, []);

  //functions

  const getOverviewData = () => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/overview/stats`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status === 'success') {
          // console.log(data)
          setTotalAccounts(data.totalAccounts)
          setTotalBlogViews(data.totalBlogViews)
          setTotalCourseViews(data.totalCourseViews)
          setPopularBlog(data.mostPopularBlog)
          setPopularCourse(data.mostPopularCourse)
          setPopularDevotional(data.mostPopularDevotional)
        }
      }).catch(err => console.error(err));

  }



  return (
    <div className="overview-main-parent">
      <h1 className="overview-heading">Overview</h1>
      <div className="total-data-panel">
        <div className="total-no-of-accounts total-data-panel-child">
          <div className='total-data-inner-child'>
            <h3 className="heading">
              Total No. of Accounts
            </h3>
            <h1 className="total-no total-data-lg-number total-data-middle-section">{totalAccounts}</h1>
            <h3 className="weekly-change">
              15% last week
            </h3>
          </div>
        </div>
        <div className="total-devotional-downloads total-data-panel-child">
          <div className='total-data-inner-child'>
            <h3 className="heading">
              Total Devotional Downloads
            </h3>
            <div className="pdf-and-email-section total-data-middle-section">
              <div className="pdf-section">
                <div className="pdf-and-email-icons">
                  <BsFilePdf />
                  <p className="pdf-email-text">PDF</p>
                </div>
                <h1 className='total-data-lg-number'>{totalPdfDownloads}</h1>
              </div>
              <div className="divide-line"></div>
              <div className="email-section">
                <div className="pdf-and-email-icons">
                  <IoMailOutline />
                  <p className="pdf-email-text">Email</p>
                </div>
                <h1 className='total-data-lg-number'>{totalEmailDownloads}</h1>
              </div>
            </div>
            <h3 className="weekly-change">
              15% last week
            </h3>
          </div>
        </div>
        <div className="total-no-of-blog-views total-data-panel-child">
          <div className='total-data-inner-child'>
            <h3 className="heading">
              Total No. of Blog Views
            </h3>
            <h1 className='total-no total-data-lg-number total-data-middle-section'>{totalBlogViews}</h1>
            <h3 className="weekly-change">
              15% last week
            </h3>
          </div>
        </div>
        <div className="total-no-of-course-views total-data-panel-child">
          <div className='total-data-inner-child'>
            <h3 className="heading">
              Total No. of Course Views
            </h3>
            <h1 className='total-no total-data-lg-number total-data-middle-section'>{totalCourseViews}</h1>
            <h3 className="weekly-change">
              15% last week
            </h3>
          </div>
        </div>
      </div>

      {/* Continent Map */}
      <div className="continent-map">
        <div className="continent-map-heading">
          <h1 className="map-heading">
            Geographical Region of Each Account
          </h1>
        </div>
        <div className="continent-map-img">
          <img src={map} alt="" />
        </div>
        <div className="no-of-accounts-by-continents-container">
          <div className="continent-and-account-no">
            <div className="continent-color-and-name-container">
              <div className="continent-color Asia-continent-color"></div>
              <h3 className="continent-name">Asia</h3>
            </div>
            <div className="continent-account-no">
              <h4 className="continent-total-accounts">200</h4>
            </div>
          </div>
          <div className="continent-and-account-no">
            <div className="continent-color-and-name-container">
              <div className="continent-color North-America-continent-color"></div>
              <h3 className="continent-name">North America</h3>
            </div>
            <div className="continent-account-no">
              <h4 className="continent-total-accounts">200</h4>
            </div>
          </div>
          <div className="continent-and-account-no">
            <div className="continent-color-and-name-container">
              <div className="continent-color Europe-continent-color"></div>
              <h3 className="continent-name">Europe</h3>
            </div>
            <div className="continent-account-no">
              <h4 className="continent-total-accounts">200</h4>
            </div>
          </div>
          <div className="continent-and-account-no">
            <div className="continent-color-and-name-container">
              <div className="continent-color South-America-continent-color"></div>
              <h3 className="continent-name">South America</h3>
            </div>
            <div className="continent-account-no">
              <h4 className="continent-total-accounts">200</h4>
            </div>
          </div>
          <div className="continent-and-account-no">
            <div className="continent-color-and-name-container">
              <div className="continent-color Australia-continent-color"></div>
              <h3 className="continent-name">Australia</h3>
            </div>
            <div className="continent-account-no">
              <h4 className="continent-total-accounts">200</h4>
            </div>
          </div>
          <div className="continent-and-account-no">
            <div className="continent-color-and-name-container">
              <div className="continent-color Africa-continent-color"></div>
              <h3 className="continent-name">Africa</h3>
            </div>
            <div className="continent-account-no">
              <h4 className="continent-total-accounts">200</h4>
            </div>
          </div>
          <div className="continent-and-account-no">
            <div className="continent-color-and-name-container">
              <div className="continent-color Antartica-continent-color"></div>
              <h3 className="continent-name">Antartica</h3>
            </div>
            <div className="continent-account-no">
              <h4 className="continent-total-accounts">200</h4>
            </div>
          </div>
        </div>
      </div>

      {/* Pie charts section */}
      <div className="pie-charts-section">
        <DesktopMobileData />
        <IOSAndroidData />
      </div>

      {/* Most popular contents */}
      <div className="most-popular-section">
        <div className="content-box">
          <h4 className="most-popular-section-heading">Most Popular Devotional</h4>
          <GetImage imageRef={popularDevotional?.image} />
          <h4>{popularDevotional?.title}</h4>
        </div>
        <div className="content-box">
          <h4 className="most-popular-section-heading">Most Popular Blog Post</h4>
          <GetImage imageRef={popularBlog?.image} />
          <h4>{popularBlog?.title}</h4>
        </div>
        <div className="content-box">
          <h4 className="most-popular-section-heading">Most Popular Course</h4>
          <GetImage imageRef={popularCourse?.image} />
          <h4>{popularCourse?.title}</h4>
        </div>
      </div>

    </div>
  );
}
export default Overview;