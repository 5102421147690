import { CircularProgress } from '@mui/material'
import React, { useContext, useState } from 'react'

import ModalContext from '../../store/ModalContext'
import AuthContext from '../../store/AuthContext'


const BuildSiteModal = () => {

  const modalCtx = useContext(ModalContext)
  const authCtx = useContext(AuthContext)

  const [submitting, setSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submitForBuild = async () => {

    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    }

    try {
      setSubmitting(true)
      const res = await fetch('https://build.kingdomempowered.com/v1/build', options)
      const data = await res.json()

      if (data.status == 'success') {
        console.log(data);
        setSubmitting(false);
        setIsSubmitted(true);
        // modalCtx.closeModal()
      }
    } catch (error) {
      console.log('error submitting website for build :', error)
    }
  }

  const showBuildMessage = () => {

    if(isSubmitted) {
      return <>
        <div className="modal-content">
          <p>This may take upto 5-10 minutes</p>
          <p>You can close this and check the site in 10mins. Please contact support if there are any issues with the build</p>
        </div>
        <div className="btn-container">
          <button className='btn-primary-border' onClick={() => modalCtx.closeModal()} >close</button>
        </div>
      </>
      // <div className="loading-overlay">
      //   <p>This may take upto 5-10 minutes</p>
        
      //   <p>You can close this and check the site in 10mins. Please contact support if there are any issues with the build</p>
      // </div>
    } else if(submitting) {
      return <>
        <div className="modal-content">
          <p>You're about to run a build for the Website and submit it to Google for indexing.</p>
          <p>Would you like to proceed?</p>
        </div>
        <div className="btn-container">
          <CircularProgress style={{ color: '#3149C3' }} />
        </div>
      </>
    } else {
      return <>
        <div className="modal-content">
          <p>You're about to run a build for the Website and submit it to Google for indexing.</p>
          <p>Would you like to proceed?</p>
        </div>
        <div className="btn-container">
          <button className='btn-primary-border' onClick={() => modalCtx.closeModal()} >No</button>
          <button className='btn-primary' onClick={() => submitForBuild()} >Yes</button>
        </div>
      </>
    }

  }

  return (
    <div className='build-site-modal' >
      {showBuildMessage()}
    </div>
  )
}

export default BuildSiteModal 