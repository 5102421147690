import React, { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material';

import AuthContext from '../store/AuthContext.js';
import ModalContext from '../store/ModalContext.js';
import DrawerContext from '../store/DrawerContext.js';

const Days = () => {

	const authCtx		= useContext(AuthContext);
	const modalCtx	= useContext(ModalContext);
	const drawerCtx = useContext(DrawerContext);

  const { id }    = useParams();
  const navigate  = useNavigate();

  console.log("id", id);

  function toggleDrawerHandler(drawer, data) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, data);
	};

	const [days, setDays] = useState([])

	useEffect(() => {
		getAllDays();
	}, []);
    
	const handleModal = (modal) => {
		console.log('modal: ' + modal)
		console.log(modalCtx)
		modalCtx.openModal();
		modalCtx.setDetails(modal, null);
	}

	const getAllDays = () => {

		// fetch(process.env.REACT_APP_API_URI + '/v1/days', {
		fetch(process.env.REACT_APP_API_URI + '/v1/admin/devotionals/' + id + '/days', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
		})
		.then(response => {
			// console.log(response);
			return response.json();
		}).then(data => {
			console.log('Success:', data);
			if (data.status == 'success') {
				setDays(data?.days)
			}  else {
        if (data?.code == 'auth/argument-error') {
          navigate(`/login?destination=devotionals/${id}/days`)
          authCtx.logout()
        }
      }
		}).catch(err => console.error(err));

	}

	const openDeleteDayModal = (id, day) => {
		modalCtx.setDetails('confirm-delete-day', { id: id, day: day });
    modalCtx.openModal();
	}

	function removeTags(str) {
		if ((str === null) || (str === ''))
			return false;
		else
			str = str.toString();
	
		return str.replace(/<[^>]+>/ig, '').slice(0, 70);
	}



  return (
    <div className='container'>

			<header>
				<h1>Devotional's Days</h1>
				<div className="create-devotional">
					{/* <Button className='create-devotional-btn' onClick={() => handleModal('createDevotional')} >+ Create New</Button> */}
					<Button className='header-cta' onClick={() => toggleDrawerHandler('create-day', { id })}>+ Create New</Button>
				</div>
			</header>
			
			<div className="data-table">
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
							<TableRow>
								{/* <TableCell align="center">Image</TableCell> */}
								<TableCell align="center">Day</TableCell>
								<TableCell align="left">Title</TableCell>
								<TableCell align="left">Text</TableCell>
								<TableCell align="right"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{days?.map((day) => (
								<TableRow key={day.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

									{/* <TableCell component="th" scope="row">
										{devotional.image ? <img src={devotional.image} alt={devotional.title} className='devotional-img' height="24px" width="auto" /> : <span style={{ display: "block", margin: "0 auto", height: "24px", width: "24px", backgroundColor: "gray" }}></span>}
									</TableCell> */}
									<TableCell align="center">{day.days_no}</TableCell>
									<TableCell align="left">{day.title}</TableCell>
									<TableCell align="left" className='devo-description'>{removeTags(day.text)}</TableCell>
									<TableCell align="right" className="action-buttons-cell">
										<Delete className="devo-delete-btn action-icon" onClick={() => openDeleteDayModal(day.id)} />
										<Edit className="devo-edit-btn action-icon" onClick={() => toggleDrawerHandler('edit-day', day)} />										
									</TableCell>

								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
    </div>
  )
}

export default Days;

