
import React, { useState, useContext, useEffect, Component  } from 'react';
import { useLocation } from 'react-router-dom';

import { Drawer } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import { classes } from 'istanbul-lib-coverage';

// devotional
import EditDevotionalDrawer from './Drawers/Devotional/EditDevotionalDrawer';
import EditDevotionalAuthorDrawer from './Drawers/Devotional/EditDevotionalAuthorDrawer';
import EditDevotionalTagsDrawer from './Drawers/Devotional/EditDevotionalTagsDrawer';
import EditDevotionalTopicsDrawer from './Drawers/Devotional/EditDevotionalTopicsDrawer';
import CreateDevotionalDrawer from './Drawers/Devotional/CreateDevotionalDrawer';
import EditDayDrawer from './Drawers/Devotional/EditDayDrawer';
import CreateDayDrawer from './Drawers/Devotional/CreateDayDrawer';
// courses
import CreateCourseDrawer from './Drawers/Courses/CreateCourseDrawer';
import CreateCourseEpisodeDrawer from './Drawers/Courses/CreateCourseEpisodeDrawer';
import EditEpisodeDrawer from './Drawers/Courses/EditEpisodeDrawer';
import EditCourseDrawer from './Drawers/Courses/EditCourseDrawer';
//blogs
import EditBlogDrawer from './Drawers/Blogs/EditBlogDrawer';
import CreateBlogDrawer from './Drawers/Blogs/CreateBlogDrawer';
//other
import CreateNetworkDrawer from './Drawers/CreateNetworkDrawer';
import CreateTopicDrawer from './Drawers/CreateTopicDrawer';
import CreateTagDrawer from './Drawers/CreateTagDrawer';
import CreateAuthorDrawer from './Drawers/CreateAuthorDrawer';
import EditAuthorDrawer from './Drawers/EditAuthorDrawer';
import EditTopicDrawer from './Drawers/EditTopicDrawer';
import EditTagDrawer from './Drawers/EditTagDrawer';
import UserDrawer from './Drawers/UsersDrawer';

import DrawerContext from '../store/DrawerContext.js';
import CreateEditReviewDrawer from './Drawers/CreateEditReviewDrawer';
import CreateUserDrawer from './Drawers/CreateUserDrawer';
import CreateBookDrawer from './Drawers/Books/CreateBookDrawer';
import EditCourseEpisodeDrawer from './Drawers/Courses/EditCourseEpisodeDrawer';

const noMenuRoutes = ["/login", "/register"];

function DetailsDrawer(props) {

	const drawerCtx = useContext(DrawerContext);
	const { pathname } = useLocation();

	const [openDrawer, setOpenDrawer] = useState(false);
	const [selectedTab, setSelectedTab] = React.useState(0);
	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	function closeDrawer() {
		drawerCtx.closeDrawer();
	}

	if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

	const currentDrawer = () => {
		switch(drawerCtx.drawer) {
			
			// Users
			case "user-details": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><UserDrawer userDetails={drawerCtx.details} /></Drawer>;
			case "create-user": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateUserDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			//devotional
			case "create-devotional": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateDevotionalDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			case "create-day": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateDayDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			case "edit-day": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditDayDrawer /></Drawer>;
			case "edit-devotional": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditDevotionalDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			case "edit-devotional-author": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditDevotionalAuthorDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			case "edit-devotional-tags": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditDevotionalTagsDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			case "edit-devotional-topics": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditDevotionalTopicsDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			//courses
			case "create-course": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateCourseDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			case "create-course-episode": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateCourseEpisodeDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			case "edit-course": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditCourseDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			case "edit-episode": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditCourseEpisodeDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			//books
			//blogs
			case "create-book": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateBookDrawer /></Drawer>;
			case "edit-book": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditBlogDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			

			//blogs
			case "create-blog": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateBlogDrawer /></Drawer>;
			case "edit-blog": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditBlogDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			//other
			case "create-network": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateNetworkDrawer /></Drawer>;
			case "create-topic": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateTopicDrawer /></Drawer>;
			case "edit-topic": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditTopicDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			case "create-edit-review": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateEditReviewDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			// case "edit-review": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditTopicDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			case "create-tag": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateTagDrawer /></Drawer>;
			case "edit-tag": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditTagDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			case "create-author": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateAuthorDrawer drawerDetails={drawerCtx.details} /></Drawer>;
			case "edit-author": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditAuthorDrawer drawerDetails={drawerCtx.details} /></Drawer>;

			default:  return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><h1>No details here, sorry!</h1></Drawer>;
		}
	}

	// if(drawer) {
		return (
			<div>{ currentDrawer() }</div>
		);
	// } else {
	// 	return null;
	// }

	// return (
	// 	<>
	// 	<Drawer 
	// 	anchor='right'
	// 	classes={{ paper: classes.drawerContainer}}
	// 	onClose={() => setOpenDrawer(false)} 
	// 	open={openDrawer}
	// 	onOpen={() => setOpenDrawer(true)}>
				
	// 			<List>
	// 			{/* <h1>{this.props.name}</h1> */}
	// 				<Tabs value={selectedTab} onChange={handleChange}>
						
	// 					<Tab label="Details" />
	// 					<Tab label="Translation" />
	// 					<Tab label="Statistics" />

	// 				</Tabs>
	// 			</List>
	// 	</Drawer>
	// 		{/* <Button
	// 		className={classes.MoreVertIcon}
	// 		onClick={() => setOpenDrawer(!openDrawer)}
	// 		disableRipple>
	// 		{/* <MenuIcon className={classes.MoreVertIcon} />
	// 	</Button> */}
	// 	</>
		
	// );

}
export default DetailsDrawer;
