import { Button, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import AuthContext from '../../store/AuthContext';
import DrawerContext from '../../store/DrawerContext';
import { useNavigate } from "react-router-dom";

const CreateTopicDrawer = () => {

  const authCtx   = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const navigate  = useNavigate();

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [createTopicDrawerObj, setCreateTopicDrawerObj] = useState({ title: "" });

  // console.log("createTopicDrawerObj", createTopicDrawerObj);

  const submitCreateTopic = () => {
    
    if(authCtx.isLoggedIn) {

    }
    const { title } = createTopicDrawerObj;
		fetch(process.env.REACT_APP_API_URI + '/v1/admin/topics', {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
			body: JSON.stringify({
				title,
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			if(data.status == "success") {
				// navigate("/devotionals", { replace: true });
        drawerCtx.closeDrawer();
			} else {
        console.error("Error while creating topic...");
				// setSnackbarMessage(data.message);
				// setOpenSnackbar(true);
			}
		}).catch((err) => console.error("Error while creating topic...", err));
  }


  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Create Topic Drawer</h1>
        </header>

        <section>

          {/* <div className="double-column-form"> */}
          {/* <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required /> */}
          <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={createTopicDrawerObj.title} onChange={(e) => setCreateTopicDrawerObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          {/* </div> */}

          <div className="double-column-form aligned-right margin-top">

            <Button variant="contained" component="label" disabled={false} 
              onClick={(e) => submitCreateTopic()}>Create Topic
            </Button>
          </div>

        </section>

      </div>
    </div>
  )
}



export default CreateTopicDrawer;
