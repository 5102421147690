import React, { useContext, useState, useEffect } from 'react';
import { Alert, Button, FormControl, InputLabel, Menu, MenuItem, Select, Snackbar } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext.js';

import 'react-quill/dist/quill.snow.css';
import { Add, CloseRounded, KeyboardArrowDownRounded } from '@mui/icons-material';

const EditTopicsModal = ({ modalDetails }) => {


  // console.log("modalDetails", modalDetails);

  //context
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [topic, setTopic] = useState('');
  const [searchTopic, setSearchTopic] = useState('')
  const [addTopicToDevo, setAddTopicToDevo] = useState('')

  const [showTopicResults, setShowTopicResults] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)


  //effect
  useEffect(() => {
    getAllTopics()
    setSelectedTopics(modalDetails.topics)
  }, [])

  //I had to do this effect because the state doesn't mutate immediately
  useEffect(() => {
    addNewlyCreatedTopic(addTopicToDevo)
  }, [topics, addTopicToDevo])

  //change visibility and get all topics when searchTopic is changed
  useEffect(() => {
    if (searchTopic.length > 0) {
      setShowTopicResults(true)
    } else {
      setShowTopicResults(false)
    }
    getAllTopics()
  }, [searchTopic])


  // submit topic to db
  const submitEditTopic = (id) => {

    if (authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + '/v1/admin/devotionals/topics', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({
          topicId: id,
          id: modalDetails.id
        })
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status == "success") {
        } else {
          console.error("Error while editing devotional...");
        }
      }).catch((err) => console.error("Error while editing devotional...", err));
    }

  }

  // get all topics
  const getAllTopics = (title) => {
    console.log(title)

    fetch(process.env.REACT_APP_API_URI + '/v1/topics', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          setTopics(data?.data)
          setAddTopicToDevo(title)
        }
      }).catch(err => console.error(err));

  }

  //deleting topic
  const DeleteTopic = async (devoId, id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/devotionals/${devoId}/topics/${id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const topicsResponse = await response.json();
      if (topicsResponse.status == 'success') {
      }
    } catch (err) {
      console.log(err);
    }
  }

  // create new topic
  const submitCreateTopic = async (title) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/topics/`,
        {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({
            title,
          })
        })

      const topicsResponse = await response.json();
      if (topicsResponse.status == 'success') {
        getAllTopics(title)
      }
    } catch (err) {
      console.log(err);
    }
  }

  //this function will add the newly created topic to the devotional without selecting it twice
  const addNewlyCreatedTopic = (title) => {
    for (let i = 0; i < topics.length; i++) {
      if (topics[i].title == title) {
        submitEditTopic(topics[i].id)
        setSelectedTopics(prev => [...prev, topics[i]]);
        setAddTopicToDevo('')
        setSearchTopic('')
      }
    }
  }

  //check if topic exist
  const handleCreateNewTopic = (title) => {
    if (topics.some(topic => topic.title == title)) {
      setOpenSnackbar(true)
      return;
    } else {
      submitCreateTopic(title)
    }
  }

  //add selected topic to db function and array
  const handleSelectTopic = (topic) => {
    if (!selectedTopics.some(el => el.id === topic.id)) {
      submitEditTopic(topic.id)
      setSelectedTopics(prev => [...prev, topic]);
    } else {
      return
    }
  }

  //filter and delete topic from db and state
  const handleFilterArray = (devoId, id) => {
    DeleteTopic(devoId, id)
    const newArr = selectedTopics.filter((arr) => {
      return arr.id !== id
    })
    setSelectedTopics(newArr)
  }

  //set search topic string
  const handleSearchTopic = (e) => {
    setSearchTopic(e.target.value)
  }

  //handling topic visibility
  const handleShowTopicResult = () => {
    if (showTopicResults) {
      setShowTopicResults(false)
    } else {
      setShowTopicResults(true)
    }
  }

  return (
    <>
      <div className="edit-topics-container">
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert severity="info" sx={{ width: '100%' }}>
            Topic already exist.
          </Alert>
        </Snackbar>
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Edit Devotional Topics</h1>
        </header>

        <section>

          {/* <FormControl fullWidth disableEnforceFocus>
            <InputLabel id="select-author">Topic</InputLabel>
            <Select
              labelId="select-author"
              value={topic}
              label="Topics"
              onChange={handleChangeTopic}
              className='select-author'
              disableEnforceFocus
            >
              {
                topics?.map((topic, i) => {
                  return <MenuItem key={i} disableEnforceFocus onClick={() => handleSelectTopic(topic)} value={topic.title}>{topic.title}</MenuItem>
                })
              }

            </Select>
          </FormControl> */}

          <div className="search-topic-container">
            <input type="text" placeholder='Search for topic...' value={searchTopic} onChange={handleSearchTopic} />
            <KeyboardArrowDownRounded className='drop-down-btn' onClick={() => handleShowTopicResult()} style={showTopicResults ? { transform: 'rotateZ(180deg)' } : null} />
          </div>

          <div className="topics-menu" style={showTopicResults ? null : { display: 'none' }} >
            <ul>
              {
                topics?.filter((topic) => topic.title.toLowerCase().includes(searchTopic.toLowerCase())).map((topic, i) => {
                  return <li key={i} onClick={() => handleSelectTopic(topic)}>{topic.title}</li>
                })
              }
              {searchTopic.length == 0 ? null : <li className='add-new-topic' onClick={() => handleCreateNewTopic(searchTopic)}><Add /> &nbsp; Add new topic &nbsp; <span className='topic-text'>"{searchTopic}"</span></li>}
            </ul>
          </div>

          <div className="author-list">
            {
              selectedTopics?.map((topic, i) => (
                <span key={i} className='author-tag'>{topic.title}<CloseRounded className='remove-author' onClick={() => handleFilterArray(modalDetails.id, topic.id)} /></span>
              ))
            }
          </div>
          <div className="save-changes-btn">
            <Button variant="contained" component="label" disabled={false}
              onClick={(e) => modalCtx.closeModal()}>Save Changes
            </Button>
            <Button className='close-btn' variant="contained" component="label" disabled={false}
              onClick={(e) => modalCtx.closeModal()}>Close
            </Button>
          </div>
        </section>
      </div>
    </>
  )
}

export default EditTopicsModal;
