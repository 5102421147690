import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';

import GetImage from '../Components/GetImage'

import AuthContext from '../store/AuthContext.js';
import ModalContext from '../store/ModalContext.js';
import DrawerContext from '../store/DrawerContext.js';


import editButton from '../assets/img/blog/edit.svg';
import deleteButton from '../assets/img/blog/delete.svg';

import { Avatar, Button, Card, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { CalendarMonth, CloseRounded, Delete, Edit } from '@mui/icons-material';

const Authors = () => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const navigate = useNavigate();

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [authors, setAuthors] = useState([])

  useEffect(() => {
    getAllDevotional();
  }, []);

  const handleModal = (modal) => {
    console.log('modal: ' + modal)
    console.log(modalCtx)
    modalCtx.openModal();
    modalCtx.setDetails(modal, null);
  }

  const getAllDevotional = () => {

    fetch(process.env.REACT_APP_API_URI + '/v1/authors', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        // console.log(response);
        return response.json();
      }).then(data => {
        console.log('Success:', data);
        if (data.status == 'success') {
          setAuthors(data?.data)
        } else {
          if (data?.code == 'auth/argument-error') {
            navigate(`/login?destination=authors`)
            authCtx.logout()
          }
        }
      }).catch(err => console.error(err));

  }

  const openDeleteDevotionalModal = (id) => {
    modalCtx.setDetails('confirm-delete-author', { id });
    modalCtx.openModal();
  }

  return (
    <div className='container'>

      <header>
        <h1>Authors</h1>
        <div className="create-devotional">
          {/* <Button className='create-devotional-btn' onClick={() => handleModal('createDevotional')} >+ Create New</Button> */}
          <Button className='header-cta' onClick={() => toggleDrawerHandler('create-author', null)}>+ Create New</Button>
        </div>
      </header>

      <div className='flex-table'>


        {authors?.map((author, i) => (

          <Card className='author-card' key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

            {
              author?.avatar == ''
                ? <Avatar className='avatar' alt="Remy Sharp">{author.full_name[0]}</Avatar>
                : <GetImage imageRef={author.avatar} />
            }


            <span className='name'>{author.full_name}</span>
            <span className='des'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr invidunt.</span>
            <span className='email'>{author.email}</span>


            <div>
              <img className='back-button action-icon' onClick={() => toggleDrawerHandler('edit-author', author)} src={editButton} alt='' />
              <img className='back-button action-icon' onClick={() => openDeleteDevotionalModal(author.id)} src={deleteButton} alt='' />

            </div>

          </Card>

        ))}
      </div>
    </div>
  )
}

export default Authors;
