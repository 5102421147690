import React, { useContext, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import ModalContext from '../../store/ModalContext';
import homepage from '../../assets/img/advertising/Home Page.png';
import AuthContext from '../../store/AuthContext';

export default function AdvertisingHomePage() {
  const modalCtx = useContext(ModalContext);
  const [advertisementData, setAdvertisementData] = useState({});
  const authCtx = useContext(AuthContext);
  const [togglehover, settogglehover] = useState(false)

  useEffect(() => {
    fetchAdvertisementData();
  }, []);

  useEffect(() => {
    fetchAdvertisementData();
  }, [modalCtx]);

  const fetchAdvertisementData = async () => {
    try {
      const response = await fetch('https://api.kingdomempowered.com/v1/advertisement/slot/page/1', {
        headers: {
          Authorization: `Bearer ${authCtx.token}`
        }
      });
      const data = await response.json();
      if (data.status === 'success' && data.data.length > 0) {
        setAdvertisementData(data.data[0]);
      }
    } catch (error) {
      console.error('Error fetching advertisement data:', error);
    }
  };

  const openUploadImageModal = (id) => {
    modalCtx.setDetails('upload-image', { aspectRatio: switchAspectRatio(id), origin: 'advertisingHome', id });
    modalCtx.setFixedsize(true);

    modalCtx.openModal();
  };

  const switchAspectRatio = (id) => {
    if (id === 1) {
      return 6.7
    } else if (id === 2) {
      return 4.1
    } else if (id === 3) {
      return 7.2
    }
  }

  const renderAdvertisementSlot = (id, link) => {
    if (link) {
      return (
        <div
          className={`advertising-home-img-cropper${id}`}
          style={{ cursor: 'pointer' }}
          onClick={() => openUploadImageModal(id)}
          onMouseEnter={() => {
            settogglehover(true)
          }}

          onMouseLeave={() => {
            settogglehover(false)
          }}
        >
          <img src={link} alt={`Slot ${id}`} />
          <div className="advertisement-text-icon-container-with-img" style={{
            display: togglehover ? 'flex' : 'none',
          }}>
            <Add />
            <h3 className="add-advertisement-text">Add Advertisement</h3>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={`advertising-home-img-cropper${id}`}
          style={{ cursor: 'pointer' }}
          onClick={() => openUploadImageModal(id)}
        >
          <div className="advertisement-text-icon-container">
            <Add />
            <h3 className="add-advertisement-text">Add Advertisement</h3>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="advertising-img-container advertising-home-container">
      <img src={homepage} alt="" srcSet="" className='main-img' />
      {renderAdvertisementSlot(1, advertisementData.slot1Link)}
      {renderAdvertisementSlot(2, advertisementData.slot2Link)}
      {renderAdvertisementSlot(3, advertisementData.slot3Link)}
    </div>
  );
}