import { useContext } from 'react';
import { Button } from '@mui/material';
import AuthContext from '../../store/AuthContext';
import ModalContext from '../../store/ModalContext';

const DeleteTopicModal = ({ modalDetails }) => {

  // console.log("delete modal props", modalDetails);
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }

  const deleteTopic = (id) => {
		if(authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + '/v1/admin/topics/' + id + '/delete', {
        method: 'DELETE',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
        // body: JSON.stringify({ id })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if(data.status == "success") {
          modalCtx.closeModal();
        } else {
          console.error("Error while deleting topic...");
        }
      }).catch((err) => {
        console.error("Error while deleting topic...", err);
        handleCloseModal();
      });
			console.log("delete topic id", id);
			// console.log(e.target);
		}
	}

  return (
    <div className="modal">
      
      <p>Are you sure you want to delete this Topic?</p>
      
      <div className="footer">
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>No</Button>
        <Button className="confirm-btn" onClick={() => deleteTopic(modalDetails.id)}>Yes</Button>
      </div>
    </div>
  )
}


export default DeleteTopicModal;
