import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ModalContext from '../../../store/ModalContext';
import DrawerContext from '../../../store/DrawerContext';
import AuthContext from '../../../store/AuthContext';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import { CloseRounded } from '@mui/icons-material';
import Dropzone from './Dropzone';
import { Delete, Edit, FileUploadRounded } from '@mui/icons-material';
import { CircularProgress, LinearProgress } from '@mui/material';
import { uploadBytesResumable, ref as sRef, getDownloadURL } from "../../FirebaseConfig";
import storage from "../../FirebaseConfig";

import done from "../../../assets/img/modal/done.svg"
import moment from 'moment/moment';

const UploadVideoModal = (props) => {
  const [isUploading, setIsUploading] = useState(false);
  const modalDetails = props.modalDetails.id;

  // const db = database;
  const ref = useRef()

  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);


  const [video, setVideo] = useState();
  const [videoDuration, setVideoDuration] = useState();
  const [percent, setPercent] = useState();

  useEffect(() => {

    validateFile(video)

  }, [video])

  function validateFile(file) {
    if (file) {
      console.log('first')
      var video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        setVideoDuration(Math.floor(video?.duration))
      }

      video.src = URL.createObjectURL(file);
    }
  }
  console.log(videoDuration)
  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setVideo(file);
        const storageRef = sRef(storage, `/episodes/${file.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(percent);
            // update progress
            setPercent(percent);

          },
          (err) => console.log(err),
          () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              console.log("this is url", url);
            });
          }
        );

      };

      reader.readAsDataURL(file);
      return file;
    });
  }, []);


  const saveVideo = () => {
    let coverRef = `/episodes/${video.name}`;

    fetch(process.env.REACT_APP_API_URI + '/v1/admin/courses/' + modalDetails.courseId + '/episodes/' + modalDetails.id + '/updateVideo', {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify({
        refstring: coverRef
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status == "success") {
        saveVideoTime()
      } else {
        console.error("Error while uploading image...");
      }
    }).catch((err) => console.error("Error while uploading image...", err));


  }

  const saveVideoTime = () => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/admin/courses/${modalDetails.courseId}/episodes/${modalDetails.id}/updateTimeframe`, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify({
        "timeframe": videoDuration ? videoDuration : 0
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status == "success") {
        setIsUploading(false)
        modalCtx.closeModal();
      } else {
        console.error("Error while updating the time of the video...");
      }
    }).catch((err) => console.error("Error while updating the time of the video...", err));

  }

  const removeVideo = () => {
    setVideo(null);
  }

  console.log(video)

  return (
    <>
      <div className='upload-video-modal'>
        <div className='head' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2> Upload Video</h2>
          <CloseRounded onClick={() => modalCtx.closeModal()}></CloseRounded>
        </div>
        <Dropzone onDrop={onDrop} accept={"video/*"} />

        {
          video ?
            <>
              <div className='upload-card'>
                <div style={{ display: 'flex', flexDirection: 'column', width: '75%', paddingBottom: '10px' }}>
                  <span style={{ maxWidth: '100%', fontSize: '16px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {video ? video?.name : '-'}
                  </span>
                  {/* <span style={{ fontSize: '14px' }}>800KB / 10MB</span> */}
                  <LinearProgress variant="determinate" value={percent} style={{ marginTop: '8px' }} />
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{`${percent}%`}</span>

                  {percent == 100 ? <img src={done} /> : <CircularProgress variant="determinate" value={percent} size={32} />}

                  <CloseRounded style={{ color: '#C6C6C6' }} onClick={removeVideo} />
                </div>
              </div>

              <div style={{ display: 'flex', width: '100%', gap: '20px', marginTop: '20px' }}>
                <Button variant='outlined' fullWidth onClick={() => modalCtx.closeModal()}>Cancel</Button>
                <Button variant='contained' fullWidth onClick={video ? saveVideo : null}>Save Video</Button>
              </div>

            </>
            :
            null
        }

      </div>
    </>
  )
}

export default UploadVideoModal;