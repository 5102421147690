import { Button, TextField, Switch, FormGroup, FormControlLabel } from '@mui/material';
import React, { useContext, useState } from 'react'
import AuthContext from '../../../store/AuthContext';
import DrawerContext from '../../../store/DrawerContext';
import ModalContext from '../../../store/ModalContext';
import { useNavigate } from "react-router-dom";

//assets
import placeholder from '../../../assets/img/placeholder.png';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateBlogDrawer = () => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const navigate = useNavigate();

  // function toggleDrawerHandler(drawer, user) {
  //   drawerCtx.openDrawer();
  //   drawerCtx.setDetails(drawer, user);
  // };

  const [createBlogObj, setCreateBlogObj] = useState({ snippet: "", title: "", post: "", pageTitle: "", metaDescription: "", metaKeywords: "", isVideo: "no", videoUrl: "" });

  const submitCreateBlog = () => {

    if (authCtx.isLoggedIn) {

    }
    const { snippet, title, post, pageTitle, metaDescription, metaKeywords, isVideo, videoUrl } = createBlogObj;
    fetch(process.env.REACT_APP_API_URI + '/v1/admin/blogs', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify({
        snippet,
        title,
        post,
        pageTitle,
        metaDescription,
        metaKeywords,
        isVideo,
        videoUrl
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status == "success") {
        getBlogById(data.blogId)
        console.log(data)

      } else {
        console.error("Error while creating blog...");
      }
    }).catch((err) => console.error("Error while creating blog...", err));
  }

  const getBlogById = (id) => {

    fetch(process.env.REACT_APP_API_URI + `/v1/blogs/${id}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          console.log('data from the get blog by id res', data)
          openUploadImageModal(id, data.data)
          drawerCtx.setDetails(null, data.data);
          drawerCtx.closeDrawer();
        }
      }).catch(err => {
        console.error(err)
      });

  }

  // const getBlogById = () => {
  // openUploadImageModal(BlogId, blog)
  // drawerCtx.setDetails(null, blog);
  // drawerCtx.closeDrawer();
  // }

  const handleVideoBoolean = (e) => {
    if (e) {
      setCreateBlogObj((prev) => ({ ...prev, isVideo: 'yes' }))
    } else {
      setCreateBlogObj((prev) => ({ ...prev, isVideo: 'no' }))
    }
  }

  const openUploadImageModal = (id, blog) => {
    modalCtx.setDetails('upload-image', { aspectRatio: undefined, origin: 'blog', id: id, newBlog: true, blog: blog });
    modalCtx.openModal();
  }


  return (
    <div className="container">
      <div className="drawer">
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Create Blog</h1>
        </header>

        <section>
          <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={createBlogObj.title} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          <TextField style={{ margin: "10px auto 15px" }} name="pageTitle" label="Page Title" value={createBlogObj.pageTitle} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Page Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          <TextField style={{ margin: "10px auto 15px" }} name="metaDescription" label="Meta Description" value={createBlogObj.metaDescription} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Meta Description" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          <TextField style={{ margin: "10px auto 15px" }} name="metaKeywords" label="Meta Keywords" value={createBlogObj.metaKeywords} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Meta Keywords" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          <FormGroup>
            <FormControlLabel label="Video" control={<Switch />} onChange={e => handleVideoBoolean(e.target.checked)} />
          </FormGroup>
          <TextField disabled={createBlogObj.isVideo !== 'yes'} style={{ margin: "10px auto 15px" }} name="videoUrl" label="Video Url" value={createBlogObj.videoUrl} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Video Url" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />

          {/* <div className='edit-blog-post'>Edit Your Blog Snippet Below</div>
          <ReactQuill className="blog-snippet-quill" theme="snow" name="snippet" value={createBlogObj.snippet} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, snippet: e }))} />

          <div className='edit-blog-post'>Edit Your Blog Post Below</div>
          <ReactQuill defaultValue='Blog Post' theme="snow" name="post" value={createBlogObj.post} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, post: e }))} /> */}

          <div className="double-column-form aligned-right margin-top">
            <Button variant="contained" component="label" disabled={false}
              onClick={(e) => submitCreateBlog()}>Create Blog
            </Button>
          </div>
        </section>
      </div>
    </div>
  )
}



export default CreateBlogDrawer;
