import React, { useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';

import { Equalizer } from '@mui/icons-material';

import Logo from '../assets/img/ke-logo.png';

import AuthContext from '../store/AuthContext.js';
import ModalContext from '../store/ModalContext.js';

import Administrators from '../assets/img/sidebar/administrators.svg'
import AdvertisingIcon from '../assets/img/sidebar/advertising.svg'
import Authors from '../assets/img/sidebar/authors.svg'
import BlogBw from '../assets/img/sidebar/blogs_bw.svg'
import CourseBw from '../assets/img/sidebar/courses_bw.svg'
import Creators from '../assets/img/sidebar/creators.svg'
import DevotionalBw from '../assets/img/sidebar/devotionals_bw.svg'
import Featured from '../assets/img/sidebar/featured.svg'
import Logout from '../assets/img/sidebar/logout.svg'
import Overview from '../assets/img/sidebar/overviews.svg'
import Payment from '../assets/img/sidebar/payments.svg'
import Reviews from '../assets/img/sidebar/reviews.svg'
import Tags from '../assets/img/sidebar/tags.svg'
import Topics from '../assets/img/sidebar/topics.svg'
import Users from '../assets/img/sidebar/users.svg'

import '../styles/App.scss';
import { Button } from '@mui/material';

const noSideBarRoutes = ["/login", "/register"];

function SideBar() {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const { pathname } = useLocation();

  const isLoggedIn = authCtx.isLoggedIn;

  const handleModal = (modal, data) => {
    modalCtx.setDetails(modal, data)
    modalCtx.openModal()
  }

  if (noSideBarRoutes.some((item) => pathname.includes(item))) return null;

  return (
    <div className="menu">
      <div className="company-logo"><img src={Logo} /></div>
      <nav>
        {!isLoggedIn && (<Link to="/login">
          <div className="sidebar-item">
            <Equalizer />
            <span>Login</span>
          </div>
        </Link>)}

        {isLoggedIn && (<Link to="/overview">
          <div className="sidebar-item">
            <img src={Overview} alt="Overview" />
            <span>Overview</span>
          </div>
        </Link>)}

        {isLoggedIn && (<Link to="/featured">
          <div className="sidebar-item">
            <img src={Featured} alt="Featured" />
            <span>Featured</span>
          </div>
        </Link>)}

        {isLoggedIn && (<Link to="/blogs">
          <div className="sidebar-item">
            <img src={BlogBw} alt="BlogBw" />
            <span>Blogs</span>
          </div>
        </Link>)}

        {isLoggedIn && (<Link to="/courses">
          <div className="sidebar-item">
            <img src={CourseBw} alt="CourseBw" />
            <span>Courses</span>
          </div>
        </Link>)}

        {isLoggedIn && (<Link to="/devotionals">
          <div className="sidebar-item">
            <img src={DevotionalBw} alt="DevotionalBw" />
            <span>Devotionals</span>
          </div>
        </Link>)}

        {isLoggedIn && (<Link to="/books">
          <div className="sidebar-item">
            <img src={DevotionalBw} alt="Payment" />
            <span>Books</span>
          </div>
        </Link>)}

        {isLoggedIn && (<Link to="/users">
          <div className="sidebar-item">
            <img src={Users} alt="Users" />
            <span>Users</span>
          </div>
        </Link>)}

        {isLoggedIn && (<Link to="/payments">
          <div className="sidebar-item">
            <img src={Payment} alt="Payment" />
            <span>Payments</span>
          </div>
        </Link>)}

        {isLoggedIn && (<Link to="/authors">
          <div className="sidebar-item">
            <img src={Authors} alt="Authors" />
            <span>Authors</span>
          </div>
        </Link>)}

        {isLoggedIn && (<Link to="/topics">
          <div className="sidebar-item">
            <img src={Topics} alt="Topics" />
            <span>Topics</span>
          </div>
        </Link>)}

        {isLoggedIn && (<Link to="/tags">
          <div className="sidebar-item">
            <img src={Tags} alt="Tags" />
            <span>Tags</span>
          </div>
        </Link>)}

        {isLoggedIn && (<Link to="/reviews">
          <div className="sidebar-item">
            <img src={Reviews} alt="Reviews" />
            <span>Reviews</span>
          </div>
        </Link>)}

        {isLoggedIn && (<Link to="/advertising">
          <div className="sidebar-item">
            <img src={AdvertisingIcon} alt="Advertising" />
            <span>Advertising</span>
          </div>
        </Link>)}

        {isLoggedIn && (<Link to="/administrators">
          <div className="sidebar-item">
            <img src={Administrators} alt="Administrators" />
            <span>Administrators</span>
          </div>
        </Link>)}

        {isLoggedIn && (<a onClick={() => authCtx.logout()}>
          <div className="sidebar-item">
            <img src={Logout} alt="Logout" />
            <span>Logout</span>
          </div>
        </a>)}

      </nav>
      <div className="site-build-btn-container">
        <Button onClick={() => handleModal('build-site', null)} className='site-build-btn' >Build & submit</Button>
      </div>
    </div>
  );
}
export default SideBar;