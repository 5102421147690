import React, { useContext, useState } from 'react';
import { Button, TextField } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import DrawerContext from '../../../store/DrawerContext';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditDayDrawer = () => {

  
  const authCtx   = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  
  console.log("drawerDetails", drawerCtx.details);
  const [createDevotionalObj, setCreateDevotionalObj] = useState({ 
    title: drawerCtx.details.title || "", 
    days_no: drawerCtx.details.days_no || "", 
    text: drawerCtx.details.text || "" });
    
    console.log(createDevotionalObj);

    const submitEditDevotional = () => {
    
      if(authCtx.isLoggedIn) {
        fetch(process.env.REACT_APP_API_URI + '/v1/admin/devotionals/day/' + drawerCtx.details.id + '/update', {
          method: 'PUT',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
          body: JSON.stringify({
            day: createDevotionalObj.days_no,
            title: createDevotionalObj.title,
            text: createDevotionalObj.text,
          })
        }).then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if(data.status == "success") {
            // navigate("/devotionals", { replace: true });
            drawerCtx.closeDrawer();
            window.location.reload();
          } else {
            console.error("Error while editing devotional...");
            // setSnackbarMessage(data.message);
            // setOpenSnackbar(true);
          }
        }).catch((err) => console.error("Error while editing devotional...", err));
      }
    }

  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Edit Day</h1>
        </header>

        <section>

          {/* <div className="double-column-form"> */}
          <TextField style={{ margin: "10px auto 15px" }} name="days_no" label="Day" value={createDevotionalObj.days_no} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Day" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={createDevotionalObj.title} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          {/* <TextField style={{ margin: "10px auto 15px" }} name="author" label="Author" value={createDevotionalObj.author} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Author" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required /> */}
          {/* <TextField style={{ margin: "10px auto 15px" }} name="description" label="Description" value={createDevotionalObj.description} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" multiline rows={4} InputLabelProps={{ shrink: true }} fullWidth required /> */}
          <ReactQuill theme="snow" name="description" value={createDevotionalObj.text} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, text: e }))} />
          {/* </div> */}

          <div className="double-column-form aligned-right margin-top">

            <Button variant="contained" component="label" disabled={false} 
              onClick={(e) => submitEditDevotional()}>Save Changes
            </Button>
          </div>

        </section>

      </div>
    </div>
  );
}

export default EditDayDrawer;

