import React, { useContext, useState, useEffect } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import DrawerContext from '../../../store/DrawerContext';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CloseRounded } from '@mui/icons-material';

const EditDevotionalAuthorDrawer = ({ drawerDetails }) => {

    // console.log("drawerDetails", drawerDetails);

    //context
    const authCtx   = useContext(AuthContext);
    const drawerCtx = useContext(DrawerContext);

    const [authors, setAuthors] = useState([]);
    const [selectedAuthors, setSelectedAuthors] = useState([]);
    const [author, setAuthor] = useState('');

  //effect
    useEffect(() => {
      setSelectedAuthors(drawerDetails.author)
      getAllAuthors()
    }, [])

    //functions
    const submitEditAuthor = (id) => {
    
      if(authCtx.isLoggedIn) {
        fetch(process.env.REACT_APP_API_URI + '/v1/admin/devotionals/author', {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({
            id: drawerDetails.id,
            authorId: id
          })
        }).then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if(data.status == "success") {
            // navigate("/devotionals", { replace: true });
            // drawerCtx.closeDrawer();
            // window.location.reload();
          } else {
            console.error("Error while editing devotional...");
            // setSnackbarMessage(data.message);
            // setOpenSnackbar(true);
          }
        }).catch((err) => console.error("Error while editing devotional...", err));
      }
    }

    const getAllAuthors = () => {

      fetch(process.env.REACT_APP_API_URI + '/v1/authors', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
      .then(response => {
        // console.log(response);
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          setAuthors(data?.data)
        }
      }).catch(err => console.error(err));
  
    }

    const DeleteAuthor = ( devoId, id) => {
      // console.log('/v1/devotionals/' + devoId + '/author/' + id)
  
      fetch(process.env.REACT_APP_API_URI + '/v1/devotionals/' + devoId + '/author/' + id, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
      .then(response => {
        // console.log(response);
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
        console.log(data)
        // window.location.reload()
        }
      }).catch(err => console.error(err));
    
      }

    const handleChangeAuthor = (event) => {
      setAuthor(event.target.value);
    }
    
    const handleSelectAuthor = (author) => {
      if(!selectedAuthors.some(el => el.id === author.id)){
        submitEditAuthor(author.id)
        setSelectedAuthors(prev => [...prev, author]);
      } else {
        return
      }
    }

    const handleFilterArray = (id) => {
      DeleteAuthor(drawerDetails.id, id)
      const newArr = selectedAuthors.filter((arr) => { 
        return arr.id !== id
       })
       setSelectedAuthors(newArr)
    }

    console.log(selectedAuthors)


  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Edit Devotional Author</h1>
        </header>

        <section>
          
          <FormControl fullWidth >
            <InputLabel id="select-author">Author</InputLabel>
            <Select
              labelId="select-author"
              value={author}
              label="Author"
              onChange={handleChangeAuthor}
              className='select-author'
              disableEnforceFocus
            >
              {
                authors?.map((author, i) => {
                  return <MenuItem key={i} onClick={() => handleSelectAuthor(author)} value={author.full_name}>{author.full_name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <div className="author-list">
            {
              selectedAuthors.map((author, i) => (
                <span key={i} className='author-tag'>{author.full_name}<CloseRounded className='remove-author' onClick={() => handleFilterArray(author.id)} /></span>
              ))
            }
          </div>
          <div className="double-column-form aligned-right margin-top">
            <Button variant="contained" component="label" disabled={false} 
              onClick={(e) => { drawerCtx.closeDrawer() }
                }>Save Changes
            </Button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default EditDevotionalAuthorDrawer;

