import { Button, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import AuthContext from '../../store/AuthContext';
import DrawerContext from '../../store/DrawerContext';
import { useNavigate } from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateAuthorDrawer = ({ drawerDetails }) => {

  const authCtx   = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const navigate  = useNavigate();

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };


  const [EditAuthorObj, setEditAuthorObj] = useState({ 
    first_name: drawerDetails.first_name || "", 
    last_name: drawerDetails.last_name || "", 
    full_name: drawerDetails.full_name || "", 
    email: drawerDetails.email || "", 
    avatar: drawerDetails.avatar || "" 
  });

  console.log("EditAuthorObj", EditAuthorObj);

  const submitCreateAuthor = () => {
    
    if(authCtx.isLoggedIn) {

    }
    const { first_name, last_name, full_name, email, avatar} = EditAuthorObj;

		fetch(process.env.REACT_APP_API_URI + '/v1/admin/authors/' + drawerDetails.id + '/update', {
			method: 'PUT',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
			body: JSON.stringify({
				first_name,
        last_name,
        full_name,
        email,
        avatar
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			if(data.status == "success") {
        drawerCtx.closeDrawer();
        window.location.reload();
			} else {
        console.error("Error while creating devotional...");
			}
		}).catch((err) => console.error("Error while creating devotional...", err));
  }


  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Edit Author</h1>
        </header>

        <section>

          <TextField 
          style={{ margin: "10px auto 15px" }} 
          name="first_name" 
          label="First Name" 
          value={EditAuthorObj.first_name} 
          onChange={(e) => setEditAuthorObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} 
          placeholder="First Name" 
          variant="outlined" 
          InputLabelProps={{ shrink: true }} 
          fullWidth 
          required />

          <TextField 
          style={{ margin: "10px auto 15px" }} 
          name="last_name" 
          label="Last Name"
          value={EditAuthorObj.last_name} 
          onChange={(e) => setEditAuthorObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} 
          placeholder="Last Name"
            variant="outlined" 
            InputLabelProps={{ shrink: true }} 
            fullWidth 
            required />

          <TextField 
          style={{ margin: "10px auto 15px" }} 
          name="full_name" 
          label="Full Name"
          value={EditAuthorObj.full_name} 
          onChange={(e) => setEditAuthorObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} 
          placeholder="Full Name"
            variant="outlined" 
            InputLabelProps={{ shrink: true }} 
            fullWidth 
            required />

          <TextField 
          style={{ margin: "10px auto 15px" }} 
          name="email" 
          label="Email" 
          value={EditAuthorObj.email} 
          onChange={(e) => setEditAuthorObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} 
          placeholder="Email" 
          variant="outlined" 
          InputLabelProps={{ shrink: true }} 
          fullWidth 
          required />

          <div className="double-column-form aligned-right margin-top">
            <Button variant="contained" component="label" disabled={false} onClick={(e) => submitCreateAuthor()}>Create Author</Button>
          </div>

        </section>

      </div>
    </div>
  )
}



export default CreateAuthorDrawer;
