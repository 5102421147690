import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';

const IOSAndroidData = () => {
  const totalValue = 200;

  const getSubtitle = () => {
    const iosValue = 130;
    const androidValue = 70;
  };

  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = Highcharts.chart('iosAndroidContainer', {
      chart: {
        type: 'pie',
      },
      title:{
        text:""
      },
      plotOptions: {
        pie: {
          innerSize: '80%',
          dataLabels: {
            enabled: false,
          },
          borderWidth: 0,
          gapSize: 0,
        },
      },
      colors: ['#2275B9', '#F2642C'],
      series: [
        {
          name: 'Usage',
          data: [
            { name: 'iOS', y: 130 },
            { name: 'Android', y: 70 },
          ],
        },
      ],
    });

    chartRef.current = chartInstance;
  }, []);

  const update = () => {
    const chart = chartRef.current;

    chart.series[0].setData([
      { name: 'iOS', y: 130 },
      { name: 'Android', y: 70 },
    ]);

    chart.setTitle(null, { text: getSubtitle() });
  };

  const handleInputChange = () => {
    update();
  };

  return (
    <div className="iosAndroidData">
      <div className="iosAndroidContainer-heading">No. of Android Users vs IOS Users</div>
      <div id="iosAndroidContainer" style={{width:"60%" , margin:"auto"}}/>
      <div className='users-count'>
        <div className="ios-users-count">
          <div className="users-icon">
            <div className="users-indicator"></div>
            <h4>iOS</h4>
          </div>
          <h4 className="no-of-users">130</h4>
        </div>
        <div className="android-users-count">
          <div className="users-icon">
            <div className="users-indicator"></div>
            <h4>Android</h4>
          </div>
          <h4 className="no-of-users">70</h4>
        </div>
      </div>
    </div>
  );
};

export default IOSAndroidData;