import { Button } from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";
import { Delete, Edit, FileUploadRounded } from '@mui/icons-material';

function Dropzone({ onDrop, accept, open }) {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept,
      onDrop,
    });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="v-d-n-d">
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} {...getRootProps({ className: "dropzone" })}>
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          {isDragActive ? (
            <p className="dropzone-content">
              Release to drop the files here
            </p>
          ) : (
            <p className="dropzone-content" style={{textAlign:'center'}}>
              Drag and Drop Video <br></br> Or
            </p>
          )}
          <Button type="button" onClick={open} className="btn"><FileUploadRounded/> Upload Video </Button>
        </div>
      </div>
    </div>
  );
}

export default Dropzone;