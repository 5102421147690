import { Button, Checkbox } from "@mui/material"

import Add from '../assets/img/add-to.svg'
import { useContext, useEffect, useState } from "react"

//context
import AuthContext from '../store/AuthContext'
import ModalContext from '../store/ModalContext'

import GetImage from '../Components/GetImage'

const Featured = () => {

  const authCtx = useContext(AuthContext)
  const modalCtx = useContext(ModalContext)

  const [blogs, setBlogs] = useState([])
  const [courses, setCourses] = useState([])
  const [devotionals, setDevotionals] = useState([])

  useEffect(() => {

    getFeaturedContent()

  }, [])

  useEffect(() => {

    getFeaturedContent()

  }, [modalCtx])


  const getFeaturedContent = () => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/featured`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    }).then(response => {
      return response.json();
    }).then(data => {
      // console.log('Success:', data);
      if (data.status === 'success') {
        const blogsData = data?.featured?.filter(array => array.entity == 'blog');
        const coursesData = data?.featured?.filter(array => array.entity == 'course');
        const devotionalsData = data?.featured?.filter(array => array.entity == 'devotional');
        console.log(data)
        setBlogs(blogsData)
        setCourses(coursesData)
        setDevotionals(devotionalsData)
      }
    }).catch(err => console.error(err));

  }

  const removeFeaturedContent = (id, entityType, entityId) => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/admin/featured/${id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify({
        "entityId": entityId,
        "entityType": entityType
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log('Success:', data);
      if (data.status === 'success') {
        getFeaturedContent()
      }
    }).catch(err => console.error(err));

  }

  const updateFeaturedContentTag = (id, tag) => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/admin/featured/${id}/tag`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify({
        "tag": tag,
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === 'success') {
        getFeaturedContent()
      }
    }).catch(err => console.error(err));

  }

  const updateFeaturedContentTitleBool = (id, boolean) => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/admin/featured/${id}/title`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify({
        "isTitle": boolean,
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === 'success') {
        getFeaturedContent()
      }
    }).catch(err => console.error(err));

  }

  const handleModal = (entity) => {
    modalCtx.setDetails('add-featured-entity', { entity: entity });
    modalCtx.openModal();
  }

  const addTrailingDots = (string, limit) => {
    if (!string) return null

    var dots = "...";
    if (string?.length > limit) {
      string = string?.substring(0, limit) + dots;
    }
    return string;
  }

  const validateArray = (arr, index) => {
    // if(arr?.length < 1) return false
    if (arr[index]) {
      return true
    } else {
      return false
    }
  }

  // console.log(validateArray(blogs, 0))
  // console.log(courses)

  return (
    <div className="featured-page-container" >
      <header>
        <h1>Featured</h1>
      </header>
      <div className="featured-page">
        <div className="featured-entities-container">
          <div className="featured-entities-header">
            <h3>Blogs</h3>
          </div>
          <div className="featured-entities">
            <div className="featured-entity">
              {
                validateArray(blogs, 0)
                  ? <>
                    <div className="image"><GetImage imageRef={blogs[0]?.image} /></div>
                    <div className="title">
                      <div className="title-tag-wrapper">
                        <h3 className="title-text">{addTrailingDots(blogs[0]?.title, 80)}</h3>
                        <div className="tag-input">
                          <span>Tag : </span>
                          <input defaultValue={blogs[0]?.tag} onChange={(e) => updateFeaturedContentTag(blogs[0]?.id, e.target.value)} type="text" placeholder="Enter A Tag..." />
                          {/* <Button className="submit-tag-btn" >Submit</Button> */}
                          <span className="include-title-input" >Include Title: </span>
                          <Checkbox onClick={() => updateFeaturedContentTitleBool(blogs[0]?.id, !blogs[0]?.isTitle)} defaultChecked={blogs[0]?.isTitle} />
                        </div>
                      </div>
                      <Button onClick={() => removeFeaturedContent(blogs[0]?.id, 'blog', blogs[0]?.id)} className="remove-btn">Remove</Button>
                    </div></>
                  : <div className="add-to-container" onClick={() => handleModal("blog")} >
                    <img src={Add} alt="add-to" />
                    <span>Add Blog</span>
                  </div>
              }
            </div>
            <div className="featured-entity">
              {
                validateArray(blogs, 1)
                  ? <>
                    <div className="image"><GetImage imageRef={blogs[1]?.image} /></div>
                    <div className="title">
                      <div className="title-tag-wrapper">
                        <h3 className="title-text">{addTrailingDots(blogs[1]?.title, 95)}</h3>
                        <div className="tag-input">
                          <span>Tag : </span>
                          <input defaultValue={blogs[1]?.tag} onChange={(e) => updateFeaturedContentTag(blogs[1]?.id, e.target.value)} type="text" placeholder="Enter A Tag..." />
                          {/* <Button className="submit-tag-btn" >Submit</Button> */}
                          <span className="include-title-input" >Include Title: </span>
                          <Checkbox onClick={() => updateFeaturedContentTitleBool(blogs[1]?.id, !blogs[1]?.isTitle)} defaultChecked={blogs[1]?.isTitle} />
                        </div>
                      </div>
                      <Button onClick={() => removeFeaturedContent(blogs[1]?.id, 'blog', blogs[1]?.id)} className="remove-btn">Remove</Button>
                    </div></>
                  : <div className="add-to-container" onClick={() => handleModal("blog")} >
                    <img src={Add} alt="add-to" />
                    <span>Add Blog</span>
                  </div>
              }
            </div>
          </div>
        </div>
        <div className="featured-entities-container">
          <div className="featured-entities-header">
            <h3>Courses</h3>
          </div>
          <div className="featured-entities">
            <div className="featured-entity">
              {
                validateArray(courses, 0)
                  ? <>
                    <div className="image"><GetImage imageRef={courses[0]?.image} /></div>
                    <div className="title">
                      <div className="title-tag-wrapper">
                        <h3 className="title-text">{addTrailingDots(courses[0]?.title, 95)}</h3>
                        <div className="tag-input">
                          <span>Tag : </span>
                          <input defaultValue={courses[0]?.tag} onChange={(e) => updateFeaturedContentTag(courses[0]?.id, e.target.value)} type="text" placeholder="Enter A Tag..." />
                          {/* <Button className="submit-tag-btn" >Submit</Button> */}
                          <span className="include-title-input" >Include Title: </span>
                          <Checkbox onClick={() => updateFeaturedContentTitleBool(courses[0]?.id, !courses[0]?.isTitle)} defaultChecked={courses[0]?.isTitle} />
                        </div>
                      </div>
                      <Button onClick={() => removeFeaturedContent(courses[0]?.id, 'course', courses[0]?.id)} className="remove-btn">Remove</Button>
                    </div></>
                  : <div className="add-to-container" onClick={() => handleModal("course")} >
                    <img src={Add} alt="add-to" />
                    <span>Add Course</span>
                  </div>
              }
            </div>
            <div className="featured-entity">
              {
                validateArray(courses, 1)
                  ? <>
                    <div className="image"><GetImage imageRef={courses[1]?.image} /></div>
                    <div className="title">
                      <div className="title-tag-wrapper">
                        <h3 className="title-text">{addTrailingDots(courses[1]?.title, 95)}</h3>
                        <div className="tag-input">
                          <span>Tag : </span>
                          <input defaultValue={courses[1]?.tag} onChange={(e) => updateFeaturedContentTag(courses[1]?.id, e.target.value)} type="text" placeholder="Enter A Tag..." />
                          {/* <Button className="submit-tag-btn" >Submit</Button> */}
                          <span className="include-title-input" >Include Title: </span>
                          <Checkbox onClick={() => updateFeaturedContentTitleBool(courses[1]?.id, !courses[1]?.isTitle)} defaultChecked={courses[1]?.isTitle} />
                        </div>
                      </div>
                      <Button onClick={() => removeFeaturedContent(courses[1]?.id, 'course', courses[1]?.id)} className="remove-btn">Remove</Button>
                    </div></>
                  : <div className="add-to-container" onClick={() => handleModal("course")} >
                    <img src={Add} alt="add-to" />
                    <span>Add Course</span>
                  </div>
              }
            </div>
          </div>
        </div>
        <div className="featured-entities-container">
          <div className="featured-entities-header">
            <h3>Devotionals</h3>
          </div>
          <div className="featured-entities">
            <div className="featured-entity">
              {
                validateArray(devotionals, 0)
                  ? <>
                    <div className="image"><GetImage imageRef={devotionals[0]?.image} /></div>
                    <div className="title">
                      <div className="title-tag-wrapper">
                        <h3 className="title-text">{addTrailingDots(devotionals[0]?.title, 95)}</h3>
                        <div className="tag-input">
                          <span>Tag : </span>
                          <input defaultValue={devotionals[0]?.tag} onChange={(e) => updateFeaturedContentTag(devotionals[0]?.id, e.target.value)} type="text" placeholder="Enter A Tag..." />
                          {/* <Button className="submit-tag-btn" >Submit</Button> */}
                          <span className="include-title-input" >Include Title: </span>
                          <Checkbox onClick={() => updateFeaturedContentTitleBool(devotionals[0]?.id, !devotionals[0]?.isTitle)} defaultChecked={devotionals[0]?.isTitle} />
                        </div>
                      </div>
                      <Button onClick={() => removeFeaturedContent(devotionals[0]?.id, 'devotional', devotionals[0]?.id)} className="remove-btn">Remove</Button>
                    </div></>
                  : <div className="add-to-container" onClick={() => handleModal("devotional")} >
                    <img src={Add} alt="add-to" />
                    <span>Add Devotional</span>
                  </div>
              }
            </div>
            <div className="featured-entity">
              {
                validateArray(devotionals, 1)
                  ? <>
                    <div className="image"><GetImage imageRef={devotionals[1]?.image} /></div>
                    <div className="title">
                      <div className="title-tag-wrapper">
                        <h3 className="title-text">{addTrailingDots(devotionals[1]?.title, 95)}</h3>
                        <div className="tag-input">
                          <span>Tag : </span>
                          <input defaultValue={devotionals[1]?.tag} onChange={(e) => updateFeaturedContentTag(devotionals[1]?.id, e.target.value)} type="text" placeholder="Enter A Tag..." />
                          {/* <Button className="submit-tag-btn" >Submit</Button> */}
                          <span className="include-title-input" >Include Title: </span>
                          <Checkbox onClick={() => updateFeaturedContentTitleBool(devotionals[1]?.id, !devotionals[1]?.isTitle)} defaultChecked={devotionals[1]?.isTitle} />
                        </div>
                      </div>
                      <Button onClick={() => removeFeaturedContent(devotionals[1]?.id, 'devotional', devotionals[1]?.id)} className="remove-btn">Remove</Button>
                    </div></>
                  : <div className="add-to-container" onClick={() => handleModal("devotional")} >
                    <img src={Add} alt="add-to" />
                    <span>Add Devotional</span>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Featured