import React, { useContext, useState, useEffect } from 'react';
import { Alert, Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, Menu, MenuItem, Select, Snackbar } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext.js';

import 'react-quill/dist/quill.snow.css';
import { Add, CheckBox, CloseRounded, KeyboardArrowDownRounded } from '@mui/icons-material';

const EditPermissionsModal = ({ modalDetails }) => {


  // console.log("modalDetails", modalDetails);

  //context
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const permissions = ['users', 'creators', 'payments', 'blogs', 'courses', 'devotionals', 'authors', 'topics', 'tags', 'reviews', 'administrators'];
  const [filteredPermissions, setFilteredPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [searchPermission, setSearchPermissions] = useState('');
  const [showPermissionsResults, setShowPermissionResults] = useState(false);
  const [savingPermissions, setSavingPermissions] = useState(false);

  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);

  const [admin, setAdmin] = useState();

  const [topic, setTopic] = useState('');
  const [searchTopic, setSearchTopic] = useState('')
  const [addTopicToDevo, setAddTopicToDevo] = useState('')

  const [showTopicResults, setShowTopicResults] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)



  useEffect(() => {
    if (searchPermission.length > 0) {
      setShowPermissionResults(true)
    } else {
      setShowPermissionResults(false)
    }
    // getAllTopics()
  }, [searchPermission])

  useEffect(() => {
    GetAllAdmins()
  }, [])

  useEffect(() => {
    if (objectToArray(admin?.permissions)?.length) {
      let arrToAdd = []
      for (let i = 0; i < objectToArray(admin?.permissions)?.length; i++) {
        arrToAdd.push({ permission: objectToArray(admin?.permissions)[i]?.permission, type: objectToArray(admin?.permissions)[i]?.value })
      }
      setSelectedPermissions(arrToAdd);
    }
  }, [admin])

  const submitPermissions = () => {

    if (authCtx.isLoggedIn) {
      const payload = {
        userId: modalDetails.id,
        permissions: []
      };

      for (var i = 0; i < selectedPermissions.length; i++) {
        payload.permissions.push({
          permission: selectedPermissions[i]?.permission,
          permission_type: selectedPermissions[i]?.type
        });
      }

      // objectToArray(admin?.permissions)?.map((item, i) => {

      //   for (var i = 0; i < selectedPermissions.length; i++) {
      //       payload.permissions.push({
      //         permission: selectedPermissions[i],
      //         permission_type: "read"
      //       });
      //     }

      //   if (objectToArray(admin?.permissions)?.find(el => el.permission == permission)) {
      //     payload.permissions.push({
      //       permission: permission,
      //       permission_type: type
      //     });
      //     console.log('first')
      //   } else if(!objectToArray(admin?.permissions)?.find(el => el.permission == permission)) {

      //     console.log('second')
      //   } else if (!objectToArray(admin?.permissions)?.find(el => el.permission == permission)){
      //     payload.permissions.push({
      //       permission: objectToArray(admin?.permissions)[i]?.permission,
      //       permission_type: objectToArray(admin?.permissions)[i]?.value
      //     });
      //     console.log('third')
      //   }

      // })

      // const payload = {
      //   userId: modalDetails.id,
      //   permissions: [{
      //     permission: permission,
      //     permission_type: type
      //   }]
      // };

      console.log('payload', payload)

      fetch(process.env.REACT_APP_API_URI + `/v1/users/admin/permission`, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify(payload)
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status == "success") {
          console.log(`added permissions`)
          GetAllAdmins()
          setSavingPermissions(false);
          return true;
        } else {
          console.error(`Error while adding permissions}`);
        }
      }).catch((err) => console.error("Error while adding  permissions...", err));

      setSavingPermissions(false);
    }
  }


  const getAllPermissions = (title) => {
    console.log(title)

    fetch(process.env.REACT_APP_API_URI + '/v1/topics', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          setTopics(...permissions)
          setAddTopicToDevo(title)
        }
      }).catch(err => console.error(err));

  }


  const GetAllAdmins = () => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/admin/administrators/admins`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          const found = data?.data.find(element => element.id == modalDetails.id);
          setAdmin(found)
        }
      }).catch(err => console.error(err));

  }

  //turns reply object to array
  const objectToArray = (obj) => {
    if (obj) {
      const arrayOfObj = Object.keys(obj).map(key => ({ "permission": key, "value": obj[key] }))
      // console.log(arrayOfObj)
      return arrayOfObj.reverse()
    }
  }

  //check is selected or not
  const checkPermissions = (permissions) => {

    const newArr = selectedPermissions.filter((arr) => {
      return arr == permissions
    });

    return (newArr.length == 0);

  }

  const handleSelectPermissions = (permission, type) => {

    console.log({ permission: permission, type: type })

    if (!objectToArray(admin?.permissions)?.length) {
      setSelectedPermissions(prev => [...prev, { permission: permission, type: type }]);
      submitPermissions()
    }

    if (selectedPermissions?.find(el => el.permission !== permission)) {
      console.log({ permission: permission, type: type })

      setSelectedPermissions(prev => [...prev, { permission: permission, type: type }]);
      submitPermissions()
    }
    if (selectedPermissions?.find(el => el.permission == permission)) {

      const objIndex = selectedPermissions.findIndex((obj => obj.permission == permission));

      let tempArray = selectedPermissions

      tempArray[objIndex].permission = permission
      tempArray[objIndex].type = type

      setSelectedPermissions(tempArray)
      submitPermissions()
    }

  }

  const checkPermissionsOfAdmin = (permission) => {

    let checked = ''

    for (let i = 0; i < selectedPermissions?.length; i++) {
      if (permission == selectedPermissions[i]?.permission) {
        checked = selectedPermissions[i]?.type
      }
    }

    return checked

  }

  //handle save button

  async function savePermissions() {

    setSavingPermissions(true);
    submitPermissions();
    setSavingPermissions(false);
    // modalCtx.closeModal();

  }

  //filter and delete topic from db and state
  const handleFilterArray = (userId, permission) => {
    // DeleteTopic(userId, permission)
    const newArr = selectedPermissions.filter((arr) => {
      return arr !== permission
    })
    setSelectedPermissions(newArr)
  }

  //set search topic string

  const handleSearchPermissions = (e) => {
    setSearchPermissions(e.target.value)
  }

  //handling topic visibility

  const handleShowPermissionsResult = () => {
    if (showPermissionsResults) {
      setShowPermissionResults(false)
    } else {
      setShowPermissionResults(true)
    }
  }
  // console.log('permissions', permissions)
  console.log('selectedPermissions', selectedPermissions)
  console.log('admin', admin)

  return (
    <>
      <div className="edit-topics-container">
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert severity="info" sx={{ width: '100%' }}>
            Permission already exist.
          </Alert>
        </Snackbar>
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Edit Administrator Permissions</h1>
        </header>

        {/* <section>

          <div className="search-topic-container">
            <input type="text" placeholder='Search for permission...' value={searchPermission} onChange={handleSearchPermissions} />
            <KeyboardArrowDownRounded className='drop-down-btn' onClick={() => handleShowPermissionsResult()} style={showPermissionsResults ? { transform: 'rotateZ(180deg)' } : null} />
          </div>

          <div className="topics-menu" style={showPermissionsResults ? null : { display: 'none' }} >
            <ul>
              {
                permissions?.filter((perm) => perm.toLowerCase().includes(searchPermission.toLowerCase())).map((perms, i) => {
                  return <li key={i} onClick={() => handleSelectPermissions(perms)}>{perms}</li>
                })
              }

            </ul>
          </div>

          <div className="author-list">
            { admin?.permissions ?
              objectToArray(admin?.permissions)?.map((perm, i) => (
                <div key={i} className='permission-row'>{perm.permission}<CloseRounded className='remove-author' onClick={() => handleFilterArray(modalDetails.id, perm)} /></div>
              ))
              : null
            }
          </div>
          <div className="save-changes-btn">
            {
              savingPermissions
                ? <Button variant="contained" component="label" disabled={false}> Saving.. </Button>
                : <Button variant="contained" component="label" disabled={false} onClick={(e) => savePermissions()}>Save Changes</Button>
            }
            <Button className='close-btn' variant="contained" component="label" disabled={false}
              onClick={(e) => modalCtx.closeModal()}>Close
            </Button>
          </div>
        </section> */}

        <section>
          <div className="permission-list">
            {
              permissions?.map((perm, i) => (
                <div key={i} className='permission-row'>
                  <span>{perm}</span>
                  <div className="checkbox-div">
                    {/* <FormControlLabel control={<Checkbox onClick={(e) => handleSelectPermissions(perm, 'read')} checked={checkPermissionsOfAdmin(perm, 'read')} />} label="Read" />
                    <FormControlLabel control={<Checkbox onClick={(e) => handleSelectPermissions(perm, 'both')} checked={checkPermissionsOfAdmin(perm, 'both')} />} label="Both" /> */}
                    {/* <FormControlLabel control={<Checkbox onClick={(e) => handleSelectPermissions( perm, 'none')} defaultChecked={checkPermissionsOfAdmin(perm, 'none')} />} label="None" /> */}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Age</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={checkPermissionsOfAdmin(perm)}
                        label="Age"
                        onChange={() => handleSelectPermissions(perm, 'read')}
                      >
                        <MenuItem value={'read'}>Read</MenuItem>
                        <MenuItem value={'both'}>Both</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="save-changes-btn">
            {
              savingPermissions
                ? <Button variant="contained" component="label" disabled={false}> Saving.. </Button>
                : <Button variant="contained" component="label" disabled={false} onClick={(e) => savePermissions()}>Save Changes</Button>
            }
            <Button className='close-btn' variant="contained" component="label" disabled={false}
              onClick={(e) => modalCtx.closeModal()}>Close
            </Button>
          </div>
        </section>
      </div>
    </>
  )
}

export default EditPermissionsModal;
