import { useContext, useState } from 'react';
import { Button, TextField } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext';

const EditBookModal = ({ modalDetails }) => {

  // console.log("delete modal props", modalDetails);
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }
  console.log(modalDetails.id.affiliate_code);
  const [createDevotionalObj, setCreateDevotionalObj] = useState({
    blog_id: modalDetails.id.id, title: modalDetails.id.title, affiliate_code: modalDetails.id.affiliate_code,

  });
  const updateBook = () => {
    if (authCtx.isLoggedIn) {
      const { blog_id, title, affiliate_code } = createDevotionalObj;
      fetch(process.env.REACT_APP_API_URI + '/v1/admin/books/' + blog_id, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
        body: JSON.stringify({ title, blog_id, affiliate_code })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status == "success") {
          // navigate("/devotionals", { replace: true });
          // drawerCtx.closeDrawer();
          modalCtx.closeModal();
          // window.location.reload();
        } else {
          console.error("Error while updating books...");
          // setSnackbarMessage(data.message);
          // setOpenSnackbar(true);
        }
      }).catch((err) => {
        console.error("Error while upadting books...", err);
        handleCloseModal();
      });
      console.log("update book id", blog_id);
    }
  }

  return (
    <div className="modal">
      <p>Edit Book Name</p>
      <div>
        <TextField style={{ margin: "10px auto 15px" }} name="title" label="Book Title" value={createDevotionalObj.title} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder=" Book Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
      </div>
      <div className="footer">
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>Cancel</Button>
        <Button className="confirm-btn" onClick={() => updateBook()}>Save</Button>
      </div>
    </div>
  )
}

export default EditBookModal;
