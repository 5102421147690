//react
import { useEffect, useState } from "react";

//mui
import { Button, CircularProgress, Paper, Rating, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Checkbox } from '@mui/material';

//assets
import deleteButton from '../assets/img/blog/delete.svg';
import Blogs from '../assets/img/reviews/blogs.svg'
import Courses from '../assets/img/reviews/courses.svg'
import Devotionals from '../assets/img/reviews/devotionals.svg'
import BlogsBw from '../assets/img/reviews/blogs-bw.svg'
import CoursesBw from '../assets/img/reviews/courses-bw.svg'
import DevotionalsBw from '../assets/img/reviews/devotionals-bw.svg'

//firebase
import { onValue, ref, remove } from 'firebase/database'
import { database } from '../Components/FirebaseConfig.jsx'

const Reviews = () => {

  //firebase database
  const db = database;

  //states
  const [courseReviews, setCourseReviews] = useState([]);
  const [blogReviews, setBlogReviews] = useState([]);
  const [devotionalReviews, setDevotionalReviews] = useState([]);
  const [allReviews, setAllReviews] = useState();
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [reviewFilter, setReviewFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  //boolean states
  const [selectedAll, setSelectedAll] = useState(false);
  const [deletingReviews, setDeletingReviews] = useState(false);


  //side effects
  useEffect(() => {
    addPostListener('course')
    addPostListener('devotional')
    addPostListener('blog')
  }, []);


  useEffect(() => {
    handleReviewsFilter()
  }, [courseReviews, blogReviews, devotionalReviews, reviewFilter]);


  useEffect(() => {
    if (selectedReviews?.length < allReviews?.length) {
      setSelectedAll(false)
    }
  }, [selectedReviews]);

  useEffect(() => {
    if (searchQuery.length <= 0) {
      handleReviewsFilter()
    } else {
      searchFilter(searchQuery)
    }
  }, [searchQuery]);


  //functions

  //search filter
  const searchFilter = (query) => {

    const filtered_users = allReviews?.filter(function (user) {
      user = user?.username?.toLowerCase();
      return user.indexOf(query) > -1;
    });
    setAllReviews(filtered_users)

  }

  //review filter
  const handleReviewsFilter = () => {

    if (reviewFilter === 'blog') {
      const arr = blogReviews
      setAllReviews(arr);
    } else if (reviewFilter === 'course') {
      const arr = courseReviews
      setAllReviews(arr);
    } else if (reviewFilter === 'devotional') {
      const arr = devotionalReviews
      setAllReviews(arr);
    } else {
      const arr = courseReviews.concat(blogReviews, devotionalReviews)
      setAllReviews(arr);
    }

  }

  //get posts from firebase
  const addPostListener = (entityType) => {
    const dbRef = ref(db, `${entityType}sReview`)
    onValue(dbRef, (snapshot) => {

      switch (entityType) {
        case 'course':
          setCourseReviews(replyObjectToArray(snapshotToArray(snapshot)))
          return;
        case 'blog':
          setBlogReviews(replyObjectToArray(snapshotToArray(snapshot)))
          return;
        case 'devotional':
          setDevotionalReviews(replyObjectToArray(snapshotToArray(snapshot)))
          return;

        default:
          return;
      }
    })
  }

  const snapshotToArray = (snapshot) => {
    const returnArr = []
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val()
      returnArr.push(item)
    })
    // console.log(returnArr)
    return returnArr
  }

  //return array from object
  const replyObjectToArray = (obj) => {
    if (obj) {
      const newArr = obj.map((item, index) => {
        return Object.keys(item).map(key => item[key])
      })
      const merge3 = newArr.flat(1);
      return merge3;
    }
  }


  const switchCategory = (entityType) => {
    switch (entityType) {
      case 'course':
        return <div className="course-category"  >
          <img src={Courses} alt="" />
          <span> Courses </span>
        </div>
      case 'blog':
        return <div className="blog-category"  >
          <img src={Blogs} alt="" />
          <span> Blogs </span>
        </div>
      case 'devotional':
        return <div className="devotional-category"  >
          <img src={Devotionals} alt="" />
          <span> Devotionals </span>
        </div>
      default:
        return;
    }
  }

  const HandleReviewFilter = (entityType) => {
    switch (entityType) {
      case 'course':
        if (reviewFilter == 'course') {
          setReviewFilter('')
        } else {
          setReviewFilter('course')
        }
        break;

      case 'blog':
        if (reviewFilter == 'blog') {
          setReviewFilter('')
        } else {
          setReviewFilter('blog')
        }
        break;

      case 'devotional':
        if (reviewFilter == 'devotional') {
          setReviewFilter('')
        } else {
          setReviewFilter('devotional')
        }
        break;

      default:
        return;
    }
  }

  const deleteMultipleReviews = () => {

    setDeletingReviews(true)

    Promise.all(
      selectedReviews.map(async review => {
        deleteReviewFirebase(review.entity, review.entityId, review.id)
      })
    ).then(data => {
      console.log('selected reviews deleted')
      setSelectedReviews([])
      setDeletingReviews(false)
    })

  }

  const deleteReviewFirebase = (entityType, entityId, timestamp) => {

    remove(ref(db, `${entityType}sReview/${entityId}/${timestamp}`))
      .then(() => {
        console.log('review removed')
        setSelectedReviews([])
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const handleSelectAll = () => {
    if (selectedAll) {
      setSelectedAll(false)
      setSelectedReviews([])
    } else {
      setSelectedAll(true)
      setSelectedReviews([])

      if (reviewFilter === 'blog') {
        for (let i = 0; i < blogReviews.length; i++) {
          setSelectedReviews(prev => [...prev, { entity: blogReviews[i]?.entity, entityId: blogReviews[i]?.entityId, id: blogReviews[i]?.timeStamp }])
        }
      } else if (reviewFilter === 'course') {
        for (let i = 0; i < courseReviews.length; i++) {
          setSelectedReviews(prev => [...prev, { entity: courseReviews[i]?.entity, entityId: courseReviews[i]?.entityId, id: courseReviews[i]?.timeStamp }])
        }
      } else if (reviewFilter === 'devotional') {
        for (let i = 0; i < devotionalReviews.length; i++) {
          setSelectedReviews(prev => [...prev, { entity: devotionalReviews[i]?.entity, entityId: devotionalReviews[i]?.entityId, id: devotionalReviews[i]?.timeStamp }])
        }
      } else {
        for (let i = 0; i < allReviews.length; i++) {
          setSelectedReviews(prev => [...prev, { entity: allReviews[i]?.entity, entityId: allReviews[i]?.entityId, id: allReviews[i]?.timeStamp }])
        }
      }

    }
  }

  const handleSelectReviews = (entity, entityId, id) => {

    const reviewFound = selectedReviews?.find((item) => id == item.id)

    if (reviewFound) {

      const array = selectedReviews
      const result = array.filter(item => item.id !== reviewFound.id);
      console.log(result)
      setSelectedReviews(result)

    } else {

      setSelectedReviews(prev => [...prev, { entity, entityId, id }])

    }

  }

  const handleCheckCheckedReview = (id) => {

    const reviewFound = selectedReviews?.find((item) => id == item.id)

    if (reviewFound) {
      return true
    } else {
      return false
    }

  }

  return (
    <div className='container reviews'>

      {
        deletingReviews
          ? <div className="deleting-reviews-container">
            <CircularProgress size={30} />
            <h3>Deleting Reviews...</h3>
          </div>
          : null
      }

      <header>
        <h1>Reviews</h1>
        <div className="action-btns">

          {
            selectedAll
              ? <div className="delete-all-btn">
                <Button className='header-cta' >Delete All</Button>
              </div>
              : null
          }

          {
            selectedReviews?.length > 0
              ? <div className="delete-btn">
                <Button className='header-cta' onClick={() => deleteMultipleReviews()} >Delete</Button>
              </div>
              : null
          }

          <div className="search-reviews">
            <input placeholder="Search..." onChange={({ target }) => setSearchQuery(target.value)} ></input>
          </div>
          <div onClick={() => HandleReviewFilter('blog')} className="filter-blogs-reviews" style={reviewFilter === "blog" ? { background: "#D7F1FA" } : null} >
            <img src={reviewFilter === "blog" ? Blogs : BlogsBw} alt="filter button" />
          </div>
          <div onClick={() => HandleReviewFilter('course')} className="filter-courses-reviews" style={reviewFilter === "course" ? { background: "#ECCCEF" } : null} >
            <img src={reviewFilter === "course" ? Courses : CoursesBw} alt="filter button" />
          </div>
          <div onClick={() => HandleReviewFilter('devotional')} className="filter-devotionals-reviews" style={reviewFilter === "devotional" ? { background: "#FDF5D8" } : null} >
            <img src={reviewFilter === "devotional" ? Devotionals : DevotionalsBw} alt="filter button" />
          </div>
        </div>
      </header>



      <div className="data-table">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left"><Checkbox checked={selectedAll} onClick={() => handleSelectAll()} /></TableCell>
                <TableCell align="left">Category</TableCell>
                <TableCell align="left">Review</TableCell>
                <TableCell align="left">Rating</TableCell>
                <TableCell align="right">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                allReviews?.length
                  ? allReviews?.map((review) => (
                    <>
                      <TableRow key={review.timeStamp} className="table-row">
                        <TableCell className="TableCell" style={{ width: 80 }} ><Checkbox checked={handleCheckCheckedReview(review.timeStamp)} onClick={() => handleSelectReviews(review.entity, review.entityId, review.timeStamp)} /></TableCell>
                        <TableCell className="TableCell review-category-container" style={{ width: 180 }} >{switchCategory(review?.entity)}</TableCell>
                        <TableCell className="TableCell"  >
                          <div className="review-user-container">{review.username} - {review.email}</div>
                          <div className="review-text">{review.reviewText}</div>
                        </TableCell>
                        <TableCell style={{ width: 100 }} align="left" className="TableCell" >
                          <Rating name="simple-controlled" value={review?.rating} readOnly precision={0.5} />
                        </TableCell>
                        <TableCell style={{ width: 80 }} align="right" valign="middle" className="delete-review">
                          <Tooltip title="Delete Review">
                            <img className='back-button action-icon' onClick={() => deleteReviewFirebase(review.entity, review.entityId, review.timeStamp)} src={deleteButton} alt='delete' />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
                  : null
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default Reviews;
