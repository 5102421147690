import React from 'react';
import { useLocation } from 'react-router-dom'

import Public from '../assets/img/logo-public.png';
import '../styles/App.scss';

const noMenuRoutes = ["/login"];

function Image() {

    const { pathname } = useLocation();
    
    if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

    return (
        <div className="full-frame">
            <div className="public-header">
                <img src={Public} />
            </div>
        </div>
    );
}
export default Image;