import React, { createContext, useState } from 'react';

const AdminContext = createContext({
  permissions: [
    {
      permission: "reviews",
      permission_type: "read"
    },
    {
      permission: "blogs",
      permission_type: "both"
    },
    {
      permission: "users",
      permission_type: "both"
    },
    {
      permission: "payments",
      permission_type: "both"
    },
    {
      permission: "books",
      permission_type: "both"
    },
    {
      permission: "courses",
      permission_type: "both"
    },
    {
      permission: "devotionals",
      permission_type: "both"
    },
    {
      permission: "authors",
      permission_type: "both"
    },
    {
      permission: "devotionals",
      permission_type: "both"
    },
    {
      permission: "devotionals",
      permission_type: "both"
    },
    {
      permission: "devotionals",
      permission_type: "both"
    }
  ],
  setPermissions: (setPermissions) => { },
});

export function AdminContextProvider(props) {

  const [permissions, setPermissions] = useState([
    {
      permission: "reviews",
      permission_type: "read"
    },
    {
      permission: "blogs",
      permission_type: "both"
    },
    {
      permission: "users",
      permission_type: "both"
    },
    {
      permission: "payments",
      permission_type: "both"
    },
    {
      permission: "books",
      permission_type: "both"
    },
    {
      permission: "courses",
      permission_type: "both"
    },
    {
      permission: "devotionals",
      permission_type: "both"
    },
    {
      permission: "authors",
      permission_type: "both"
    },
    {
      permission: "devotionals",
      permission_type: "both"
    },
    {
      permission: "devotionals",
      permission_type: "both"
    },
    {
      permission: "devotionals",
      permission_type: "both"
    }
  ]);

  function setPermissionsHandler(data) {
    setPermissions(data);
  }

  const context = {
    permissions: permissions,
    setPermissions: setPermissionsHandler,
  };

  return <AdminContext.Provider value={context}>
    {props.children}
  </AdminContext.Provider>
}

export default AdminContext;