import { Button, Snackbar, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Close } from '@mui/icons-material';
import AuthContext from '../../store/AuthContext';
import DrawerContext from '../../store/DrawerContext';

const CreateUserDrawer = () => {

  const [open, setOpen] = useState(false);
  const [uuid, setUUID] = useState("");
  const authCtx   = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const navigate  = useNavigate();

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [createUserObj, setCreateUserObj] = useState({ 
    name: "", email: "", password: "",
  });

  // console.log("createDevotionalObj", createDevotionalObj);

  const submitCreateUser = () => {
    const { name, email, password} = createUserObj;
    if(authCtx.isLoggedIn) {
      console.log(email, password, name);
      authCtx.createUser(email, password, name)
    }
  }


  return (
    <div className="container">
      <div className="drawer">

        <header style={{ display: "flex", justifyContent: "space-between" }}>
          <h1 data-aos="fade-right" data-aos-delay="500">Create User</h1>
          <Close style={{ margin: "0 0 0 auto", cursor: "pointer" }} onClick={() => drawerCtx.closeDrawer()} />
        </header>

        <section>

          {/* <div className="double-column-form"> */}
          {/* <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required /> */}
          <TextField style={{ margin: "10px auto 15px" }} name="name" label="Name" value={createUserObj.name} onChange={(e) => setCreateUserObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Name" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          <TextField style={{ margin: "10px auto 15px" }} name="email" label="Email" value={createUserObj.email} onChange={(e) => setCreateUserObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Email" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          {/* <TextField style={{ margin: "10px auto 15px" }} name="description" label="Description" value={createDevotionalObj.description} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" multiline rows={4} InputLabelProps={{ shrink: true }} fullWidth required /> */}
          <TextField style={{ margin: "10px auto 15px" }} name="password" label="Password" value={createUserObj.password} onChange={(e) => setCreateUserObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Password" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          

          {/* </div> */}

          <div className="double-column-form aligned-right margin-top">

            <Button variant="contained" component="label" disabled={false} 
              onClick={(e) => submitCreateUser()}>Create User
            </Button>
          </div>

        </section>

        <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message="User Created"
      />

      </div>
    </div>
  )
}



export default CreateUserDrawer;
