import React, { useContext, useState, useEffect } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import DrawerContext from '../../../store/DrawerContext';

import 'react-quill/dist/quill.snow.css';
import { CloseRounded } from '@mui/icons-material';

const EditDevotionalTopicsDrawer = ({ drawerDetails }) => {

    //context
    const authCtx   = useContext(AuthContext);
    const drawerCtx = useContext(DrawerContext);

    const [topics, setTopics] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [topic, setTopic] = useState('');

    //effect
    useEffect(() => {
      getAllTopics()
      setSelectedTopics(drawerDetails.topics)
    }, [])

    //functions
    const submitEditTopic = (id) => {
    
      if(authCtx.isLoggedIn) {
        fetch(process.env.REACT_APP_API_URI + '/v1/admin/devotionals/topics', {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({
            topicId: id,
            id: drawerDetails.id
          })
        }).then(response => {
          return response.json();
        }).then(data => {
          if(data.status == "success") {
          } else {
            console.error("Error while editing devotional...");
          }
        }).catch((err) => console.error("Error while editing devotional...", err));
      }

    }

    const getAllTopics = () => {

          fetch(process.env.REACT_APP_API_URI + '/v1/topics', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
          })
          .then(response => {
            return response.json();
          }).then(data => {
            if (data.status == 'success') {
              setTopics(data?.data)
            }
          }).catch(err => console.error(err));
      
        }

    const DeleteTopic = async (devoId, id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/devotionals/${devoId}/topics/${id}`, 
            {
              method: 'DELETE',
              headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
            })

            const topicsResponse = await response.json();
            if(topicsResponse.status == 'success'){
            }
        } catch (err) {
            console.log(err);
        }
	  }


    const handleChangeTopic = (event) => {
      setTopic(event.target.value);
    }
    
    const handleSelectTopic = (topic) => {
      if(!selectedTopics.some(el => el.id === topic.id)){
        submitEditTopic(topic.id)
        setSelectedTopics(prev => [...prev, topic]);
      } else {
        return
      }
    }

    const handleFilterArray = (id) => {
      DeleteTopic(drawerDetails.id, id)
      const newArr = selectedTopics.filter((arr) => { 
        return arr.id !== id
      })
      setSelectedTopics(newArr)
    }

  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Edit Devotional Topics</h1>
        </header>

        <section>
                
          <FormControl fullWidth disableEnforceFocus>
            <InputLabel id="select-author">Topic</InputLabel>
            <Select
              labelId="select-author"
              value={topic}
              label="Topics"
              onChange={handleChangeTopic}
              className='select-author'
              disableEnforceFocus
            >
              {
                topics?.map((topic, i) => {
                  return <MenuItem key={i} disableEnforceFocus onClick={() => handleSelectTopic(topic)} value={topic.title}>{topic.title}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <div className="author-list">
            {
              selectedTopics?.map((topic, i) => (
                <span key={i} className='author-tag'>{topic.title}<CloseRounded className='remove-author' onClick={() => handleFilterArray(drawerDetails.id, topic.id)} /></span>
              ))
            }
          </div>
          <div className="double-column-form aligned-right margin-top">
            <Button variant="contained" component="label" disabled={false} 
              onClick={(e) => drawerCtx.closeDrawer()}>Save Changes
            </Button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default EditDevotionalTopicsDrawer;

