import { Button, CircularProgress } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import AuthContext from '../../store/AuthContext'
import ModalContext from '../../store/ModalContext'
import { CloseRounded } from '@mui/icons-material'

const AddFeaturedEntity = () => {


  const authCtx = useContext(AuthContext)
  const modalCtx = useContext(ModalContext)


  const [entities, setEntities] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {

    getAllEntity(modalCtx.details.entity)

  }, [modalCtx])


  /**
  * this will run on the initial page load, getting first 30 blogs
  */
  const getAllEntity = async (entity) => {
    setLoading(true)
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/${entity}s${entity == 'course' ? '/all/new' : ''}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      const data = await res.json()
      if (data.status === 'success') {
        setEntities(data.data)
        setLoading(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const addFeaturedContent = (entityType, entityId) => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/admin/featured`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify({
        "entityId": entityId,
        "entityType": entityType
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log('Success:', data);
      if (data.status === 'success') {
        modalCtx.closeModal()
      }
    }).catch(err => console.error(err));

  }

  const putFeaturedContent = (entityType, entityId) => {

    fetch(`${process.env.REACT_APP_API_URI}/v1/featured`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      body: JSON.stringify({
        "entityId": entityId,
        "entityType": entityType
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log('Success:', data);
      if (data.status === 'success') {
        modalCtx.closeModal()
      }
    }).catch(err => console.error(err));

  }

  return (
    <div className='featured-entity-modal-container' >
      <header>
        <h3>Select {modalCtx.details.entity}</h3>
        <CloseRounded onClick={() => modalCtx.closeModal()} className='close' />
      </header>
      <div className="featured-entity-modal">
        <div className="entity-list">
          {!loading
            ? entities?.map((item, index) => (
              <div className="entity" key={index}>
                <div className="entity-title">{item.title}</div>
                <Button className="add-entity-btn" onClick={() => addFeaturedContent(modalCtx.details.entity, item.id)} >Add</Button>
              </div>
            ))
            : <CircularProgress className='circular-progress' />
          }
        </div>
      </div>
    </div>
  )
}

export default AddFeaturedEntity