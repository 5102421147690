import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import GetImage from '../Components/GetImage'

import AuthContext from '../store/AuthContext.js';
import ModalContext from '../store/ModalContext.js';
import DrawerContext from '../store/DrawerContext.js';

//assets
import placeholder from '../assets/img/placeholder.png';
import editButton from '../assets/img/blog/edit.svg';
import deleteButton from '../assets/img/blog/delete.svg';

import { Button, CircularProgress, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { AddRounded, CalendarMonth, CloseRounded, Delete, DragIndicator, Edit, PictureAsPdf } from '@mui/icons-material';
import SearchIcon from "@mui/icons-material/Search";
import { ref, getDownloadURL } from "firebase/storage";
import storage from "../Components/FirebaseConfig";

const Books = () => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const navigate = useNavigate();

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [books, setBooks] = useState([])
  const [tags, setTags] = useState([]);
  const [topics, setTopics] = useState([]);
  const [topicsArray, setTopicsArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [affiliateCode, setAffiliateCode] = useState('');
  const [filtered, setFiltered] = useState([]);
  const [bookOrder, setBookOrder] = useState({
    bookOrder: [
      {
        bookId: 0,
        orderIndex: 0
      }
    ]
  });

  // updating status states 
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [updatingStatusId, setUpdatingStatusId] = useState();

  useEffect(() => {
    getAllBook()
  }, []);

  useEffect(() => {
    getAllBook()
  }, [drawerCtx, modalCtx]);

  useEffect(() => {
    getBookOrder()
  }, [books]);

  useEffect(() => {
    reOrderEpisodes()
  }, [bookOrder]);

  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  const getBookOrder = () => {

    let tempBookOrder = {
      bookOrder: [
        {
          bookId: 0,
          orderIndex: 0
        }
      ]
    }

    books?.map((item, index) => {
      return tempBookOrder?.bookOrder?.push({ bookId: item.id, orderIndex: index + 1 })
    })

    setBookOrder(tempBookOrder)

  }


  const getAllBook = () => {

    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v1/admin/books', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    })
      .then(response => {
        // console.log(response);
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          setIsLoading(false);
          setBooks(data?.data.reverse())
          setFiltered(data?.data.reverse())
          SearchFilter('')
          setUpdatingStatus(false)
        } else {
          if (data?.code == 'auth/argument-error') {
            navigate(`/login?destination=books`)
            authCtx.logout()
          }
        }
      }).catch((err) => {
        setIsLoading(false);
        console.error(err);
        setUpdatingStatus(false);
      });

  }

  const reOrderEpisodes = async () => {

    try {

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/books/update/orderIndex`, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify(bookOrder)
      })
      const data = await res.json()

      if (data.status === 'success') {
        console.log('success', data)
      }

    } catch (error) {
      console.log(error)
    }

  }


  // const reOrderEpisodes = () => {

  //   let requests = episodesPublished.map((episode, i) => {
  //     //create a promise for each API call
  //     return new Promise(async (resolve, reject) => {
  //       const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/courses/${id}/episode/${episode.id}/update/episodeNo`, {
  //         method: 'PUT',
  //         mode: 'cors',
  //         headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
  //         body: JSON.stringify({ "episodeNo": i + 1 })
  //       })
  //       const data = await res.json()
  //       if (data.status == 'success') {
  //         resolve(data)
  //       } else {
  //         reject('error updating episode number.')
  //       }
  //     })
  //   })
  //   Promise.all(requests).then((episodeProgressData) => {
  //     episodeProgressData.forEach((res, i) => {
  //       if (res) {
  //         // console.log('first', res, i)
  //       }
  //     })
  //     setIsReordering(false)
  //   }).catch(err => console.log(err))
  // }

  const renderBookImage = (book) => {
    return (
      <div className='background' style={book.bg_image ? { backgroundImage: `url(${book.bg_image})` } : { background: `${book.bg_color}` }}>
        {book.bg_image ? <GetImage imageRef={book.bg_image} alt={book.title} /> : null}
        <img className='edit-bg' src={editButton} style={{ height: "16px", width: '16px' }} onClick={() => openBackgroundColor(book)}></img>
        <div className='image'>
          <img src={book.image} style={{ height: "110px", boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px' }} />
        </div>
      </div>
    );
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(books);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setBooks(items);
  };

  const updateBook = (title, bookId, blogId) => {

    let currentBook = books.find(function (obj) { return obj.id == bookId; });
    let img = [];

    if (currentBook && currentBook.affiliate_code) {
      let images = currentBook.affiliate_code.split("src=");
      if (images[1]) {
        img = images[1].split("\"");
      }
    }

    // currentBook.image = img[1];

    const payload = {
      "title": title,
      "blog_id": blogId,
      "affiliate_code": currentBook.affiliate_code,
      "image": img[1]
    }

    fetch(process.env.REACT_APP_API_URI + `/v1/admin/books/${bookId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload)
    })
      .then(response => {
        return response.json();
      }).then(data => {
        console.log('code that is being sent ', affiliateCode)
        if (data.status == 'success') {
          console.log('books updated ', data);
          let updatedBooks = [];
          books.forEach(b => {
            if (b.id == bookId) {
              b.image = img[1];
            }
            updatedBooks.push(b);
          });
          setBooks(updatedBooks);
        }
      }).catch((err) => {
        console.error(err);
      });

  }

  const SearchFilter = (text) => {
    setSearch(text);

    if (text == '') {
      setFiltered([...books]);
    } else {
      setFiltered(
        [...books.filter(dev => dev.title.includes(text))]
      )

    }

  }

  const openDeleteBookModal = (id) => {
    modalCtx.setDetails('confirm-delete-book', { id });
    modalCtx.openModal();
  }

  const openEditBookModal = (id) => {
    modalCtx.setDetails('edit-book', { id });
    modalCtx.openModal();
  }

  const openAttchBlogModal = (id) => {
    modalCtx.setDetails('attach-blog', { id });
    modalCtx.openModal();
  }

  const openUploadImageModal = (id) => {
    modalCtx.setDetails('upload-image', { aspectRatio: undefined, origin: 'book', id: id });
    modalCtx.openModal();
  }

  const openBackgroundColor = (id) => {
    modalCtx.setDetails('choose-background-color', { id });
    modalCtx.openModal();
  }

  const handleAffiliateCode = (e, book) => {
    let currentBook = books.find(function (obj) { return obj.id == book.id; });
    currentBook.affiliate_code = e.target.value;
    let updatedBooks = [];
    books.forEach(b => {
      if (b.id == book.id) {
        b.affiliate_code = e.target.value;
      }
      updatedBooks.push(b);
    });
    setBooks(updatedBooks);
  }

  // const getImg = (coverRef) => {
  // 	getDownloadURL(ref(storage, coverRef))
  // 		.then((url) => {
  //       console.log("this is url", url);
  // 			return url;

  // 		})
  // 		.catch((error) => {
  // 			console.log(error)
  // 		});
  // }

  console.log(books)
  console.log(bookOrder)

  return (
    <div className='container'>

      <header>
        <h1>Books</h1>
        <div className="create-devotional">
          {/* <Button className='create-book-btn' onClick={() => handleModal('createBook')} >+ Create New</Button> */}
          <TextField
            id="search"
            type="search"
            label="Search"
            value={search}
            onChange={(e) => { SearchFilter(e.target.value) }}
            sx={{ width: 300 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button className='header-cta' onClick={() => toggleDrawerHandler('create-book', null)}>+ Create New</Button>
        </div>
      </header>

      <div className="data-table">
        {/* {
          isLoading
            ? (
              <section style={{ width: "100%", minHeight: "250px", display: "flex", justifyContent: "center", alignItems: "center", }}>
                <CircularProgress style={{ color: "#1359e4" }} />
              </section>
            )
            : ( */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ width: '100%', position: "sticky", top: "0", background: "#FFF", zIndex: "100" }}>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left">Book Image</TableCell>
                <TableCell align="left">Blog Name</TableCell>
                {/* <TableCell align="left">Author</TableCell> */}
                {/* <TableCell align="left">Description</TableCell> */}
                <TableCell align="left">Affiliate Code</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="books">
                {(provided) => (
                  <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                    {
                      books?.map((book, index) => (
                        <Draggable draggableId={book.id.toString()} index={index} key={book.id}>
                          {
                            (provided) => (
                              <TableRow ref={provided.innerRef} {...provided.draggableProps} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} className="table-row">
                                <TableCell scope="row" className="grab-here TableCell" {...provided.dragHandleProps}>
                                  <div className="drag-book-handle">
                                    <h3 style={{ color: "black" }}>{index + 1}</h3>
                                    <DragIndicator />
                                  </div>
                                </TableCell>
                                <TableCell scope="row" className="book-img TableCell">
                                  {renderBookImage(book)}
                                </TableCell>
                                <TableCell align="left" className="TableCell booktitle">
                                  <div className="book-name-row">
                                    <span> {book.title.length > 0 ? book.title : "Not Found"}</span>
                                    <img src={editButton} onClick={() => openEditBookModal(book)} />
                                  </div>
                                  <div className="blog-title-row">
                                    <span className="blog-title"> {book.blog.length > 0 ? book.blog : null}</span>
                                  </div>
                                  <div className="book-div">
                                    <div style={{ display: 'flex', alignItems: "center", gap: '10px', cursor: 'pointer' }} className="bookname filled" onClick={() => openAttchBlogModal(book)}>
                                      <AddRounded className="add-author action-icon" /> Attach a Blog
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell align="left" className="book-affiliate TableCell">
                                  <div className="text-div">
                                    <textarea
                                      className="type-affiliate"
                                      value={book.affiliate_code}
                                      onChange={(e) => handleAffiliateCode(e, book)}
                                      cols="45"
                                      rows="5"
                                      style={{ width: '100%', resize: 'none' }}
                                    ></textarea>
                                    <div>
                                      <Button onClick={() => updateBook(book.title, book.id, book.blog_id)} className="save-changes-affiliate-btn">
                                        Save Changes
                                      </Button>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell style={{ width: '50px' }} align="center" className="action-buttons-cell TableCell">
                                  <div className="edit-icons">
                                    <img style={{ cursor: "pointer" }} className="devo-edit-btn" onClick={() => openDeleteBookModal(book.id)} src={deleteButton} alt="" />
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          }
                        </Draggable>
                      ))
                    }
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
        {/* )
        } */}
      </div>

    </div>
  )
}

export default Books;

