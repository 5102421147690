import { useContext, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { CloseRounded } from '@mui/icons-material';
import moment from 'moment/moment';

const PublishEpisodeModal = ({ modalDetails }) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [episodeDate, setBlogDate] = useState(null);
  const [updatingStatusId, setUpdatingStatusId] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }

  const deleteBlog = (id) => {
    if (authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + '/v1/admin/episodes/' + id, {
        method: 'DELETE',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status == "success") {
          modalCtx.closeModal();
        } else {
          console.error("Error while deleting episode...");
        }
      }).catch((err) => {
        console.error("Error while deleting episode...", err);
      });
    }
  }

  const updateDate = (episode) => {
    console.log(episode);
    // if (authCtx.isLoggedIn) {
    //     fetch(process.env.REACT_APP_API_URI + '/v1/admin/episodes/' + id, {
    //       method: 'DELETE',
    //       mode: 'cors',
    //       headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
    //     }).then(response => {
    //       return response.json();
    //     }).then(data => {
    //       console.log(data);
    //       if (data.status == "success") {
    //         modalCtx.closeModal();
    //       } else {
    //         console.error("Error while deleting episode...");
    //       }
    //     }).catch((err) => {
    //       console.error("Error while deleting episode...", err);
    //     });
    //   }

  }


  const updateStatus = async () => {

    setUpdatingStatus(true)
    setUpdatingStatusId(modalDetails.episode.id)

    try {

      if(modalDetails.episode.status === 'published'){

        fetch(process.env.REACT_APP_API_URI + '/v1/admin/courses/' + modalDetails.episode.courseId + '/episodes/'+modalDetails.episode.id+'/updateStatus', {
          method: 'PUT',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({ status: 'draft' })
        })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status == 'success') {
            setUpdatingStatus(false);
            modalCtx.closeModal();
          }
        }).catch(err => console.error(err));

      } else {

        fetch(process.env.REACT_APP_API_URI + '/v1/admin/courses/' + modalDetails.episode.courseId + '/episodes/'+modalDetails.episode.id+'/updateStatus', {
          method: 'PUT',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({ status :'published' })
        })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status == 'success') {
            setUpdatingStatus(false)
            modalCtx.closeModal();
          } else if(data.status == "warning") {
            setUpdatingStatus(false);
            setWarningMessage(data.message);
          } else if(data.status == "error") {
            setUpdatingStatus(false);
            setErrorMessage(data.message);
          }
        }).catch(err => console.error(err));

      }

    } catch (err) {
      console.log(err);
    }
  }

  // console.log(dayjs(episodeDate).format("MMM DD, YYYY"))

  if (modalDetails.episode.status == "draft") {
    return (
      <div className="modal">
        { warningMessage ? <CloseRounded className='remove-author' onClick={() => handleCloseModal()} /> : <>
            <div className='heading'>
              <h3>Are you sure that you wish to publish this episode ?</h3>
              <CloseRounded className='remove-author' onClick={() => handleCloseModal()} />
            </div>
          </>
        }
        { warningMessage ? <div className="warning"><strong>Episode published, but note the missing details</strong><br/>{warningMessage}</div> : null }
        { errorMessage ? <div className="error"><strong>This episode can't be published</strong><br/>{errorMessage}</div> : null }
        { warningMessage ? null : <div className="footer">          
            <Button className="cancel-btn" variant='outlined' onClick={(e) => { handleCloseModal() }}>Cancel</Button>
            <Button className="confirm-btn" onClick={() => updateStatus()}>{updatingStatus ? <CircularProgress></CircularProgress> : 'Publish'}</Button>
          </div>
        }
      </div>
    )
  } else {
    return <div className="modal">
      <div className='heading'>
        <h3>Are you sure that you wish to draft this episode?</h3>
        <CloseRounded className='remove-author' onClick={() => handleCloseModal()} />
      </div>

      <div className="footer">

        <Button className="cancel-btn" variant='outlined' onClick={(e) => { handleCloseModal() }}>Cancel</Button>
        <Button className="confirm-btn" onClick={() => updateStatus()}>{updatingStatus ? <CircularProgress></CircularProgress> : 'Draft'}</Button>
        {/* <Button className="confirm-btn" onClick={() => updateDate(modalDetails.episode.id)}>Draft</Button> */}
      </div>
    </div>
  }
}

export default PublishEpisodeModal;
