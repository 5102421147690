import { useContext } from 'react';
import { Button } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext';

const DeleteBookModal = ({ modalDetails }) => {

  // console.log("delete modal props", modalDetails);
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }

  const deleteBook = (id) => {
    if (authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + '/v1/admin/books/' + id, {
        method: 'DELETE',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
        // body: JSON.stringify({ id })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status == "success") {
          // navigate("/devotionals", { replace: true });
          // drawerCtx.closeDrawer();
          modalCtx.closeModal();
          // window.location.reload();
        } else {
          console.error("Error while deleting books...");
          // setSnackbarMessage(data.message);
          // setOpenSnackbar(true);
        }
      }).catch((err) => {
        console.error("Error while deleting books...", err);
        handleCloseModal();
      });
      console.log("delete book id", id);
    }
  }

  return (
    <div className="modal">
      <p>Are you sure you want to delete this Book?</p>
      <div className="footer">
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>No</Button>
        <Button className="confirm-btn" onClick={() => deleteBook(modalDetails.id)}>Yes</Button>
      </div>
    </div>
  )
}

export default DeleteBookModal;
