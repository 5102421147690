import React, { useContext, useState, useEffect } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import DrawerContext from '../../../store/DrawerContext';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Close, CloseRounded } from '@mui/icons-material';

const EditDevotionalDrawer = ({ drawerDetails }) => {

  console.log("EditDevotionalDrawer drawerDetails", drawerDetails);

    //context
    const authCtx   = useContext(AuthContext);
    const drawerCtx = useContext(DrawerContext);

    //states
    const [createDevotionalObj, setCreateDevotionalObj] = useState({ 
      author: drawerDetails.author || "", 
      title: drawerDetails.title || "", 
      description: drawerDetails.description || "",
      page_title: drawerDetails.page_title || "",
      meta_description: drawerDetails.meta_description || "",
      meta_keywords: drawerDetails.meta_keywords || ""
    });

    const [authors, setAuthors]                   = useState([]);
    const [selectedAuthors, setSelectedAuthors]   = useState([]);
    const [author, setAuthor]                     = useState("");
    const [pageTitle, setPageTitle]               = useState("");
    const [metaDescription, setMetaDescription]   = useState("");
    const [metaKeywords, setMetaKeywords]         = useState("");

    //effect
    useEffect(() => {
      setSelectedAuthors(drawerDetails.author)
      getAllAuthors()
    }, []);


    //functions
    const submitEditDevotional = async () => {
      if (authCtx.isLoggedIn) {
        const { title, description, author, page_title, meta_description, meta_keywords } = createDevotionalObj;
        try {
          const response = await fetch(process.env.REACT_APP_API_URI + '/v1/admin/devotionals/' + drawerDetails.id + '/update', {
            method: 'PUT',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
            body: JSON.stringify({
              title, description, author,
              page_title, meta_description, meta_keywords,
            })
          });
          const data = await response.json();
          if (data.status == "success") {
            drawerCtx.closeDrawer();
          } else {
            console.error("Error while editing devotional...");
          }
        } catch (err) {
          console.error("Error while editing devotional...", err);
        }
      }
    }

    

    //functions
    const submitEditAuthor = (id) => {
    
      if(authCtx.isLoggedIn) {
        fetch(process.env.REACT_APP_API_URI + '/v1/devotionals/author', {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({
            id: drawerDetails.id,
            authorId: id
          })
        }).then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if(data.status == "success") {
          } else {
            console.error("Error while editing devotional...");
          }
        }).catch((err) => console.error("Error while editing devotional...", err));
      }
    }

    const getAllAuthors = () => {

      fetch(process.env.REACT_APP_API_URI + '/v1/authors', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          setAuthors(data?.data)
        }
      }).catch(err => console.error(err));
  
    }

    const deleteAuthor = async (devoId, id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/v1/devotionals/${devoId}/author/${id}`, 
            {
              method: 'DELETE',
              headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
            })
      
            const topicsResponse = await response.json();
            if(topicsResponse.status == 'success'){
            }
        } catch (err) {
            console.log(err);
        }
     }

    const handleChangeAuthor = (event) => {
      setAuthor(event.target.value);
    }

    const handleSelectAuthor = (author) => {
      if(!selectedAuthors.some(el => el.id === author.id)){
        submitEditAuthor(author.id)
        setSelectedAuthors(prev => [...prev, author]);
      } else {
        return
      }
    }

    const handleFilterArray = (id) => {
     
      deleteAuthor(drawerDetails.id, id)
      const newArr = selectedAuthors.filter((arr) => { 
        return arr.id !== id
       })
       setSelectedAuthors(newArr)
    }



  return (
    <div className="container">
      <div className="drawer">

        <header style={{ display: "flex", justifyContent: "space-between" }}>
          <h1 data-aos="fade-right" data-aos-delay="500">Edit Devotional Drawer</h1>
          <Close style={{ margin: "0 0 0 auto", cursor: "pointer" }} onClick={() => drawerCtx.closeDrawer()} />
        </header>

        <section>

          {/* <div className="double-column-form"> */}
          {/* <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required /> */}
          <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={createDevotionalObj.title} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          {/* <TextField style={{ margin: "10px auto 15px" }} name="author" label="Author" value={createDevotionalObj.author} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required /> */}
          {/* <TextField style={{ margin: "10px auto 15px" }} name="description" label="Description" value={createDevotionalObj.description} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required /> */}

          {/* <div className="author-list">
            {
              selectedAuthors?.map((author, i) => (
                <span key={i} className='author-tag'>{author.full_name}<CloseRounded className='remove-author' onClick={() => handleFilterArray(author.id)} /></span>
              ))
            }
          </div> */}

          {/* <FormControl fullWidth >
            <InputLabel id="select-author">Author</InputLabel>
            <Select
              labelId="select-author"
              value={author}
              label="Author"
              onChange={handleChangeAuthor}
              className='select-author'
              disableEnforceFocus
            >
              {
                authors?.map((author, i) => {
                  return <MenuItem key={i} onClick={() => handleSelectAuthor(author)} value={author.full_name}>{author.full_name}</MenuItem>
                })
              }
            </Select>
          </FormControl> */}
          {/* <p style={{color: "#aaa"}}>Removed Adding author from here because it was causing problems</p> */}

          <ReactQuill theme="snow" name="description" value={createDevotionalObj.description} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, description: e }))} />
          {/* </div> */}

          <TextField style={{ margin: "10px auto 15px" }} name="page_title" label="Page Title" value={createDevotionalObj.page_title} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Page Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          
          <TextField style={{ margin: "10px auto 15px" }} name="meta_description" label="Meta Description" value={createDevotionalObj.meta_description} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Meta Description" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          
          <TextField style={{ margin: "10px auto 15px" }} name="meta_keywords" label="Meta Keywords" value={createDevotionalObj.meta_keywords} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Meta Keywords" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />

          <div className="double-column-form aligned-right margin-top">

            <Button variant="contained" component="label" disabled={false} 
              onClick={(e) => submitEditDevotional()}>Save Changes
            </Button>
            <Button style={{ margin: "0 0 0 15px" }} variant="contained" component="label" disabled={false} 
              onClick={() => drawerCtx.closeDrawer()}>Cancel
            </Button>
          </div>

        </section>

      </div>
    </div>
  );
}

export default EditDevotionalDrawer;

