import { Button, TextField } from '@mui/material';
import React, { useContext, useState } from 'react'
import AuthContext from '../../../store/AuthContext';
import DrawerContext from '../../../store/DrawerContext';
import { useNavigate, useParams } from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateDayDrawer = ({ drawerDetails }) => {

  const authCtx   = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const navigate  = useNavigate();

  console.log("drawerDetails", drawerDetails);

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [createDevotionalObj, setCreateDevotionalObj] = useState({ day: "", title: "", text: "" });

  // console.log("createDevotionalObj", createDevotionalObj);

  const submitCreateDay = () => {
    
    if(authCtx.isLoggedIn) {

    }
    const { day, title, text } = createDevotionalObj;
		fetch(process.env.REACT_APP_API_URI + '/v1/admin/devotionals/day', {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
			body: JSON.stringify({
        devId: drawerDetails.id,
				day, 
        title, 
        text, 
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			if(data.status == "success") {
				// navigate("/devotionals", { replace: true });
        drawerCtx.closeDrawer();
        window.location.reload();
			} else {
        console.error("Error while adding day...");
				// setSnackbarMessage(data.message);
				// setOpenSnackbar(true);
			}
		}).catch((err) => console.error("Error while adding day...", err));
  }


  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Create Day</h1>
        </header>

        <section>

          <TextField style={{ margin: "10px auto 15px" }} name="day" label="Day" value={createDevotionalObj.day} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Day" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={createDevotionalObj.title} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          <ReactQuill theme="snow" name="description" value={createDevotionalObj.text} onChange={(e) => setCreateDevotionalObj((prev) => ({ ...prev, text: e }))} />
          <div className="double-column-form aligned-right margin-top">
            <Button variant="contained" component="label" disabled={false}  onClick={(e) => submitCreateDay()}>Add Day</Button>
          </div>

        </section>

      </div>
    </div>
  )
}



export default CreateDayDrawer;
