import React from 'react';
import AOS from 'aos';

import 'aos/dist/aos.css'; 

import ClientsTable from "../Components/ClientsTable";
import '../styles/App.scss';

// ..
AOS.init();


// import '../App.scss';

const Clients = () => {

	return (
		<div className="container">
			<div data-aos="fade-up">
				<h1>Clients</h1>
			</div>
			<ClientsTable />
		</div>
	);

}
export default Clients;