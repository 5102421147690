import { useContext, useState, useRef, useCallback, useEffect } from 'react';

//context
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext';
import DrawerContext from '../../../store/DrawerContext';

//firebase
import { uploadBytes, ref as sRef, getDownloadURL, database } from "../../FirebaseConfig";
import storage from "../../FirebaseConfig";

//react crop
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import { Base64 } from 'js-base64';
import 'react-image-crop/src/ReactCrop.scss';

//mui
import { Button, CircularProgress, Slider } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';

//dropzone
import Dropzone from './Dropzone';

// import { canvasPreview } from './CropImage.js';
import { compileString } from 'sass';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}


const ImageCropper = (props) => {
  console.log("this is props", props);

  // const db = database;
  const ref = useRef()

  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);

  const [imageType, setImageType] = useState(modalCtx?.details?.imageType)
  // const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [crop, setCrop] = useState({
    unit: 'px', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 200,
    height: 50
  })
  const [zoom, setZoom] = useState(1);
  const [completedCrop, setCompletedCrop] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [image, setImage] = useState();
  const [finalImage, setFinalImage] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [isCropping, setIsCropping] = useState(false);
  const [maxHeight, setmaxHeight] = useState(null)
  const [maxWidth, setmaxWidth] = useState(null)
  const [minWidth, setminWidth] = useState(null)
  const [minheight, setminheight] = useState(null)
  const [advertisementData, setAdvertisementData] = useState({});


  const fetchAdvertisementData = async () => {
    try {
      const response = await fetch('https://api.kingdomempowered.com/v1/advertisement/slot', {
        headers: {
          Authorization: `Bearer ${authCtx.token}`
        }
      });
      const data = await response.json();
      if (data.status === 'success' && data.advertisementSlots) {
        setAdvertisementData(data.advertisementSlots);
      }
    } catch (error) {
      console.error('Error fetching advertisement data:', error);
    }
  };

  useEffect(() => {
    fetchAdvertisementData();
  }, []);

  useEffect(() => {
    console.log("advertisementData", advertisementData);
  }, [advertisementData])


  const [cropConfig, setCropConfig] = useState(
    // default crop config
    {
      unit: "%",
      width: 100,
      aspect: 4 / 1
    }
  );
  const [imageRef, setImageRef] = useState(null);

  // async function cropImage(crop) {
  //   if (imageRef && crop.width && crop.height) {
  //     const croppedImage = await getCroppedImage(
  //       imageRef,
  //       crop,
  //       "croppedImage.jpeg" // destination filename
  //     );

  //     // calling the props function to expose
  //     // croppedImage to the parent component
  //     onImageCropped(croppedImage);
  //   }
  // }


  useEffect(() => {
    console.log("advertisementData", advertisementData)
  }, [advertisementData])


  // get dropped images
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
      // const { width, height } = e.target.result;
      // console.log(width);
      // setCrop(centerAspectCrop(width, height, 4 / 1))
      // setImageRef(file)
      return file;
    });
  }, []);


  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  // get cropped image 
  const cropImage = async () => {
    try {
      // console.log("Are we here");
      // console.log(ref.current);
      // console.log(crop.width);
      // console.log(crop.height);
      // console.log(cropConfig);
      if (ref.current && cropConfig.width && cropConfig.height) {
        const croppedImage = await getCroppedImage(
          ref.current,
          cropConfig,
          "croppedImage.jpeg" // destination filename
        );

        // calling the props function to expose
        // croppedImage to the parent component
        // onImageCropped(croppedImage);
        setCroppedImage(croppedImage);
      }

      // // const croppedImage = await getCroppedImg(image, crop, 0, zoom);
      // console.log(completedCrop);
      // const croppedImage = await canvasPreview(image, completedCrop, zoom, 0);
      // console.log(croppedImage);
      // setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e)
    }
  }

  function getCroppedImage(sourceImage, cropConfig, fileName) {
    // creating the cropped image from the source image
    const canvas = document.createElement("canvas");
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext("2d");

    console.log(cropConfig.x);

    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );

    return canvas.toDataURL('image/png');

    // return new Promise((resolve, reject) => {
    //   canvas.toBlob((blob) => {
    //     // returning an error
    //     if (!blob) {
    //       reject(new Error("Canvas is empty"));
    //       return;
    //     }

    //     blob.name = fileName;
    //     // creating a Object URL representing the Blob object given
    //     const croppedImageUrl = window.URL.createObjectURL(blob);

    //     resolve(croppedImageUrl);
    //   }, "image/jpeg");
    // });
  }

  const resetCrop = () => {
    setCroppedImage(null);
  }

  // this returns a file object from a base file
  const baseToFile = (base) => {
    if (base) {
      let arr = base?.split(",")
      let mime = arr[0].match(/:(.*?);/)[1]
      let data = arr[1]

      let dataStr = Base64.atob(data)
      let n = dataStr.length
      let dataArr = new Uint8Array(dataStr.length)

      while (n--) {
        dataArr[n] = dataStr.charCodeAt(n)
      }

      let file = new File([dataArr], `img_cropper_${Date.now()}.png`, { type: mime })
      return file
    } else {
      return null
    }
  }

  // decides where to send the image based on the origin of the context
  const handleUploadLocation = () => {
    switch (modalCtx.details.origin) {
      case 'devotional':
        uploadDevotionalImg();
        break;

      case 'blog':
        uploadBlogImg();
        break;

      case 'book':
        uploadBookImg();
        break;

      case 'course':
        uploadCourseImg();
        break;

      case 'episode':
        uploadEpisodeImg();
        break;

      case 'advertisingHome':
        uploadAdvertisingImage(1, 29);
        break;

      case 'advertisingDevotional':
        uploadAdvertisingImage(3, 31);
        break;

      case 'advertisingCourses':
        uploadAdvertisingImage(2, 30);
        break;

      default:
        uploadBookImg();
        break;
    }
  }


  // upload image to firebase and then to PUT in devotional object
  const uploadDevotionalImg = () => {

    let img = baseToFile(croppedImage ? croppedImage : image);

    if (img) {

      setIsUploading(true)
      let coverRef = `/devotionals/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {

        fetch(process.env.REACT_APP_API_URI + '/v1/admin/devotionals/' + modalCtx.details.id + '/uploadImage', {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({
            refstring: coverRef
          })
        }).then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == "success") {
            setIsUploading(false)
            modalCtx.closeModal();
          } else {
            console.error("Error while uploading image...");
          }
        }).catch((err) => console.error("Error while uploading image...", err));

      }, (error) => console.log(error)
      )
    } else {
      console.log("no img");
    }
  }

  const uploadBookImg = () => {

    let img = baseToFile(croppedImage ? croppedImage : image);

    if (img) {

      console.log(props.id, "this is props.id");
      setIsUploading(true)
      let coverRef = `/books/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {

        fetch(process.env.REACT_APP_API_URI + '/v1/admin/books/' + props.id + '/uploadBgImage', {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({
            refstring: coverRef
          })
        }).then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == "success") {
            setIsUploading(false)
            modalCtx.closeModal();
          } else {
            console.error("Error while uploading image...");
          }
        }).catch((err) => console.error("Error while uploading image...", err));

      }, (error) => console.log(error)
      )
    } else {
      console.log("no img");
    }
  }

  // upload image to firebase and then to PUT in devotional object
  const uploadBlogImg = () => {

    let img = baseToFile(croppedImage ? croppedImage : image);

    if (img) {

      setIsUploading(true)
      let coverRef = `/blogs/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {

        fetch(process.env.REACT_APP_API_URI + '/v1/admin/blogs/' + modalCtx.details.id + '/uploadImage', {
          method: 'PUT',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({
            refstring: coverRef
          })
        }).then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == "success") {
            setIsUploading(false)
            if (modalCtx.details.newBlog == true) {
              toggleDrawerHandler('edit-blog', modalCtx?.details?.blog)
            }
            modalCtx.closeModal();
          } else {
            console.error("Error while uploading image...");
          }
        }).catch((err) => console.error("Error while uploading image...", err));

      }, (error) => console.log(error)
      )
    } else {
      console.log("no img");
    }
  }

  // upload image to firebase and then to PUT in devotional object
  const uploadEpisodeImg = () => {

    let img = baseToFile(croppedImage ? croppedImage : image);

    if (img) {

      setIsUploading(true)
      let coverRef = `/episodes/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {

        fetch(process.env.REACT_APP_API_URI + '/v1/admin/courses/' + modalCtx.details.id.courseId + '/episodes/' + modalCtx.details.id.id + '/updateImage', {
          method: 'PUT',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({
            refstring: coverRef
          })
        }).then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == "success") {
            setIsUploading(false)
            if (modalCtx.details.newBlog == true) {
              toggleDrawerHandler('edit-blog', modalCtx?.details?.blog)
            }
            modalCtx.closeModal();
          } else {
            console.error("Error while uploading image...");
          }
        }).catch((err) => console.error("Error while uploading image...", err));

      }, (error) => console.log(error)
      )
    } else {
      console.log("no img");
    }
  }

  // upload image to firebase and then to PUT in devotional object
  const uploadCourseImg = () => {

    let img = baseToFile(croppedImage ? croppedImage : image);

    if (img) {

      setIsUploading(true)
      let coverRef = `/courses/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {

        fetch(process.env.REACT_APP_API_URI + '/v1/admin/courses/' + modalCtx.details.id + '/uploadImage', {
          method: 'PUT',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({
            refstring: coverRef
          })
        }).then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == "success") {
            setIsUploading(false)
            modalCtx.closeModal();
          } else {
            console.error("Error while uploading image...");
          }
        }).catch((err) => console.error("Error while uploading image...", err));

      }, (error) => console.log(error)
      )
    } else {
      console.log("no img");
    }
  }

  const uploadAdvertisingImage = (pageId, id) => {
    let img = baseToFile(croppedImage ? croppedImage : image);

    const homePageSlot1Link = advertisementData?.homePage?.[0]?.slot1Link;
    const homePageSlot2Link = advertisementData?.homePage?.[0]?.slot2Link;
    const homePageSlot3Link = advertisementData?.homePage?.[0]?.slot3Link;

    const coursesSlot1Link = advertisementData?.courses?.[0]?.slot1Link;
    const coursesSlot2Link = advertisementData?.courses?.[0]?.slot2Link;

    const devotionalsSlot1Link = advertisementData?.devotionals?.[0]?.slot1Link;
    const devotionalsSlot2Link = advertisementData?.devotionals?.[0]?.slot2Link;

    if (img) {
      setIsUploading(true);
      let coverRef = `/advertising/${img.name}`;
      const storageRef = sRef(storage, coverRef);

      uploadBytes(storageRef, img)
        .then((snapshot) => {
          getDownloadURL(storageRef)
            .then((imageUrl) => {
              let slot1Link, slot2Link, slot3Link;

              if (pageId === 1) {
                slot1Link = modalCtx?.details?.id === 1 ? imageUrl : homePageSlot1Link;
                slot2Link = modalCtx?.details?.id === 2 ? imageUrl : homePageSlot2Link;
                slot3Link = modalCtx?.details?.id === 3 ? imageUrl : homePageSlot3Link;
              } else if (pageId === 2) {
                slot1Link = modalCtx?.details?.id === 1 ? imageUrl : coursesSlot1Link;
                slot2Link = modalCtx?.details?.id === 2 ? imageUrl : coursesSlot2Link;
              } else if (pageId === 3) {
                slot1Link = modalCtx?.details?.id === 1 ? imageUrl : devotionalsSlot1Link;
                slot2Link = modalCtx?.details?.id === 2 ? imageUrl : devotionalsSlot2Link;
              }

              const payload = {
                pageId: pageId,
                slot1Link: slot1Link,
                slot2Link: slot2Link,
                slot3Link: slot3Link,
              };

              const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authCtx.token,
              };

              fetch(`https://api.kingdomempowered.com/v1/admin/advertisement/slot/${id}`, {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify(payload),
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                  return response.json();
                })
                .then((data) => {
                  console.log(data);
                  setIsUploading(false);
                  modalCtx.closeModal();
                  modalCtx.setFixedsize(false);
                })
                .catch((error) => {
                  console.error('Error:', error);
                });
            })
            .catch((error) => {
              console.error('Error getting image URL:', error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('no img');
    }
  };


  //open drawer
  function toggleDrawerHandler(drawer, data) {
    drawerCtx.openDrawer();
    drawerCtx.setDrawer(drawer);
  };

  // close image
  const onClose = useCallback(() => {
    setCroppedImage(null)
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }, []);

  const showTitle = () => {
    switch (imageType) {

      case "client-logo": return <h2>Upload Client Logo</h2>;

    }
  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    console.log(e.currentTarget);
    setCrop(centerAspectCrop(width, height, 4 / 1))
  }


  useEffect(() => {
    console.log('fixedsize', modalCtx.fixedsize)
  }, [modalCtx.fixedsize])

  return (
    <div className='general-image-upload-modal'>
      <div className="title">
        {showTitle()}
        <CloseRounded onClick={() => onClose()} />
      </div>
      <div className='easy-crop'>
        {/* <input type="file" onChange={(e) => setFinalImage(e.target.files)} /> */}
        {
          image ?
            isCropping ? (
              modalCtx.fixedsize ? (
                <ReactCrop
                  crop={cropConfig}
                  ruleOfThirds
                  maxHeight={modalCtx.maxHeight}
                  maxWidth={modalCtx.maxWidth}
                  minHeight={modalCtx.minHeight}
                  minWidth={modalCtx.minWidth}
                  onChange={(cropConfig) => setCropConfig(cropConfig)}
                  crossorigin="anonymous" // to avoid CORS-related problems
                >
                  <img src={image} ref={ref} />
                </ReactCrop>
              ) : (
                () => {
                  console.log('not fixed size')
                },
                <ReactCrop
                  crop={cropConfig}
                  ruleOfThirds
                  onChange={(cropConfig) => setCropConfig(cropConfig)}
                  crossorigin="anonymous" // to avoid CORS-related problems
                >
                  <img src={image} ref={ref} />
                </ReactCrop>
              )
            ) : (
              <img src={image} ref={ref} />
            )
            : <Dropzone onDrop={onDrop} accept={"image/*"} />
        }
      </div>
      <div className='crop-btns'>

        {
          !croppedImage && !isCropping ? (
            <Button onClick={() => setIsCropping(true)} disabled={!image}>Crop</Button>
          ) : (
            <Button onClick={(cropConfig) => { cropImage(cropConfig); setIsCropping(false); }} disabled={!image}>Done Cropping</Button>
          )
        }

        {croppedImage ? <Button onClick={resetCrop} style={{ width: "100px" }}>Adjust</Button> : null}

        {
          (croppedImage || image) && !isCropping
            ?
            <Button onClick={() => {
              handleUploadLocation();
              modalCtx.setFixedsize(false);
            }}>
              {isUploading ? <CircularProgress style={{ color: "#fff" }} /> : "Upload"}
            </Button>
            : null
        }

      </div>
    </div>
  );

}

export default ImageCropper;
