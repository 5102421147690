import { useState, useContext, useEffect } from 'react';
import { Modal, Box } from '@material-ui/core';

import ModalContext from '../store/ModalContext.js';

//devotional
import CreateDevotional from            './Modals/Devotional/CreateDevotional.jsx';
import EditTopicsModal from             './Modals/Devotional/EditTopicsModal.jsx';
import EditTagsModal from               './Modals/Devotional/EditTagsModal.jsx';
import EditAuthorModal from             './Modals/Devotional/EditAuthorModal.jsx';
import DeleteDevotionalModal from       './Modals/Devotional/DeleteDevotionalModal.jsx';
import DeleteDayModal from              './Modals/Devotional/DeleteDayModal.jsx';
//courses
import EditCourseAuthorModal from       './Modals/Courses/EditCourseAuthorModal.jsx';
import EditCourseTopicsModal from       './Modals/Courses/EditCourseTopicsModal.jsx';
import EditCourseTagsModal from         './Modals/Courses/EditCourseTagsModal.jsx';
import DeleteCourseModal from           './Modals/Courses/DeleteCourseModal.jsx';
//blogs
import CreateBlog from                  './Modals/Blogs/CreateBlog';
import EditBlogAuthorModal from         './Modals/Blogs/EditBlogAuthorModal.jsx';
import EditBlogTopicsModal from         './Modals/Blogs/EditBlogTopicsModal.jsx';
import EditBlogTagsModal from           './Modals/Blogs/EditBlogTagsModal.jsx';
import DeleteBlogModal from             './Modals/Blogs/DeleteBlogModal.jsx';
import PublishBlogModal from './Modals/Blogs/PublishBlogModal.jsx';
import CommentsApprove from './Modals/Blogs/CommentsApprove.jsx';

//administrator 

import EditPermissionsModal from './Modals/Administrator/EditPermissionsModal.jsx';
// other
import DeleteTagModal from              './Modals/DeleteTagModal.jsx';
import DeleteTopicModal from            './Modals/DeleteTopicModal.jsx';
import DeleteAuthorModal from           './Modals/DeleteAuthorModal.jsx';
// upload media
import UploadImageModal from            './Modals/ImageCropper/UploadImageModal';
import UploadFixedImageModal from './Modals/ImageCropper/UploadFixedImageModal.jsx';
import UploadPdfModal from              './Modals/UploadPdf/UploadPdfModal';
import DeleteBookModal from './Modals/Books/DeleteBookModal.jsx';
import EditBookModal from './Modals/Books/EditBookModal.jsx';
import AttachBlogModal from './Modals/Books/AttachBlogModal.jsx';
import BackgroundModal from './Modals/Books/BackgroundModal.jsx';
import ColorPicker from './Modals/Books/ColorPicker.jsx';
import PublishEpisodeModal from './Modals/Courses/PublishEpisodeModal.jsx';
import PublishCourseModal from './Modals/Courses/PublishCourseModal.jsx';
import UploadVideoModal from './Modals/UploadVideo/UploadVideoModal.jsx';

//add featured entity
import AddFeaturedEntity from './Modals/AddFeaturedEntity.jsx';
import UploadFilesModal from './Modals/UploadVideo/UploadFilesModal.jsx';
import DeleteFileModal from './Modals/SupportingFiles/DeleteFileModal.jsx';
import FileNameEditModal from './Modals/SupportingFiles/FileNameEditModal.jsx';
import BuildSiteModal from './Modals/BuildSiteModal.jsx';


// const DetailsModal = ({ lightboxModalProps }) => {
const DetailsModal = () => {
  // const { setToUpdate, goals, currentGoalID, toEdit, openModal, handleCloseModal, closeGoalMenu, goalToDelete, goalAction } = lightboxModalProps;

  const modalCtx = useContext(ModalContext);

  const boxStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '70vw',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
    maxHeight: '90vh',
  };

  const currentModal = () => {
    switch (modalCtx.modal) {

      //devotional
      case "createDevotional": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><CreateDevotional /></Box></Modal>;
      case "confirm-delete-devotional": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteDevotionalModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "confirm-delete-day": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteDayModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "confirm-delete-tag": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteTagModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "confirm-delete-topic": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteTopicModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "confirm-delete-author": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteAuthorModal modalDetails={modalCtx.details} /></Box></Modal>;
      //file upload
      case "upload-image": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><UploadFixedImageModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "upload-pdf": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><UploadPdfModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "upload-video": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><UploadVideoModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "upload-supporting-files": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><UploadFilesModal modalDetails={modalCtx.details} /></Box></Modal>;
      
      //SUpporting Files
      case "confirm-delete-file": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteFileModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "file-name-edit": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><FileNameEditModal modalDetails={modalCtx.details} /></Box></Modal>;
      
      
      
      //other
      case "edit-author": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditAuthorModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-topics": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditTopicsModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-tags": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditTagsModal modalDetails={modalCtx.details} /></Box></Modal>;
      //course
      case "confirm-delete-course": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteCourseModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-course-author": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditCourseAuthorModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-course-topics": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditCourseTopicsModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-course-tags": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditCourseTagsModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "publish-episode": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><PublishEpisodeModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "publish-course": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><PublishCourseModal modalDetails={modalCtx.details} /></Box></Modal>;

      //administrator
      case "edit-permissions": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditPermissionsModal modalDetails={modalCtx.details} /></Box></Modal>;
      //books
      case "confirm-delete-book": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteBookModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-book": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditBookModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "attach-blog": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><AttachBlogModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "choose-background-color": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><BackgroundModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "color-picker": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><ColorPicker modalDetails={modalCtx.details} /></Box></Modal>;

      //blogs
      case "confirm-publish-blog": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><PublishBlogModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "confirm-delete-blog": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteBlogModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-blog-author": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditBlogAuthorModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-blog-topics": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditBlogTopicsModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "edit-blog-tags": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditBlogTagsModal modalDetails={modalCtx.details} /></Box></Modal>;
      case "blog-comment-approve": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><CommentsApprove modalDetails={modalCtx.details} /></Box></Modal>;
      case "add-featured-entity": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><AddFeaturedEntity modalDetails={modalCtx.details} /></Box></Modal>;
      
      //build site
      case "build-site": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><BuildSiteModal modalDetails={modalCtx.details} /></Box></Modal>;

      default: return null;
    }
  }

  return (
    <div>{currentModal()}</div>
  );

}

export default DetailsModal;
