import React, { useContext, useState, useEffect } from 'react';
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext.js';

import 'react-quill/dist/quill.snow.css';
import { Add, CloseRounded, KeyboardArrowDownRounded } from '@mui/icons-material';

const EditTagsModal = ({ modalDetails }) => {


  // console.log("modalDetails", modalDetails);

  //context
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tag, setTag] = useState('');
  const [searchTag, setSearchTag] = useState('')
  const [addTagTitle, setAddTagTitle] = useState('')

  const [showTagResults, setShowTagResults] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)


  //effect
  useEffect(() => {
    getAllTags()
    setSelectedTags(modalDetails.tags)
  }, [])

  //I had to do this effect because the state doesn't mutate immediately
  useEffect(() => {
    addNewlyCreatedTag(addTagTitle)
  }, [tags, addTagTitle])

  //change visibility and get all tags when searchTag is changed
  useEffect(() => {
    if (searchTag.length > 0) {
      setShowTagResults(true)
    } else {
      setShowTagResults(false)
    }
    getAllTags()
  }, [searchTag])


  // submit tag to db
  const submitEditTag = (id) => {

    if (authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + '/v1/admin/devotionals/tags', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({
          tagId: id,
          id: modalDetails.id
        })
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status == "success") {
          console.log(data)
          getAllTags()
        } else {
          console.error("Error while editing devotional...");
        }
      }).catch((err) => console.error("Error while editing devotional...", err));
    }

  }

  // get all tags
  const getAllTags = async (title) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v1/tags`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const res = await response.json();
      if (res.status == 'success') {
        console.log(res)
        setTags(res?.data)
        setAddTagTitle(title)
      }
    } catch (err) {
      console.log(err);
    }
  }

  //deleting tag
  const DeleteTag = async (devoId, id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/devotionals/${devoId}/tags/${id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const res = await response.json();
      if (res.status == 'success') {
      }
    } catch (err) {
      console.log(err);
    }
  }

  // create new tag
  const submitCreateTag = async (title) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v1/tags`,
        {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          body: JSON.stringify({
            title,
          })
        })

      const res = await response.json();
      if (res.status == 'success') {
        getAllTags(title)
      }
    } catch (err) {
      console.log(err);
    }
  }

  //this function will add the newly created tag to the devotional without selecting it twice
  const addNewlyCreatedTag = (title) => {
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].title == title) {
        submitEditTag(tags[i].id)
        setSelectedTags(prev => [...prev, tags[i]]);
        setAddTagTitle('')
        setSearchTag('')
      }
    }
  }

  //check if tag exist
  const handleCreateNewTag = (title) => {
    if (tags.some(tag => tag.title == title)) {
      setOpenSnackbar(true)
      return;
    } else {
      submitCreateTag(title)
    }
  }

  //add selected tag to db function and array
  const handleSelectTag = (tag) => {
    console.log(tag)
    if (!selectedTags.some(el => el.id === tag.id)) {
      submitEditTag(tag.id)
      setSelectedTags(prev => [...prev, tag]);
    } else {
      return
    }
  }

  //filter and delete tag from db and state
  const handleFilterArray = (id) => {
    DeleteTag(modalDetails.id, id)
    const newArr = selectedTags.filter((arr) => {
      return arr.id !== id
    })
    setSelectedTags(newArr)
  }

  //set search tag string
  const handleSearchTag = (e) => {
    setSearchTag(e.target.value)
  }

  //handling tag visibility
  const handleShowTagResult = () => {
    if (showTagResults) {
      setShowTagResults(false)
    } else {
      setShowTagResults(true)
    }
  }

  console.log(selectedTags)

  return (
    <>
      <div className="edit-tags-container">
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert severity="info" sx={{ width: '100%' }}>
            Tag already exist.
          </Alert>
        </Snackbar>
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Edit Devotional Tags</h1>
        </header>

        <section>
          {/* <span className='devotional-tags-text' >Devotional tags:</span> */}
          {/* <div className="tags-input-container">
            { tags.map((tag, index) => (
                <div className="tag-item" key={index}>
                    <span className="text">{tag.title}</span>
                    <span className="close" onClick={() => removeTag(index)}>&times;</span>
                </div>
            )) }
            <input onKeyDown={handleKeyDown} type="text" className="tags-input" placeholder="Press enter to add new tag..." />
          </div> */}

          {/* <span className='all-tags-text' >All tags:</span>
          <div className="all-tags-container">
            { tags.map((tag, index) => (
              <div className="tag-item" key={index}>
                  <span className="text">{tag.title}</span>
                  <span className="add" onClick={() => removeTag(index)}>+</span>
              </div>
            )) }
            <input onKeyDown={handleKeyDown} type="text" className="tags-input" placeholder="Press enter to add new tag..." />
          </div> */}

          {/* <FormControl fullWidth >
            <InputLabel id="select-author">Tags</InputLabel>
            <Select
              labelId="select-author"
              value={tag}
              label="Tags"
              onChange={handleChangeTag}
              className='select-author'
              disableEnforceFocus
            >
              {
                tags?.map((author, i) => {
                  return <MenuItem key={i} onClick={() => handleSelectTag(author)} value={author.title}>{author.title}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <div className="author-list">
            {
              selectedTags.map((tag, i) => (
                <span key={i} className='author-tag'>{tag.title}<CloseRounded className='remove-author' onClick={() => handleFilterArray(tag.id)} /></span>
              ))
            }
          </div> */}

          <div className="search-tag-container">
            <input type="text" placeholder='Search for tag...' value={searchTag} onChange={handleSearchTag} />
            <KeyboardArrowDownRounded className='drop-down-btn' onClick={() => handleShowTagResult()} style={showTagResults ? { transform: 'rotateZ(180deg)' } : null} />
          </div>
          <div className="tags-menu" style={showTagResults ? null : { display: 'none' }} >
            <ul>
              {
                tags?.filter((tag) => tag.title.toLowerCase().includes(searchTag.toLowerCase())).map((tag, i) => {
                  return <li key={i} onClick={() => handleSelectTag(tag)}>{tag.title}</li>
                })
              }
              {searchTag.length == 0 ? null : <li className='add-new-tag' onClick={() => handleCreateNewTag(searchTag)}><Add /> &nbsp; Add new tag &nbsp; <span className='tag-text'>"{searchTag}"</span></li>}
            </ul>
          </div>
          <div className="author-list">
            {
              selectedTags.map((tag, i) => (
                <span key={i} className='author-tag'>{tag.title}<CloseRounded className='remove-author' onClick={() => handleFilterArray(tag.id)} /></span>
              ))
            }
          </div>
          <div className="save-changes-btn">
            <Button variant="contained" component="label" disabled={false}
              onClick={(e) => modalCtx.closeModal()}>Save Changes
            </Button>
            <Button className='close-btn' variant="contained" component="label" disabled={false}
              onClick={(e) => modalCtx.closeModal()}>Close
            </Button>
          </div>
        </section>
      </div>
    </>
  )
}

export default EditTagsModal;
