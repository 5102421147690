import React, { useState, useContext, useEffect } from 'react'

import { CloseRounded } from '@mui/icons-material'
import { Alert, Button, Snackbar } from '@mui/material'

import Dropzone from './Dropzone'

import AuthContext from '../../../store/AuthContext.js';
import ModalContext from '../../../store/ModalContext.js';

const UploadPdf = ({ modalDetails }) => {

  console.log(modalDetails)

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const [pdfUploading, setPdfUploading] = useState(false)

  const [pdf, setPdf] = useState()
  const [openSnackbar, setOpenSnackbar] = useState(false)

  useEffect(() => {
    if (modalDetails.pdf) {
      setPdf({ name: modalDetails.pdf })
    }
  }, [modalDetails])

  // get dropped pdf
  const onDrop = (acceptedFiles) => {
    console.log(acceptedFiles[0])
    if (acceptedFiles[0].type == "application/pdf") {
      setPdf(acceptedFiles[0])
    } else {
      setOpenSnackbar(true);
    }
  }

  const removePdf = () => {
    setPdf('')
  }

  const handleUploadPdf = () => {
    if (pdf instanceof File) {
      updatePdf()
    }
  }

  const updatePdf = async () => {
    
    setPdfUploading(true)
    console.log(pdf)
    const data = new FormData()
    data.append('file', pdf)

    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v1/admin/devotionals/' + modalDetails.id + '/uploadPDF',
        {
          method: 'POST',
          mode: 'cors',
          headers: { 'Authorization': 'Bearer ' + authCtx.token },
          body: data
        })

      const response = await res.json();
      if (response.status === 'success') {
        setPdfUploading(false)
        modalCtx.closeModal()
        console.log('pdf uploaded')
      }

    } catch (err) {

      console.log(err);
      setPdfUploading(false)

    }
  }

  return (
    <div className='upload-pdf-container' >

      {pdf ? null : <Dropzone onDrop={onDrop} accept={"application/pdf"} />}
      {pdf ?
        <div className="pdf-container">
          <p>
            {pdf ? pdf.name : null}
          </p>
          {/* {pdf ? <CloseRounded className='close-btn' onClick={() => setPdf(null)} /> : null} */}
        </div>
        :
        null
      }
      <div className="btn-container">

        {pdf ?
          <Button className='upload-pdf-btn' onClick={() => handleUploadPdf()} >
            {pdfUploading ? 'uploading pdf...' : 'Upload Pdf'}  
          </Button>
          :
          null
        }

        {pdf ?
          <Button className='upload-pdf-btn' onClick={() => removePdf()} style={{ background: "#dd0000" }} >Remove pdf</Button>
          :
          null
        }

      </div>
      <CloseRounded className='close-modal-btn' onClick={() => modalCtx.closeModal()} />

      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
        <Alert severity="info" sx={{ width: '100%' }}>
          File type must be pdf.
        </Alert>
      </Snackbar>
    </div>
  )
}

export default UploadPdf