import { useContext, useState } from 'react';
import { Button, TextField } from '@mui/material';
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext';
import { Close } from '@mui/icons-material';
import Picker from '../../../assets/img/picker.svg'
import { SketchPicker } from 'react-color';
import ImageCropper from '../ImageCropper/UploadImageModal';

const BackgroundModal = ({ modalDetails }) => {


  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const [visible, setVisible] = useState("color");

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }
  console.log(modalDetails.id.affiliate_code);
  const [createDevotionalObj, setCreateDevotionalObj] = useState({
    blog_id: modalDetails.id.id, title: modalDetails.id.title, affiliate_code: modalDetails.id.affiliate_code,

  });
  const updateBook = () => {


    if (authCtx.isLoggedIn) {
      console.log("this is modal id", modalDetails.id.id);
      console.log("this is color", color);

      fetch(process.env.REACT_APP_API_URI + '/v1/admin/books/' + modalDetails.id.id + '/uploadBgColor ', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
        body: JSON.stringify({ bg_color: color })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status == "success") {
          // navigate("/devotionals", { replace: true });
          // drawerCtx.closeDrawer();
          modalCtx.closeModal();
          // window.location.reload();
        } else {
          console.error("Error while updating books...");
          // setSnackbarMessage(data.message);
          // setOpenSnackbar(true);
        }
      }).catch((err) => {
        console.error("Error while upadting books...", err);
        handleCloseModal();
      });
      console.log("update book id", modalDetails.id.id);
    }
  }

  const openColorPicker = (id) => {
    modalCtx.setDetails('color-picker', { id });
    modalCtx.openModal();
  }

  //color picker

  const [color, setColor] = useState('#fff')


  const handleChangeComplete = (color) => {
    setColor(color.hex);

  };

  //handle visiblity
  const [imagepicker, setImagePicker] = useState("");
  const [colorPicker, setColorPicker] = useState("cancel-btn active");

  const handleVisiblity = (color) => {
    setVisible(color);
    if (color == "color") {
      setColorPicker("btn active");
      setImagePicker("btn");
    } else {
      setColorPicker("cancel-btn");
      setImagePicker("btn active");
    }

  }


  return (
    <div className="choose-background-modal">
      <div className='title'><p>Choose Book Background Color or Image</p> <Close onClick={(e) => { handleCloseModal() }} /></div>
      <div>
        <Button className={colorPicker} onClick={(e) => { handleVisiblity("color") }}>Color Picker</Button>
        <Button className={imagepicker} onClick={() => handleVisiblity("picker")}>Image Cropper</Button>
      </div>

      {visible == "color" ?
        <>
          <div >
            <p>Background Color</p>
            <div className='background' style={{ backgroundColor: color }}>

            </div>
            <SketchPicker className='sketch' color={color}
              onChangeComplete={(e) => { handleChangeComplete(e) }} />

          </div>
          <div className="footer">
            <Button fullWidth onClick={(e) => { handleCloseModal() }}>Cancel</Button>
            <Button fullWidth onClick={() => updateBook()}>Save</Button>
          </div>
        </>
        : <ImageCropper id={modalDetails.id.id} />}



    </div>
  )
}

export default BackgroundModal;
