import React, { useContext, useState, useEffect, useRef } from 'react';

import ModalContext from '../../../store/ModalContext';
import AuthContext from '../../../store/AuthContext';

//firebase
import { ref, onValue, orderByChild, limitToLast, set, update, remove } from "firebase/database";
import { database } from "../../FirebaseConfig.jsx";

//mui
import { CheckRounded, Close, CloseRounded, DeleteForeverRounded, DeleteOutlineRounded, Favorite, FavoriteBorder, RemoveCircleOutlineRounded } from '@mui/icons-material';
import { Button } from '@mui/material';

//components
import moment from 'moment';

//assets
import Approve from '../../../assets/img/modal/approve.svg'
import DisApprove from '../../../assets/img/modal/disapprove.svg'
import DeleteComment from '../../../assets/img/modal/delete-comment.svg'

const CommentsApprove = () => {

  const InputRef = useRef(null)

  //firebase db
  const db = database;

  //context
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  //states
  const [feedId, setFeedId] = useState()
  const [comments, setComments] = useState()
  const [commentsToShow, setCommentsToShow] = useState(2)
  const [commentsToShowID, setCommentsToShowID] = useState()
  const [commentText, setCommentText] = useState('')
  const [replyCommentId, setReplyCommentId] = useState(0)
  const [replyUsername, setReplyUsername] = useState('')
  //boolean states
  const [replyMode, setReplyMode] = useState(false)


  //effect
  useEffect(() => {
    addPostListener()
    setFeedId(modalCtx.details.timestamp)
  }, [])

  useEffect(() => {
    if (replyMode) {
      InputRef.current.focus()
    }
  }, [replyMode, replyCommentId])


  //functions

  //get posts from firebase
  const addPostListener = () => {
    const dbRef = ref(
      db,
      `communityComments/${modalCtx.details.id}`,
      orderByChild('timestamp'),
      limitToLast(50)
    )
    onValue(dbRef, (snapshot) => {
      setComments(snapshotToArray(snapshot))
    })
  }

  const snapshotToArray = (snapshot) => {
    const returnArr = []
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val()
      returnArr.push(item)
    })
    return returnArr.reverse()
  }

  // modal handler
  const closeHandle = () => {
    modalCtx.closeModal();
    modalCtx.setDetails(null, null)
  }

  //return array from object
  const replyObjectToArray = (obj) => {
    if (obj) {
      const arrayOfObj = Object.keys(obj).map(key => obj[key])
      return arrayOfObj.reverse()
    }
  }

  //count likes
  const countLikes = (obj) => {
    if (obj) {
      const likes = Object.keys(obj).length
      return likes ? likes : 0
    }
  }

  //viewing more replies
  const handleViewMoreReplies = (count, id) => {
    setCommentsToShowID(id)
    setCommentsToShow(prev => prev + count)
  }

  // get relative time
  const timestampToDate = (t) => {
    var time = new Date(t)
    let relTime = moment(time).fromNow()
    // var lastIndex = relTime.lastIndexOf(" ");
    return relTime
  }

  // get a slice length for replies array
  const sliceArrayHandler = (array, index) => {
    let length = array ? array.length : 0
    if (commentsToShowID === index) {
      return length
    } else {
      return 2
    }
  }

  //  //handle getting comment likes
  //  const handleGetCommentReplyLikes = (likes, commentId, timeStamp) => {
  //   let arrayOfObj = []
  //   let likeIcon 
  //   if (likes) {
  //     arrayOfObj = Object.entries(likes).map((e) => ( e[1] ));
  //   }

  //   if(arrayOfObj){
  //     for (let i = 0; i < arrayOfObj.length; i++) {
  //       const element = arrayOfObj[i];
  //       if(element.userId == authCtx.userId){
  //         likeIcon = <Favorite className='likes-icon' style={{color: "#ff0000"}} onClick={() => disLikeCommentReply(commentId, timeStamp)} />
  //       }
  //     }
  //   }
  //   return likeIcon
  // }

  const disLikeCommentReply = (commentId, replyId) => {

    remove(ref(db, `waves/${feedId}/comments/${commentId}/replies/${replyId}/commentLikes`))
      .then(() => {
        console.log('comment like removed')
      })
      .catch((error) => {
        console.log(error)
      });
  }

  // handle delete comment
  const deleteComment = (blogId, commentId) => {

    remove(ref(db, `communityComments/${blogId}/${commentId}`))
      .then(() => {
        console.log('comment like removed')
      })
      .catch((error) => {
        console.log(error)
      });

  }

  // handle delete comment reply
  const deleteCommentReply = (blogId, commentId, replyComment) => {


    remove(ref(db, `communityComments/${blogId}/${commentId}/replies/${replyComment}`))
      .then(() => {
        console.log('comment like removed')
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const approveCommentAndUser = (blogId, commentId, userId) => {

    try {
      console.log('updating slug script')

      update(ref(db, `communityComments/${blogId}/${commentId}`), { "approved": true })
        .then(() => {

          console.log('comment approved')
          fetch(process.env.REACT_APP_API_URI + `/v1/admin/blogs/${blogId}/comments/${commentId}/approve`, {
            method: 'PUT',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
            body: JSON.stringify({ "userId": userId })
          })
            .then(response => {
              return response.json();
            }).then(data => {
              if (data.status == 'success') {
                console.log('user approve data ', data)
              }
            }).catch(err => console.error(err));

        })
        .catch((error) => {
          console.log(error)
        });

    } catch (err) {
      console.log(err);
    }
  }

  const disApproveCommentAndUser = (blogId, commentId, userId) => {

    try {
      console.log('updating slug script')

      update(ref(db, `communityComments/${blogId}/${commentId}`), { "approved": false })
        .then(() => {

          console.log('comment dis-approved')
          // fetch(process.env.REACT_APP_API_URI + `/v1/admin/blogs/${blogId}/comments/${commentId}/approve`, {
          //   method: 'PUT',
          //   mode: 'cors',
          //   headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
          //   body: JSON.stringify({ "userId": userId })
          // })
          //   .then(response => {
          //     return response.json();
          //   }).then(data => {
          //     if (data.status == 'success') {
          //       console.log('user approve data ', data)
          //     }
          //   }).catch(err => console.error(err));

        })
        .catch((error) => {
          console.log(error)
        });

    } catch (err) {
      console.log(err);
    }
  }


  const approveCommentReplyAndUser = (blogId, commentId, userId, replyId) => {

    try {
      console.log('updating slug script')

      update(ref(db, `communityComments/${blogId}/${commentId}/replies/${replyId}`), { "approved": true })
        .then(() => {

          console.log('comment reply approved')
          fetch(process.env.REACT_APP_API_URI + `/v1/admin/blogs/${blogId}/comments/${commentId}/approve`, {
            method: 'PUT',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
            body: JSON.stringify({ "userId": userId })
          })
            .then(response => {
              return response.json();
            }).then(data => {
              if (data.status == 'success') {
                console.log('user approve data ', data)
              }
            }).catch(err => console.error(err));

        })
        .catch((error) => {
          console.log(error)
        });

    } catch (err) {
      console.log(err);
    }
  }

  const disApproveCommentReplyAndUser = (blogId, commentId, userId, replyId) => {

    try {
      console.log('updating slug script')

      update(ref(db, `communityComments/${blogId}/${commentId}/replies/${replyId}`), { "approved": false })
        .then(() => {

          console.log('comment reply dis-approved')
        //   fetch(process.env.REACT_APP_API_URI + `/v1/admin/blogs/${blogId}/comments/${commentId}/approve`, {
        //     method: 'PUT',
        //     mode: 'cors',
        //     headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        //     body: JSON.stringify({ "userId": userId })
        //   })
        //     .then(response => {
        //       return response.json();
        //     }).then(data => {
        //       if (data.status == 'success') {
        //         console.log('user approve data ', data)
        //       }
        //     }).catch(err => console.error(err));

        })
        .catch((error) => {
          console.log(error)
        });

    } catch (err) {
      console.log(err);
    }
  }


  //  //handle getting comment likes
  //  const handleGetCommentLikes = (likes, timeStamp) => {
  //   let arrayOfObj = []
  //   let likeIcon 
  //   if (likes) {
  //     arrayOfObj = Object.entries(likes).map((e) => ( e[1] ));
  //   }

  //   if(arrayOfObj){
  //     for (let i = 0; i < arrayOfObj.length; i++) {
  //       const element = arrayOfObj[i];
  //       if(element.userId == authCtx.userId){
  //         likeIcon = <Favorite className='likes-icon' style={{color: "#ff0000"}} onClick={() => disLikeComment(timeStamp)} />
  //       }
  //     }
  //   }
  //   return likeIcon
  // }

  // const disLikeComment = (commentId) => {

  //   remove(ref(db, `waves/${feedId}/comments/${commentId}/commentLikes`))
  //   .then(() => {
  //     console.log('comment like removed')
  //   })
  //   .catch((error) => {
  //     console.log(error)
  //   });
  // }

  // // liking a comment
  // const likeComment = (commentId) => {
  //   const timestamp = Date.now();
  //   const userId = authCtx.userId
  //   const updateObject = {}

  //   updateObject[userId] = {
  //     'username': authCtx.userName,
  //     'avatar': authCtx.avatarRef,
  //     'timeStamp': timestamp,
  //     'userId': authCtx.userId,
  //   }

  //   update(ref(db, `waves/${feedId}/comments/${commentId}/commentLikes`), updateObject)
  //     .then(() => {
  //       console.log('comment liked')
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     });
  // }

  // const likeCommentReply = (commentId, replyId) => {
  //   const timestamp = Date.now();
  //   const userId = authCtx.userId
  //   const updateObject = {}

  //   updateObject[userId] = {
  //     'username': authCtx.userName,
  //     'avatar': authCtx.avatarRef,
  //     'timeStamp': timestamp,
  //     'userId': authCtx.userId,
  //   }
  //   update(ref(db, `waves/${feedId}/comments/${commentId}/replies/${replyId}/commentLikes`), updateObject)
  //     .then(() => {
  //       console.log('comment liked')
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     });
  // }

  // // get comment text
  // const handleCommentInput = (e) => {
  //   let text = e.target.value
  //   setCommentText(text)
  // }

  // // posting the comment
  // const handleCommentSend = (e) => {

  //   const timestamp = Date.now();
  //   const updateObject = {}

  //   updateObject[timestamp] = {
  //     'username': authCtx.userName,
  //     'avatar': authCtx.avatarRef,
  //     'timeStamp': timestamp,
  //     'userId': authCtx.userId,
  //     'comment': commentText
  //   }
  //   console.log(updateObject)
  //   console.log(`waves/${feedId}/comments`)


  //   if (e.keyCode === 13 && commentText) {
  //     update(ref(db, `waves/${feedId}/comments`), updateObject)
  //       .then(() => {
  //         console.log('post comment added')
  //         setCommentText('')
  //       })
  //       .catch((error) => {
  //         console.log(error)
  //       });

  //   }
  // }

  // const handleCommentSendBtn = () => {

  //   const timestamp = Date.now();
  //   const updateObject = {}

  //   updateObject[timestamp] = {
  //     'username': authCtx.userName,
  //     'avatar': authCtx.avatarRef,
  //     'timeStamp': timestamp,
  //     'userId': authCtx.userId,
  //     'comment': commentText
  //   }
  //   console.log(updateObject)
  //   console.log(`waves/${feedId}/comments`)

  //   if (commentText) {
  //     update(ref(db, `waves/${feedId}/comments`), updateObject)
  //       .then(() => {
  //         console.log('post comment added')
  //         setCommentText('')
  //       })
  //       .catch((error) => {
  //         console.log(error)
  //       });

  //   }
  // }

  // // posting the comment reply
  // const handleCommentSendReply = (e, commentId) => {

  //   const timestamp = Date.now();
  //   const updateObject = {}

  //   updateObject[timestamp] = {
  //     'username': authCtx.userName,
  //     'avatar': authCtx.avatarRef,
  //     'timeStamp': timestamp,
  //     'userId': authCtx.userId,
  //     'comment': commentText
  //   }

  //   if (e.keyCode === 13 && commentText) {
  //     update(ref(db, `waves/${feedId}/comments/${commentId}/replies`), updateObject)
  //       .then(() => {
  //         console.log('post comment added')
  //         setCommentText('')
  //         setReplyMode(false)
  //         setReplyUsername('')
  //       })
  //       .catch((error) => {
  //         console.log(error)
  //       });

  //   }
  // }

  // const handleCommentSendReplyBtn = (commentId) => {

  //   const timestamp = Date.now();
  //   const updateObject = {}

  //   updateObject[timestamp] = {
  //     'username': authCtx.userName,
  //     'avatar': authCtx.avatarRef,
  //     'timeStamp': timestamp,
  //     'userId': authCtx.userId,
  //     'comment': commentText
  //   }

  //   if (commentText) {
  //     update(ref(db, `waves/${feedId}/comments/${commentId}/replies`), updateObject)
  //       .then(() => {
  //         console.log('post comment added')
  //         setCommentText('')
  //         setReplyMode(false)
  //         setReplyUsername('')
  //       })
  //       .catch((error) => {
  //         console.log(error)
  //       });

  //   }
  // }

  // const commentReplyMode = (commentId, name) => {
  //   setCommentText('')
  //   setReplyMode(true)
  //   setReplyUsername(name)
  //   setReplyCommentId(commentId)
  // }

  // const cancelReply = () => {
  //   setCommentText('')
  //   setReplyMode(false)
  //   setReplyUsername('')
  //   setReplyCommentId(0)
  // }

  //console logs
  // console.log(modalCtx.details)
  console.log(comments)
  // console.log("replyMode ", replyMode)
  // console.log("replyCommentId ", replyCommentId)

  return (
    <>
      <div className='post-comments'>
        <div className="comments-header">
          <h2>Comments</h2>
          <Close className='close' onClick={() => closeHandle()} />
        </div>
        <div className="comments">
          {comments?.length !== 0
            ? comments?.map((comment, index) => (
              <div className="comment" key={index}>
                <div className="comment-avatar">
                  {/* <AvatarChatImage avatarRef={comment.avatar} /> */}
                </div>
                <div className="comment-details">
                  <span className="username">
                    {comment.username}
                    <span style={{ fontWeight: '400', marginLeft: '1rem', fontSize: '12px' }}>
                      {timestampToDate(comment.timeStamp)}
                    </span>
                  </span>
                  <div className="comment-text">
                    {comment.comment}
                  </div>

                  <div className="comment-replies">
                    {replyObjectToArray(comment.replies) &&
                      replyObjectToArray(comment.replies)?.slice(0, sliceArrayHandler(replyObjectToArray(comment.replies), index))?.map((reply, i) => (
                        <div className="reply-comment" key={i}>
                          <div className="reply-comment-avatar">
                            {/* <AvatarChatImage avatarRef={reply.avatar} /> */}
                          </div>
                          <div className="reply-comment-details">
                            <span className="username">
                              {reply.username}
                              <span style={{ fontWeight: '400', marginLeft: '1rem', fontSize: '12px' }}>
                                {timestampToDate(reply.timeStamp)}
                              </span>
                            </span>
                            <div className="reply-comment-text">
                              {reply.comment}
                            </div>
                            {/* <div className="comment-actions">
                              <div className="comment-reply-btn" onClick={() => commentReplyMode(comment.timeStamp, comment.username)}>
                                Reply
                              </div>
                              <div className='delete-comment-container' style={reply.userId == authCtx.userId ? null : {display: 'none'}}>
                              {
                                reply.userId == authCtx.userId
                                ? <DeleteForeverRounded className='delete-comment' onClick={() => deleteCommentReply(comment.timeStamp, reply.timeStamp)} />
                                : null
                              }
                              </div>
                            </div> */}
                            <div className="reply-comment-reply">
                            </div>
                          </div>
                          <div className="reply-comment-approve-actions">
                            <CheckRounded  onClick={() => approveCommentReplyAndUser(reply.id, comment.timeStamp, reply.userId, reply.timeStamp)}  className={reply.approved ? 'approve-icon approved' : 'approve-icon'} />
                            <CloseRounded onClick={() => disApproveCommentReplyAndUser(reply.id, comment.timeStamp, reply.userId, reply.timeStamp)} className={reply.approved ? 'approve-icon' : 'approve-icon not-approved'} />
                            <DeleteOutlineRounded onClick={() => deleteCommentReply(reply.id, comment.timeStamp, reply.timeStamp)} />
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  {
                    ((replyObjectToArray(comment.replies) && replyObjectToArray(comment.replies)?.length) > 2 && (replyObjectToArray(comment.replies) && replyObjectToArray(comment.replies)?.length - sliceArrayHandler(replyObjectToArray(comment.replies), index) > 0))
                      ? <div className="show-more-comment-btn" onClick={() => handleViewMoreReplies(replyObjectToArray(comment.replies)?.length - 2, index)} >
                        View {replyObjectToArray(comment.replies) ? replyObjectToArray(comment.replies)?.length - 2 : null} more replies
                      </div>
                      : null
                  }
                </div>
                <div className="comment-approve-actions">
                  <CheckRounded onClick={() => approveCommentAndUser(comment.id, comment.timeStamp, comment.userId)} className={comment.approved ? 'approve-icon approved' : 'approve-icon'} />
                  <CloseRounded onClick={() => disApproveCommentAndUser(comment.id, comment.timeStamp, comment.userId)} className={comment.approved ? 'approve-icon' : 'approve-icon not-approved'} />
                  <DeleteOutlineRounded onClick={() => deleteComment(comment.id, comment.timeStamp)} />
                </div>
              </div>
            ))
            : <p className='no-comments'>No Comments.</p>
          }
        </div>
        {/* <div className="add-comment">
          {
            replyMode 
            ? <div className="reply-user-details">
                <span>Replying to @<strong>{ replyUsername == authCtx.userName ? 'Yourself' : replyUsername }</strong></span>
                <CloseRounded className='close-reply' onClick={() => cancelReply()} />
              </div>
            : null
          }
          
          <div className="user-avatar">
            <AvatarImage />
          </div>
          {
            replyMode 
            ? <input ref={InputRef} type="text" className='comment-input' placeholder='Write comment...' value={commentText} onKeyDown={(e) => handleCommentSendReply(e, replyCommentId)} onChange={(e) => handleCommentInput(e)} />
            : <input type="text" className='comment-input' placeholder='Write comment...' value={commentText} onKeyDown={(e) => handleCommentSend(e)} onChange={(e) => handleCommentInput(e)} />
          }
          <Button 
          className='post-btn' 
          onClick={
            replyMode 
            ? () => handleCommentSendReplyBtn(replyCommentId) 
            : () => handleCommentSendBtn() 
          }
          >Post</Button>
        </div> */}
      </div>
    </>
  )
}

export default CommentsApprove;
