import { useContext } from 'react';
import { Button } from '@mui/material';
import AuthContext from '../../store/AuthContext';
import ModalContext from '../../store/ModalContext';

const DeleteTagModal = ({ modalDetails }) => {

  // console.log("delete modal props", modalDetails);
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }

  const deleteTag = (id) => {
		if(authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + '/v1/admin/tags/' + id + '/delete', {
        method: 'DELETE',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if(data.status == "success") {
          modalCtx.closeModal();
        } else {
          console.error("Error while deleting tag...");
        }
      }).catch((err) => {
        console.error("Error while deleting tag...", err);
        handleCloseModal();
      });
      console.log("delete tag id", id);
		}
	}

  return (
    <div className="modal">
      
      <p>Are you sure you want to delete this Tag?</p>
      
      <div className="footer">
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>No</Button>
        <Button className="confirm-btn" onClick={() => deleteTag(modalDetails.id)}>Yes</Button>
      </div>
    </div>
  )
}

export default DeleteTagModal;
