import React, { useContext, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import AuthContext from '../../store/AuthContext';
import ModalContext from '../../store/ModalContext';
import DrawerContext from '../../store/DrawerContext';

const CreateEditReviewDrawer = ({ drawerDetails }) => {
  
  const authCtx		= useContext(AuthContext);
  const modalCtx	= useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  // console.log("EditTopicDrawer Props", props);
  console.log("CreateEditReviewDrawer Props", drawerDetails);
  const { method, reviewDetails } = drawerDetails;

  console.log(reviewDetails);

  // const [createTopicDrawerObj, setCreateTopicDrawerObj] = useState({ title: drawerDetails?.title || "" });

  const [category, setCategory]       = useState(reviewDetails?.entity_type || "");
  const [categoryId, setCategoryId]   = useState(reviewDetails?.entity_id || 1);
  const [userId, setUserId]           = useState(reviewDetails?.user_id || localStorage.userId);
  const [review, setReview]           = useState(reviewDetails?.review || "");
  const [reviewTitle, setReviewTitle] = useState(reviewDetails?.reviewTitle || "");
  const [rating, setRating]           = useState(reviewDetails?.rating || "");
  const [status, setStatus]           = useState(reviewDetails?.status || "");

  console.log(method);
  console.log(userId);

  const submitReview = (e) => {
    e.preventDefault();
    
    if(authCtx.isLoggedIn) {

      // const { title } = createTopicDrawerObj;
      const jsonBody = {
        entityType: category,
        entityId:  categoryId,
        userId: userId,
        reviewTitle: reviewTitle,
        review: review,
        rating: rating
      };
      console.log(jsonBody);

      if(method === "create") {
        // fetch(process.env.REACT_APP_API_URI + '/v1/reviews', {
        //   method: 'POST',
        //   mode: 'cors',
        //   headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
        //   body: JSON.stringify(jsonBody)
        // }).then(response => {
        //   return response.json();
        // }).then(data => {
        //   console.log(data);
        //   if(data.status == "success") {
        //     // navigate("/devotionals", { replace: true });
        //     drawerCtx.closeDrawer();
        //     // window.location.reload();
        //   } else {
        //     console.error("Error while posting review...");
        //     // setSnackbarMessage(data.message);
        //     // setOpenSnackbar(true);
        //   }
        // }).catch((err) => console.error(err));
      }
      else if(method === "edit" && reviewDetails?.id) {
        // change endpoint to edit reviews 
        // fetch(process.env.REACT_APP_API_URI, {
        //   method: 'PUT',
        //   mode: 'cors',
        //   headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
        //   body: JSON.stringify(jsonBody)
        // }).then(response => {
        //   return response.json();
        // }).then(data => {
        //   console.log(data);
        //   if(data.status == "success") {
        //     // navigate("/devotionals", { replace: true });
        //     drawerCtx.closeDrawer();
        //     // window.location.reload();
        //   } else {
        //     console.error("Error while editing review...");
        //     // setSnackbarMessage(data.message);
        //     // setOpenSnackbar(true);
        //   }
        // }).catch((err) => console.error(err));
      }
    }
  }

  // const jsonBody = {
  //   entityType: category,
  //   entityId:  categoryId,
  //   userId: userId,
  //   review: review,
  //   rating: rating
  // };
  // console.log(jsonBody);

  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">{method === "edit" ? "Edit Review" : "Create Review"}</h1>
        </header>

        <section>

          {/* <div className="double-column-form"> */}
          {/* <TextField style={{ margin: "10px auto 15px" }} name="title" label="Title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required /> */}
          {/* </div> */}

          <FormControl fullWidth style={{ margin: "10px auto 15px" }}>
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select name="Category" label="Category" variant="outlined" required 
              placeholder="Category"
              labelId="category-select-label"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value={"blogs"}>Blogs</MenuItem>
              <MenuItem value={"courses"}>Courses</MenuItem>
              <MenuItem value={"devotionals"}>Devotionals</MenuItem>
              <MenuItem value={"authors"}>Authors</MenuItem>
            </Select>
          </FormControl>
          
          {/* <TextField style={{ margin: "10px auto 15px" }} name="review" label="Review" value={createTopicDrawerObj.review} onChange={(e) => setCreateTopicDrawerObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required /> */}
          
          <TextField multiline rows={4} style={{ margin: "10px auto 15px" }} name="review-title" label="Review Title" value={reviewTitle} onChange={(e) => setReviewTitle(e.target.value)} placeholder="Review Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          
          <TextField multiline rows={4} style={{ margin: "10px auto 15px" }} name="review" label="Review" value={review} onChange={(e) => setReview(e.target.value)} placeholder="Review" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />

          <TextField style={{ margin: "10px auto 15px" }} name="rating" label="Rating" value={rating} onChange={(e) => setRating(e.target.value)} placeholder="Rating 1-5" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />

          <div className="double-column-form aligned-right margin-top">

            <Button variant="contained" component="label" disabled={false} 
              onClick={(e) => submitReview(e)}>Save Changes
            </Button>
          </div>

        </section>

      </div>
    </div>
  )

}

export default CreateEditReviewDrawer;
